import React, {useState, useEffect} from 'react';
import LeftPanel from 'components/utils/LeftPanel'
import Login from 'components/Login'
import NavBar from 'components/utils/NavBar'
import JobAndGrade from './components/menu/JobAndGrade'
import User from 'components/menu/User'
import DateRange from 'components/menu/DateRange'
import Container from 'components/main/Container.js'
import Icon from 'components/utils/Icon'

import './styles/app.css';
//import '../node_modules/material-icons/iconfont/material-icons.css';
import { formatDateTime, determineDate, getLocation, addActivity, getDeviceType } from 'scripts/common'
import { selectData, downloadData, selectDataToSync } from 'scripts/offline'
//import { showNotification, test } from 'utils/notification'

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setUserDetails] = useState({offline: null})
  const [isError, setIsError] = useState(false)
  const [isModal, setIsModal] = useState({
    job: false,
    user: false,
    date: false,
    offline: 'sync'
  })

  const [filter, setFilter] = useState({
    jobNumber: '',
    gradeId: '',
    user: '',
    startDate: '',
    endDate: '',
    dateName: '', // for display purposes below
    lat: '',
    lng: '',
    offline: false // using this to turn off addPending and use fetchData
  })

  const [component, setComponent] = useState('Home')
  const [componentManage, setComponentManage] = useState('Home')

  const selectComponent = (o) => {
    //console.log(`o: ${o.getAttribute('data-name')}`);
    setComponent(o.getAttribute('data-name'))
  }

  const selectComponentManage = (e) => {
    //console.log(`derp: ${e.currentTarget.getAttribute('data-id')}`)
    setComponentManage(e.currentTarget.getAttribute('data-id'))
  }

  useEffect(() => {
    const interval = setInterval( async () => {
      window.navigator.onLine ? selectDataToSync() : console.log('not online')
    }, 30000);
    return () => clearInterval(interval);
  });

  const login = () => {

    const user = document.getElementById("loginname").value
    const pw = document.getElementById("loginpw").value
    const device = getDeviceType()

    // May need to store users offline? and select all, then compare?
    // If multi users on same device, this will not work!

    selectData('Users')
    .then(res => {

      setUserDetails(res[0])
      setIsLoggedIn(true)
      setIsError(false)

    })
    .catch(err => {

      getLocation(function(latlng){

        fetch('/api/login', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({user: user, pw: pw})
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log(JSON.stringify(result))
            if (result.length === 0) {
              //alert('Incorrect login credentials.')
              setIsError(true)
            }
            else {

              //localStorage.setItem('myValueInLocalStorage', 'im a test')

              //console.log(result)

              //let data = result.map(data => ({...data, device: device, nukeId: ''}))

              setUserDetails({...result[0], device: device, nukeId: ''})
              setIsLoggedIn(true)
              setIsError(false)

              addActivity('login', '', result[0].id)

              fetch('/api/updateLastLogin', {
                method: 'post',
                headers: {
                  'Accept': 'application/json, text/plain, */*',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                  id: result[0].id,
                  time: formatDateTime(new Date())
                })
              })
              .then(res=>res.json())
              .then(
                (result) => {
                  //console.log('result: ' + result)
                },
                (error) => {
                  console.log('Error: updateLastLogin --> ' + error)
                }
              )

            }

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    })

    // if (props.user.offline === props.filter.jobNumber) {
    //
    //   selectData('Users').then(res => {
    //     setUserDetails(res)
    //     setIsLoggedIn(true)
    //     setIsError(false)
    //   })
    //
    // } else {
    //
    //   getLocation(function(latlng){
    //
    //     fetch('/api/login', {
    //       method: 'post',
    //       headers: {
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify({user: user, pw: pw})
    //     })
    //     .then(res=>res.json())
    //     .then(
    //       (result) => {
    //         //console.log(JSON.stringify(result))
    //         if (result.length === 0) {
    //           //alert('Incorrect login credentials.')
    //           setIsError(true)
    //         }
    //         else {
    //
    //           //localStorage.setItem('myValueInLocalStorage', 'im a test')
    //
    //           //console.log(result)
    //
    //           //let data = result.map(data => ({...data, device: device, nukeId: ''}))
    //
    //           setUserDetails({...data[0], device: device, nukeId: ''})
    //           setIsLoggedIn(true)
    //           setIsError(false)
    //
    //           addActivity('login', '', result[0].id)
    //
    //           fetch('/api/updateLastLogin', {
    //             method: 'post',
    //             headers: {
    //               'Accept': 'application/json, text/plain, */*',
    //               'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //               id: result[0].id,
    //               time: formatDateTime(new Date())
    //             })
    //           })
    //           .then(res=>res.json())
    //           .then(
    //             (result) => {
    //               //console.log('result: ' + result)
    //             },
    //             (error) => {
    //               console.log('Error: updateLastLogin --> ' + error)
    //             }
    //           )
    //
    //         }
    //
    //       },
    //       (error) => {
    //         console.log('error: ' + error)
    //       }
    //     )
    //
    //   })
    //
    // }

  }



  // const [value, setValue] = useState(
  //   localStorage.getItem('myValueInLocalStorage') || ''
  // )
  //
  // useEffect(() => {
  //   localStorage.setItem('myValueInLocalStorage', value)
  // }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setFilter(prevState => ({...prevState, [name]: state ? value : null}))

    //if (filter.startDate !== null && filter.startDate !== '' && filter.endDate !== null && filter.endDate !== '') closeModal()
  }

  const selectOffline = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')

    let jn = td[3].textContent
    let gd = td[4].textContent === 'Grading' ? 'RG' : td[4].textContent === 'Post' ? 'PG' : ''
    let filter = {
      jobNumber: jn,
      gradeId: gd,
      userName: userDetails.username,
      userLevel: userDetails.userlevel,
      guestAccess: userDetails.guestaccess
    }

    selectJob(e)

    setIsModal(prevState => ({...prevState, offline: 'syncing'}))

    downloadData(filter)

    // .then(() => {
    //   setIsModal(prevState => ({...prevState, offline: 'synced'}))
    // })

    fetch(`/api/editOffline`, {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: userDetails.id, jobNumber: jn})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(JSON.stringify(result))
        setUserDetails(prevState => ({...prevState, offline: jn}))
      },
      (error) => {
        //console.log('error: ' + error)
      }
    )
  }

  const selectJob = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    let td = tr.getElementsByTagName('td')
    let lat, lng

    if (td[5].textContent === '' || td[5].textContent === null) { // Clear JN should be excluded
      alert('Info has not been setup. Contact a manager.')
    } else {

      if (td[1].textContent === '' || td[2].textContent === '' || td[1].textContent === null || td[2].textContent === null) {
        lat = 33.84789303492371 // albus office
        lng = -117.86229291534426
      } else {
        lat = td[1].textContent
        lng = td[2].textContent
      }

      setFilter(prevState =>
        ({...prevState,
          jobNumber: td[3].textContent,
          gradeId: td[4].textContent === 'Grading' ? 'RG' : td[4].textContent === 'Post' ? 'PG' : '',
          lat: lat,
          lng: lng
        })
      )
      closeModal()
    }

  }

  const clearJob = () => {

    let lat = 33.84789303492371 // albus office
    let lng = -117.86229291534426

    setFilter(prevState =>
      ({...prevState,
        jobNumber: '',
        gradeId: '',
        lat: lat,
        lng: lng
      })
    )
    closeModal()

  }

  const selectUser = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setFilter(prevState => ({...prevState, user: td[0].textContent}))
    closeModal()

  }

  const selectDate = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let filter = determineDate(td[0].textContent)

    setFilter(prevState => ({...prevState, startDate: filter.startDate, endDate: filter.endDate, dateName: td[0].textContent}))
    closeModal()

  }

  const selectDateAlt = (start, end) => { // og the date picker had validate, which setFilter onInput. so chart, management tools, etc updated on input :/ better to confirm

    setFilter(prevState => ({...prevState, startDate: start, endDate: end, dateName: ''}))
    closeModal()

  }

  const toggleOffline = () => setFilter(prevState =>({...prevState,
    offline: filter.offline ? false : true
  }))

  const selectDB = (db) => setFilter(prevState => ({...prevState, db: db}))

  const selectNuke = (nukeId) => setUserDetails(prevState => ({...prevState, nukeId: nukeId}))

  const closeModal = () => setIsModal(prevState => ({...prevState, job: false, user: false, date: false}))
  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))
  const openUser = () => setIsModal(prevState => ({...prevState, user: true}))
  const openDate = () => setIsModal(prevState => ({...prevState, date: true}))

  //<Offline filter={filter} toggleOffline={toggleOffline} selectDB={selectDB} />

  let jn = filter.jobNumber
  let offline = userDetails.offline === jn ? true : false
  let offlineIcon =
  jn && offline === 'sync' ?
  <td><Icon name='sync' /></td> :
  jn && offline === 'syncing' ?
  <td><div className='sync'><Icon name='sync' /></div></td> :
  jn && offline === 'synced' ?
  <td><Icon name='offline_pin' /></td> : null

  console.log(`jn ${jn} and offline ${offline}`)

  return (
    <div>

      {isLoggedIn ?

        <div style={{display: 'flex', flexFlow: 'column', height: '100vh'}}>

          {isModal.job ? <JobAndGrade selectJob={selectJob} clearJob={clearJob} closeModal={closeModal} filter={filter} user={userDetails} offline={isModal.offline} selectOffline={selectOffline} /> : null}
          {isModal.user ? <User selectUser={selectUser} closeModal={closeModal} /> : null}
          {isModal.date ? <DateRange selectDate={selectDate} selectDateAlt={selectDateAlt} closeModal={closeModal} filter={filter} /> : null}

          <NavBar component={component} user={userDetails} onClick={selectComponent} />

          <div style={{flex: '1', overflow: 'auto'}}>

            <div style={{display: 'flex', height: '100%', width: '100%', overflow: 'auto'}}>

              {component === 'Manage' ?

                <div style={{flex: '0 1 auto', height: '100%'}}>
                  <LeftPanel selectComponent={selectComponentManage} componentManage={componentManage} user={userDetails} />
                </div> : null

              }

              <div style={{flex: '1 0 auto', overflow: 'auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div style={{display: 'flex'}}>

                    {offlineIcon}

                    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openJob}>
                      <Icon name='search' />
                      {
                        filter.jobNumber !== '' && filter.gradeId !== '' ?
                        <span style={{verticalAlign: 'base-line'}}>{filter.jobNumber} {filter.gradeId === 'RG' ? 'Grading' : filter.gradeId === 'PG' ? 'Post' : ''}</span> :
                        'Select a Job'
                      }
                    </div>

                    {component === 'Manage' ?

                      <>

                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openUser}>
                          <Icon name='person_search' />
                          {
                            filter.user !== '' ?
                            <span style={{verticalAlign: 'base-line'}}>{filter.user}</span> :
                            'Select a user'
                          }
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openDate}>
                          <Icon name='calendar_today' />
                          {
                            filter.startDate !== '' || filter.endDate !== '' ?
                            <span style={{verticalAlign: 'base-line'}}>
                              {
                                filter.dateName !== '' ?
                                `${filter.dateName}` :
                                filter.startDate === filter.endDate ?
                                `${filter.startDate}` :
                                `${filter.startDate} - ${filter.endDate}`
                              }
                            </span> :
                            'Select a Date or Date Range'
                          }

                        </div>

                      </> : null

                    }

                  </div>


                  <div style={{flex: '1', overflow: 'auto'}}>

                    <Container component={component} componentManage={componentManage} user={userDetails} filter={filter} selectNuke={selectNuke} />

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div> : <Login isError={isError} onClick={login} />
      }
    </div>
  )
}

export default App;
