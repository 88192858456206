import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

const Documents = (props) => {

  const path = `reports/report`

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectViewDocuments', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: props.filter.jobNumber,
        gradeId: props.filter.gradeId
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectStatuses --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      window.open(`${path}${fetchedData[i].id}.pdf`)

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map((data, i) => {

    let description = data.description === null ? '' : data.description

    if (
      searchValue === '' ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  return (
    props.filter.jobNumber !== null && props.filter.jobNumber !== '' ?
    <div style={{margin: 10}}>
      {
        fetchedData.length > 0 ?
        <div>

          <div>
            <Icon name='refresh' onClick={fetchData} />
          </div>

          <SearchBar search={search} clearSearch={clearSearch} />

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

            <table>

              <thead>
                <tr>
                  <th>Description</th>
                </tr>
              </thead>

              <tbody>
                {listOfData}
              </tbody>

            </table>

          </div>

        </div> :
        <p>No documents added</p>
      }
    </div> : null
  )

}

export default Documents
