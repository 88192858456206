import { addPending } from 'scripts/offline'

export const formatBytes = (a,b=2) => {if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}

export const replaceStr = (str) => str.replace(/"/g, '\\"').replace(/'/g, "\\'")

export const catchError = (jn, gd, component, func, error, username, device) => {

  addPending({
    actionId: 1,
    action: 'catchError',
    table: '',
    by: username,
    time: formatDateTime(new Date()),
    lat: '',
    lng: '',
    device: device,
    jobNumber: jn,
    gradeId: gd,
    component: component,
    func: func,
    error: error
  })
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
    },
    (error) => console.log('Error: catchError --> ' + error)
  )

  //getLocation(function(latlng){

    // fetch('/api/catchError', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: username,
    //     time: formatDateTime(new Date()),
    //     lat: latlng.lat,
    //     lng: latlng.lng,
    //     device: device,
    //     jobNumber: jn,
    //     gradeId: gd,
    //     component: component,
    //     func: func,
    //     error: error
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //
    //     // do nothing
    //
    //   },
    //   (error) => {
    //     console.log('Error: catchError --> ' + error)
    //   }
    // )

  //})

}

export const groupBy = (objectArray, ...properties) => {
  return [...Object.values(objectArray.reduce((accumulator, object) => {
    const key = JSON.stringify(properties.map((x) => object[x] || null));

    if (!accumulator[key]) {
      accumulator[key] = [];
    }
    accumulator[key].push(object);
    return accumulator;
  }, {}))];
}

export const determineDate = (object) => {

  let startP, endP, startDate, endDate
  let start = new Date();
  let end = new Date();

  if (object === 'All') {

      startP = -36500;
      endP = 0;

  }

  else if (object === 'Today') {

      startP = 0;
      endP = 0;

  }

  else if (object === 'Last 7 days') {

      startP = -7;
      endP = 0;

  }

  else if (object === 'Last 30 days') {

      startP = -30;
      endP = 0;

  }

  else if (object === 'Last 6 months') {

      startP = -180;
      endP = 0;

  }

  else if (object === 'Last year') {

      startP = -365;
      endP = 0;

  }

  else if (object === 'Yesterday') {

      startP = -1;
      endP = -1;

  }

  else if (object === 'Previous week') {

      let d = new Date();
      let n = d.getDay();

      if ( n === 7 ) {

          startP = -8;
          endP = -1;

      }

      else {

          endP = -n-1;
          startP = endP + -6;

      }

  }

  //==========================
  // start date
  //==========================

  start.setDate(start.getDate()+startP)

  let dd = start.getDate()
  let mm = start.getMonth()+1
  let yyyy = start.getFullYear()

  if (dd < 10) {
      dd = '0' + dd
  }

  if (mm < 10) {
      mm ='0' + mm
  }

  startDate = yyyy+'-'+mm+'-'+dd
  console.log(startDate)

  //==========================
  // end date
  //==========================

  end.setDate(end.getDate()+endP)

  dd = end.getDate()
  mm = end.getMonth()+1
  yyyy = end.getFullYear()

  if (dd < 10) {
      dd = '0' + dd
  }

  if (mm < 10) {
      mm = '0' + mm
  }

  endDate = yyyy+'-'+mm+'-'+dd
  console.log(endDate)

  return {
    startDate: startDate,
    endDate: endDate
  }

}

export const getDayName = (date, tz) => {

  date = new Date(date)

  switch (date.getDay()) {
    case 0: return "Sunday"
    case 1: return "Monday"
    case 2: return "Tuesday"
    case 3: return "Wednesday"
    case 4: return "Thursday"
    case 5:return "Friday"
    case 6: return "Saturday"
    default: return ""
  }

}

export function formatDateMD(date, tz) {

  date = new Date(date)

  let newDate = `${
    (date.getMonth()+1).toString().padStart(2, '')
  }/${
    date.getDate().toString().padStart(2, '')
  }`

  return newDate
}

export function formatDateYMD(date, tz) {

  date = new Date(date)

  let newDate = `${
    date.getFullYear().toString().padStart(4, '0')
  }-${
    (date.getMonth()+1).toString().padStart(2, '0')
  }-${
    date.getDate().toString().padStart(2, '0')
  }`

  return newDate
}

export function formatDateTime(date, tz) {

  date = new Date(date)

  let newDate = `${
    date.getFullYear().toString().padStart(4, '0')
  }/${
    (date.getMonth()+1).toString().padStart(2, '0')
  }/${
    date.getDate().toString().padStart(2, '0')
  } ${
    date.getHours().toString().padStart(2, '0')
  }:${
    date.getMinutes().toString().padStart(2, '0')
  }:${
    date.getSeconds().toString().padStart(2, '0')
  }`

  return newDate
}

export function formatDateTimeT(date) {

  let newDate

  if (date !== null && date !== '') {

    date = new Date(date)

    newDate = `${
      date.getFullYear().toString().padStart(4, '0')
    }-${
      (date.getMonth()+1).toString().padStart(2, '0')
    }-${
      date.getDate().toString().padStart(2, '0')
    }T${
      date.getHours().toString().padStart(2, '0')
    }:${
      date.getMinutes().toString().padStart(2, '0')
    }`

    return newDate

  } else {
    return null
  }

}

export function formatTime(date, tz) {

  date = new Date(date)

  let newDate =
  `${
    date.getHours().toString().padStart(2, '0')
  }:${
    date.getMinutes().toString().padStart(2, '0')
  }`

  return newDate
}

export function getLocation(callback) {

  let options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0
  }

  function success(position) {

    let user_position = {}
    user_position.lat = position.coords.latitude
    user_position.lng = position.coords.longitude
    callback(user_position)

  //   console.log('Your current position is:')
  //   console.log(`Latitude : ${position.coords.latitude}`)
  //   console.log(`Longitude: ${position.coords.longitude}`)
  //   console.log(`More or less ${position.coords.accuracy} meters.`)
  }

  function error(err) {

    let user_position = {}
    user_position.lat = ''
    user_position.lng = ''

    if (err.code === err.PERMISSION_DENIED) {
      console.warn(`ERROR(${err.code}): ${err.message}`)
      callback(user_position)
    } else if(err.code === 3) { // timeout
      console.warn(`ERROR(${err.code}): ${err.message}`)
      callback(user_position)
    } else {
      console.warn(`ERROR(${err.code}): ${err.message}`)
      callback(user_position)
    }

  }

  navigator.geolocation.getCurrentPosition(success, error, options)

}

export const nothingChanged = () => alert('Nothing changed.')

export const missingLocation = () => alert('Missing latitude and longitude.')

export const viewLocation = (e) => {
  let lat = e.target.getAttribute('data-lat')
  let lng = e.target.getAttribute('data-lng')

  window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`)
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
}

export const addActivity = (category, description, userId) => {

  getLocation(function(latlng){

    fetch('/api/addActivity', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        app: 'field',
        category: category,
        description: description,
        userId: userId,
        time: formatDateTime(new Date()),
        lat: latlng.lat,
        lng: latlng.lng,
        device: getDeviceType()
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
      },
      (error) => {
        console.log('Error: addActivity --> ' + error)
      }
    )

  })

}
