import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD } from 'scripts/common'

import { selectData } from 'scripts/offline'

const Prefix = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    if (props.user.offline === props.filter.jobNumber) {

      selectData('TestPrefixes').then(res => setFetchedData(res))

    } else {

      fetch('/api/selectMenuPrefixes', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          gradeId: props.filter.gradeId
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setFetchedData(result)
        },
        (error) => {
          console.log('Error: selectMenuPrefixes --> ' + error)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let prefix = data.prefix === null ? '' : data.prefix
    let description = data.description === null ? '' : data.description

    if (
      searchValue === '' ||
      prefix.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} onClick={props.selectPrefix}>
          <td>{prefix}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let content = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          <table>

            <thead>
              <tr>
                  <th>Prefix</th>
                  <th>Descrip</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default Prefix
