import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Plans from 'components/main/Plans'

import { formatDateYMD,formatDateTime, getLocation, catchError, replaceStr } from 'scripts/common'

const Lab = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    plan: false,
    google: false,
    locate: false,
    warning: 0,
    warningContent: '',
    loading: true
  })

  const isChanged = useRef(false)

  const [isDeliver, setIsDeliver] = useState(false)
  const [isGoogleMap, setIsGoogleMap] = useState(false)
  const [isPlan, setIsPlan] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    sampleDate: '',
    sampleNo: null,
    location: '',
    represents: '',
    purpose: '',
    sampleType: '',
    n: '',
    e: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    sampleDate: '',
    sampleNo: null,
    location: '',
    represents: '',
    purpose: '',
    sampleType: '',
    n: '',
    e: ''
  })

  const fetchData = () => {

    fetch('/api/selectManageLab', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        //setFetchedData(result)

        setFetchedData(result.map(data => (
          {...data,
            entrytime: data.entrytime !== null ? formatDateTime(data.entrytime) : data.entrytime,
            modtime: data.modtime !== null ? formatDateTime(data.modtime) : data.modtime,
            sampledate: formatDateYMD(data.sampledate)
          }
        )))

        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTests', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {

        let sampleNo = data.sampleno === null ? '' : data.sampleno
        let location = data.location === null ? '' : data.location
        let represents = data.represents === null ? '' : data.represents
        let purpose = data.purpose === null ? '' : data.purpose
        let sampleType = data.sampletype === null ? '' : data.sampletype
        let description = data.description === null ? '' : data.description
        let color = data.color === null ? '' : data.color
        let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
        let entryTime = data.entrytime === null ? '' : data.entrytime

        return true

        //{testNo: testNo, lat: data.entrylat, lng: data.entrylng}

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, sampleNo

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      sampleNo = data[i].sampleno === null ? '' : data[i].sampleno

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: sampleNo,
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i])
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (isDeliver  && (e.target.nodeName === 'I' || e.target.nodeName === 'Button')) {
      updateDelivered(e)
    } else if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      let today = new Date()
      let entry = new Date(fetchedData[i].entrytime)
      let timeDiff = Math.abs(entry.getTime() - today.getTime())
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      if (props.user.lab < 4 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: 'Cannot Delete: You do not have required permission. Contact an Admin.'}))

      } else if (props.user.lab === 4 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: Sample is ${fetchedData[i].status}. Contact an Admin.`}))

      } else if (props.user.lab === 5 && (fetchedData[i].status !== 'sampled' || fetchedData[i].status !== 'delivered')) {

        alert(`Sample is ${fetchedData[i].status}. Sampled data will be lost if deleted!`)

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: `Sample is ${fetchedData[i].status}`}))

      } else if (props.user.lab < 2) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username !== fetchedData[i].entryby) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      } else if ((props.user.lab === 2 || props.user.lab === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      } else {

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      }

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        sampleDate: fetchedData[i].sampledate,
        sampleNo: fetchedData[i].sampleno,
        location: fetchedData[i].location,
        represents: fetchedData[i].represents,
        purpose: fetchedData[i].purpose,
        sampleType: fetchedData[i].sampletype,
        n: fetchedData[i].n,
        e: fetchedData[i].e
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const viewDelivered = () => setIsDeliver(isDeliver ? false : true)

  const updateDelivered = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let id = td[0].textContent

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      let status = fetchedData[id].status
      let deliveredby = fetchedData[id].deliveredby
      let username = props.user.username
      let userlevel = props.user.userlevel

      const deliver = (update) => {

        getLocation(function(latlng){

          fetch('/api/updateDelivered', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              id: fetchedData[id].id,
              update: update
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              fetchData()

            },
            (error) => {

              alert('Error: could not update delivered status. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'updateDelivered', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        })

      }

      if (status === 'sampled') {

          deliver('delivered');

      } else if (status === 'delivered' ) {

        if (username === deliveredby || userlevel === 'manager' || userlevel === 'admin') {

          if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
            deliver('sampled');
          }

        } else {

          alert('This sample was delivered by ' + deliveredby + '. Contact ' + deliveredby + ' to change.');

        }

      } else if (status === 'archive' || status === 'dispose') {

        if (deliveredby === null || deliveredby === '') {

          deliver('adddelivered');

        } else if (deliveredby !== null && deliveredby !== '') {

          if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
            deliver('undelivered');
          }

        } else {
          alert('Error: deliveredby? ' + deliveredby + '. Contact an admin to change.');
        }

      } else if (status === 'archived' || status === 'disposed') {

        if (userlevel !== 'admin') {

          alert('This sample has been marked ' + status + '. Contact an admin to change.');

        } else if (userlevel === 'admin') {

          if (deliveredby === null || deliveredby === '') {

            deliver('adddelivered');

          } else if (deliveredby !== null && deliveredby !== '') {

            if (window.confirm('This sample has been delivered. You want to un-deliver?')) {
              deliver('undelivered');
            }

          } else {
            alert('Error: deliveredby? ' + deliveredby + '. Contact an admin to change.');
          }

        }

      } else {

        alert('This sample has been marked ' + status + '. Contact an admin to change.');

      }

    }

  }

  // const showDeliveredInfo = (event) => {
  //   let el = event.target
  //   let id = parseInt(el.getAttribute('data-id'))
  //   let content, sampled, delivered
  //   //console.log('samples: ' + JSON.stringify(fetchedSamples))
  //   let msg = fetchedData.map(sample => {
  //
  //     if (sample.id === id) {
  //       console.log('samples2: ' + JSON.stringify(sample))
  //       sampled = sample.entrytime !== null && sample.entrytime !== '' ?
  //       'Sampled by: ' + sample.entryby + ' @ ' + formatDateTime(new Date(sample.entrytime)) + '\n' : 'Not sampled\n'
  //
  //       delivered = sample.deliveredtime !== null && sample.deliveredtime !== '' ?
  //       'Delivered by: ' + sample.deliveredby + ' @ ' + formatDateTime(new Date(sample.deliveredtime)) : 'Not delivered'
  //       content = sampled + delivered
  //
  //       return (
  //         content
  //       )
  //     }
  //
  //   })
  //
  //   alert(content)
  // }

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      n: n,
      e: e
    }))
  }

  const addLab = () => {

    if (props.user.lab < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");
    } else if (isValidated.sampleNo === null || isValidated.sampleNo ==='') {
      alert("Sample number not found.");
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.purpose === null || isValidated.purpose ==='') {
      alert("Please provide a purpose.");
    } else if (isValidated.sampleType === null || isValidated.sampleType ==='') {
      alert("Please select a sample type.");
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");
    } else {

      getLocation(function(latlng){

        fetch('/api/addLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobnumber: props.filter.jobNumber,
            gradeid: props.filter.gradeId,
            sampledate: isValidated.sampleDate,
            sampleno: isValidated.sampleNo,
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),
            purpose: replaceStr(isValidated.purpose),
            sampletype: isValidated.sampleType,
            n: isValidated.n,
            e: isValidated.e,
            status: 'sampled'
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add lab. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addLab', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const editLab = () => {

    // if (props.user.lab < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.sampleDate === null || isValidated.sampleDate ==='') {
      alert("Please select a sample Date.");
    } else if (isValidated.sampleNo === null || isValidated.sampleNo ==='') {
      alert("Sample number not found.");
    } else if (isValidated.location === null || isValidated.location ==='') {
      alert("Please provide a location.");
    } else if (isValidated.purpose === null || isValidated.purpose ==='') {
      alert("Please provide a purpose.");
    } else if (isValidated.sampleType === null || isValidated.sampleType ==='') {
      alert("Please select a sample type.");
    } else if (isValidated.n === null || isValidated.n ==='') {
      alert("Please select a North.");
    } else  if (isValidated.e === null || isValidated.e ==='') {
      alert("Please select an East.");
    } else {

      getLocation(function(latlng){

        fetch('/api/editLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            sampledate: isValidated.sampleDate,
            sampleno: isValidated.sampleNo,
            location: replaceStr(isValidated.location),
            represents: replaceStr(isValidated.represents),
            purpose: replaceStr(isValidated.purpose),
            sampletype: isValidated.sampleType,
            n: isValidated.n,
            e: isValidated.e,
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                sampledate: isValidated.sampleDate,
                sampleno: isValidated.sampleNo,
                location: isValidated.location,
                represents: isValidated.represents,
                purpose: isValidated.purpose,
                sampletype: isValidated.sampleType,
                n: isValidated.n,
                e: isValidated.e,
              } :
              data
            ))

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit lab. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editLab', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const deleteLab = () => {

    // if (props.user.lab < 3) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 0) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteLab', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete lab. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteLab', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.lab < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      let maxSampleNo = Math.max(...fetchedData.map(data => data.sampleno))

      setIsValidated(prevState => ({...prevState, sampleNo: fetchedData.length === 0 ? 1 : maxSampleNo + 1}))
      setIsModal(prevState => ({...prevState, add: true}))

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const togglePlan = () => setIsModal(prevState => ({...prevState, plan: isModal.plan ? false : true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const filterData = (data) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let sampleNo = data.sampleno === null ? '' : data.sampleno
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let purpose = data.purpose === null ? '' : data.purpose
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime

    if (

      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleNo.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      location.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      represents.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      purpose.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleType.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      color.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {

      return true

    } else {

      return false

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let sampleNo = data.sampleno === null ? '' : data.sampleno
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let purpose = data.purpose === null ? '' : data.purpose
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
    let entryTime = data.entrytime === null ? '' : data.entrytime

    let filter = filterData(data)
    //console.log(`filter ${filter}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      let curve, ei, so4, se

      curve = data.curve === null || data.curve === '' ? '' : `${data.curve} - ${data.optd} @ ${data.optm}%`

      if (data.ei !== null && data.ei !== '') {

        ei = data.ei < 21 ? 'Very Low' : data.ei < 51 ? 'Low' : data.ei < 91 ? 'Medium' : data.ei < 131 ? 'High' : 'Very High'

      } else if (data.finalDialReading !== null && data.finalDialReading !== '' && data.initialDialReading !== null && data.initialDialReading !== '') {

        ei = (data.finalDialReading - data.initialDialReading) * 1000
        ei = ei < 21 ? 'Very Low' : ei < 51 ? 'Low' : ei < 91 ? 'Medium' : ei < 131 ? 'High' : 'Very High'

      } else {

        ei = ''

      }

      if (data.dilution1000 !== null && data.dilution1000 !== '') {

        so4 = data.dilution1000 < 0.1 ? 'Negligible' : data.dilution1000 < 0.2 ? 'Moderate' : data.dilution1000 < 2 ? 'Severe' : 'Very Severe'

      } else if (data.dilution100 !== null && data.dilution100 !== '') {

        so4 = data.dilution100/10 < 0.1 ? 'Negligible' : data.dilution100/10 < 0.2 ? 'Moderate' : data.dilution100/10 < 2 ? 'Severe' : 'Very Severe'

      } else if (data.dilution10 !== null && data.dilution10 !== '') {

        so4 = data.dilution10/100 < 0.1 ? 'Negligible' : data.dilution10/100 < 0.2 ? 'Moderate' : data.dilution10/100 < 2 ? 'Severe' : 'Very Severe'

      } else if (data.dilution0 !== null && data.dilution0 !== '') {

        so4 = data.dilution0/1000 < 0.1 ? 'Negligible' : data.dilution0/1000 < 0.2 ? 'Moderate' : data.dilution0/1000 < 2 ? 'Severe' : 'Very Severe'

      } else if (data.so4 !== null && data.so4 !== '') {

        so4 = data.so4 < 0.1 ? 'Negligible' : data.so4 < 0.2 ? 'Moderate' : data.so4 < 2 ? 'Severe' : 'Very Severe'

      } else {

        so4 = ''

      }

      if (data.se !== null && data.se !== '') {

        se = data.se

      } else if (data.sand1 !== null && data.sand1 !== '' && data.clay1 !== null && data.clay1 !== '' && data.sand2 !== null && data.sand2 !== '' && data.clay2 !== null && data.clay2 !== '' && data.sand3 !== null && data.sand3 !== '' && data.clay3 !== null && data.clay3 !== '') {

        let se1 = Math.ceil((data.sand1 / data.clay1) * 100)
        let se2 = Math.ceil((data.sand2 / data.clay2) * 100)
        let se3 = Math.ceil((data.sand3 / data.clay3) * 100)

        se = Math.ceil((se1 + se2 + se3) / 3)

      } else {

        se = ''

      }

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          {isDeliver ?
            <td><Icon name={data.deliveredtime !== '' && data.deliveredtime !== null ? 'check_circle' : 'panorama_fish_eye'} id={data.id} /></td> :
            <td><Icon name='local_shipping' iconClass={data.deliveredtime !== '' && data.deliveredtime !== null ? 'material-icons' : 'material-icons-outlined'} id={data.id} /></td>
          }
          {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <td>{jn}</td> : null}
          <td>{data.sampledate}</td>
          <td>{entryBy}</td>
          <td>{sampleNo}</td>
          <td className='wrapText'>{location}</td>
          <td className='wrapText'>{represents}</td>
          <td className='wrapText'>{purpose}</td>
          <td>{sampleType}</td>
          {!isDeliver && !isModal.google ?
            <>
              <td className='wrapText'>{description}</td>
              <td className='wrapText'>{color}</td>
              <td>{curve}</td>
              <td>{ei}</td>
              <td>{so4}</td>
              <td>{se}</td>
            </> : null
          }
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        <div>
          <label className='label'>Sample Date</label>
          <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : isValidated.sampleDate} required />
        </div>

        <div>
          <label className='label'>Sample No</label>
          <input style={{width: 75}} className='input' type="text" value={isValidated.sampleNo} disabled />
        </div>

        <div>
          <label className='label'>Location</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='location' onInput={validate} onChange={changedData} required>{isValidated.location}</textarea>
        </div>

        <div>
          <label className='label'>Represents</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='represents' onInput={validate} onChange={changedData} placeholder='Optional' required>{isValidated.represents}</textarea>
        </div>

        <div>
          <label className='label'>Purpose</label>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='purpose' onInput={validate} onChange={changedData} required>{isValidated.purpose}</textarea>
        </div>

        <div>
          <label className='label'>Sample Type</label>
          <select className='select' pattern=".{1,}" name='sampleType' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
            <option value=""></option>
            <option value="smbag">Small Bag</option>
            <option value="bulk">Bulk</option>
            <option value="ac">Asphalt</option>
            <option value="ca">CA Rings</option>
            <option value="spt">SPT</option>
            <option value="shelby">Shelby Tube</option>
          </select>
        </div>

        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          {isValidated.n !== null && isValidated.n !== '' ? <p>N: {isValidated.n}</p> : null}
          {isValidated.e !== null && isValidated.e !== '' ? <p>E: {isValidated.e}</p> : null}

        </div>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addLab : isModal.edit ? editLab : null} delete={isModal.edit ? deleteLab : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.locate ? <Plans user={props.user} filter={props.filter} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: isModal.google || isModal.plan ? '0 1 auto' : '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>

                {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                <Icon name='local_shipping' color={isDeliver ? 'dodgerblue' : 'gray'} onClick={viewDelivered} />
                <Icon name='refresh' onClick={fetchData} />

                {props.component === 'Manage' ?
                  <>
                    <Icon name='location_on' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />

                  </> : null
                }
              </div>

              <SearchBar search={search} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <p style={{margin: 10}}>{fetchedData.length} Total Records. (If no filters, limit 500)</p>

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          {isModal.google ? <th></th> : null}
                          <th>{isDeliver ? 'Delivered?' : ''}</th>
                          {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <th>JN</th> : null}
                          <th>Date</th>
                          <th>Tech</th>
                          <th>SN</th>
                          <th>Location</th>
                          <th>Represents</th>
                          <th>Purpose</th>
                          <th>Type</th>
                          {!isDeliver && !isModal.google ?
                            <>
                              <th>Desc.</th>
                              <th>Color</th>
                              <th>Max</th>
                              <th>EI</th>
                              <th>SO4</th>
                              <th>SE</th>
                            </> : null
                          }
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No lab found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

          {isModal.plan ?

            <div style={{margin: 10, flex: '0 1 auto', overflow: 'auto'}}>
              <Plans user={props.user} filter={props.filter} planMode={props.planMode} />
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>
  )

}

export default Lab
