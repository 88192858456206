import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import JobAndGrade from 'components/menu/JobAndGrade'
import ViewLocation from 'components/utils/ViewLocation'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'

const Users = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    job: false,
    add: false,
    edit: false
  })
  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    userName: '',
    userPW: '',
    userLevel: '',
    fullName: '',
    email: '',
    phone: '',
    guestAccess: [],
    status: 1 // default active
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    userName: '',
    userPW: '',
    userLevel: '',
    fullName: '',
    email: '',
    phone: '',
    guestAccess: [],
    status: 1 // default active
  })

  const fetchData = () => {

    fetch('/api/selectUsers', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userLevel: props.user.userlevel
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectUser --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        userName: fetchedData[i].username,
        userPW: fetchedData[i].userpw,
        userLevel: fetchedData[i].userlevel,
        fullName: fetchedData[i].fullname,
        email: fetchedData[i].email,
        phone: fetchedData[i].phone,
        guestAccess: fetchedData[i].guestaccess === null || fetchedData[i].guestaccess === '' ? [] : fetchedData[i].guestaccess.split(','),
        status: fetchedData[i].status
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addUser = () => {

    if (props.user.user < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.userName === null || isValidated.userName ==='') {
        alert("Please provide a user name.");
    } else if (isValidated.userPW === null || isValidated.userPW ==='') {
        alert("Please provide a user password.");
    } else if (isValidated.userLevel === null || isValidated.userLevel ==='') {
        alert("Please provide a user level.");
    } else  if (isValidated.fullName === null || isValidated.fullName ==='') {
        alert("Please provide a full name.");
    } else if (isValidated.email === null || isValidated.email ==='') {
        alert("Please provide an email.");
    } else if (isValidated.phone === null || isValidated.phone ==='') {
        alert("Please a phone number.");
    } else if (isValidated.userLevel === 'guest' && (isValidated.guestAccess === null || isValidated.guestAccess === '')) {
        alert("This user is a guest. Please provide jobs they may access.");
    } else {

      getLocation(function(latlng){

        fetch('/api/addUser', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            userName: isValidated.userName,
            userPW: isValidated.userPW,
            userLevel: isValidated.userLevel,
            fullName: isValidated.fullName,
            email: isValidated.email,
            phone: isValidated.phone,
            guestAccess: isValidated.guestAccess,
            status: isValidated.status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            // setFetchedData(prevState => ([
            //   {
            //     username: isValidated.userName,
            //     userpw: isValidated.userPW,
            //     userLevel: isValidated.userlevel,
            //     fullname: isValidated.fullName,
            //     email: isValidated.email,
            //     phone: isValidated.phone,
            //     guestaccess: isValidated.guestAccess,
            //     status: isValidated.status
            //   },
            //   ...prevState])
            // )

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add user. Contact and admin.')
            console.log('Error: addUser --> ' + error)
          }
        )

      })

    }

  }

  const editUser = () => {

    if (props.user.user < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.userName === null || isValidated.userName ==='') {
        alert("Please provide a user name.");
    } else if (isValidated.userPW === null || isValidated.userPW ==='') {
        alert("Please provide a user password.");
    } else if (isValidated.userLevel === null || isValidated.userLevel ==='') {
        alert("Please provide a user level.");
    } else  if (isValidated.fullName === null || isValidated.fullName ==='') {
        alert("Please provide a full name.");
    } else if (isValidated.email === null || isValidated.email ==='') {
        alert("Please provide an email.");
    } else if (isValidated.phone === null || isValidated.phone ==='') {
        alert("Please a phone number.");
    } else if (isValidated.userLevel === 'guest' && (isValidated.guestAccess === null || isValidated.guestAccess === '')) {
        alert("This user is a guest. Please provide jobs they may access.");
    } else {

      getLocation(function(latlng){

        fetch('/api/editUser', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            userName: isValidated.userName,
            userPW: isValidated.userPW,
            userLevel: isValidated.userLevel,
            fullName: isValidated.fullName,
            email: isValidated.email,
            phone: isValidated.phone,
            guestAccess: isValidated.guestAccess,
            status: isValidated.status
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                username: isValidated.userName,
                userpw: isValidated.userPW,
                userlevel: isValidated.userLevel,
                fullname: isValidated.fullName,
                email: isValidated.email,
                phone: isValidated.phone,
                guestAccess: isValidated.guestAccess,
                status: isValidated.status
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit user. Contact and admin.')
            console.log('Error: editUser --> ' + error)
          }
        )

      })

    }

  }

  const deleteUser = () => {

    if (props.user.user < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      console.log(`perm`, props.user.user)
      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteUser', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete user. Contact and admin.')
            console.log('Error: deleteUser --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[1].textContent === '' || td[1].textContent === null) {
      alert('Info has not been setup. Contact a manager.')
    } else {
      setIsValidated(prevState => ({...prevState,
        guestAccess: [...prevState.guestAccess, td[2].textContent]
      }))
      changedData()
      closeJob()
    }

  }

  const removeJob = (e) => {
    let job = e.currentTarget.getAttribute('data-id')

    setIsValidated(prevState => ({...prevState,
      guestAccess: prevState.guestAccess.filter(data => data !== job)
    }))
    changedData()
  }

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const openAdd = () => {

    if (props.user.user < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let name = data.fullname === null ? '' : data.fullname
    let level = data.userlevel === null ? '' : data.userlevel
    let status = data.status === null ? '?' : data.status === 1 ? 'Yes' : 'No'
    let jn = data.guestaccess === null ? '' : data.guestaccess
    let lastLogin = data.lastLogin === null || data.lastLogin === '' ? '' : formatDateYMD(data.lastLogin, true)
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let modTime = data.modtime === null ? '' : formatDateTime(data.modtime, true)

    if (
      searchValue === '' ||
      name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      level.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      status.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      modBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      modTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{level}</td>
          <td>{name}</td>
          <td style={{color: status === 'Yes' ? 'dodgerblue' : 'tomato'}}>{status}</td>
          <td>{lastLogin}</td>
          <td style={{maxWidth: 200, overflow: 'auto'}}>{jn}</td>
          <td>{entryBy}</td>
          <td>{entryTime}</td>
          <td>{modBy}</td>
          <td>{modTime}</td>
        </tr>
      )
    }

  })

  let listOfAccess = isValidated.guestAccess.length > 0 ?
  isValidated.guestAccess.map(job =>
    <div style={{display: 'inline-flex', alignItems: 'center', borderRadius: 5, color: 'white', backgroundColor: 'dodgerblue', margin: 5}}>
      <span style={{verticalAlign: 'base-line', marginLeft: 10}}>{job}</span>
      <Icon name='close' color='tomato' id={job} onClick={removeJob} />
    </div>
  ) :
  null

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>Active?
          <select style={{width: 75}} className='select' pattern=".{1,}" name='status' onInput={validate} onChange={changedData} defaultValue={isValidated.status} required>
            <option value={1}>Yes</option>
            <option value={0}>No</option>
          </select>
        </label><br />

        <label>Full Name
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z\s]{1,}" name='fullName' onInput={validate} onChange={changedData} defaultValue={isValidated.fullName} required />
        </label><br />

        <label>User Name
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z]{1,}" name='userName' onInput={validate} onChange={changedData} defaultValue={isValidated.userName} required />
        </label><br />

        <label>User PW
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='userPW' onInput={validate} onChange={changedData} defaultValue={isValidated.userPW} required />
        </label><br />

        <label>User Level
          <select style={{width: 75}} className='select' pattern=".{1,}" name='userLevel' onInput={validate} onChange={changedData} defaultValue={isValidated.userLevel} required>
            <option value='entry'>Entry</option>
            <option value='manager'>Manager</option>
            <option value='admin'>Admin</option>
            <option value='guest'>Guest</option>
          </select>
        </label><br />

        <label>Email
          <input style={{width: 200}} className='input' type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" placeholder='john@example.com' name='email' onInput={validate} onChange={changedData} defaultValue={isValidated.email} required />
        </label><br />

        <label>Phone
          <input style={{width: 200}} className='input' type="tel" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" placeholder='###-###-####' name='phone' onInput={validate} onChange={changedData} defaultValue={isValidated.phone} required />
        </label>

        <div>
          {isValidated.userLevel === 'guest' ?
            <div style={{display: 'inline-flex', alignItems: 'center', borderRadius: 5, margin: 5}}>
              <span style={{verticalAlign: 'base-line', marginLeft: 10}}>Add Guest Access</span>
              <Icon name='add_circle' onClick={openJob} />
            </div> : null
          }
          <div>
            {listOfAccess}
          </div>
        </div>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addUser : isModal.edit ? editUser : null} delete={isModal.edit ? deleteUser : null} content={modalContent} closeModal={closeModal} /> : null}
      {isModal.job ? <JobAndGrade selectJob={selectJob} closeModal={closeJob} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div>
              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      <th>Level</th>
                      <th>Name</th>
                      <th>Active</th>
                      <th>Last Login</th>
                      <th>Job Access</th>
                      <th>Entry by</th>
                      <th>Entry time</th>
                      <th>Mod by</th>
                      <th>Mod time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>
              </div> :
              <p style={{margin: 10}}>No users found.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default Users
