import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import PlanDrawings from 'components/main/PlanDrawings'
import ViewLocation from 'components/utils/ViewLocation'
import Job from 'components/menu/Job'
import Alert from 'components/utils/Alert'

import { formatDateYMD, formatDateTime, getLocation, catchError } from 'scripts/common'

import { getBase64OfPlan, selectData } from 'scripts/offline'

const Plans = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    draw: false,
    index: false,
    loading: true,
    alert: false,
    alertContent: ''
  })

  const [isLoading, setIsLoading] = useState(true)

  const countErrorLoadPDF = useRef(0)

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const canvasPlan = useRef(null)
  const canvasDraw = useRef(null)

  const plansMode = useRef('plansdraw')
  const planIndex = useRef(null)

  const pdf = useRef({
    id: null,
    zoomFactor: 0.5,
    doc: null,
    scale: 1
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    type: '',
    description: '',
    sht: '',
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    n1: null,
    n2: null,
    e1: null,
    e2: null,
    angle: null,
    m0: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null,
    base64: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    type: '',
    description: '',
    sht: '',
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    n1: null,
    n2: null,
    e1: null,
    e2: null,
    angle: null,
    m0: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null,
    base64: ''
  })

  const fetchData = () => {

    if (props.user.offline === props.filter.jobNumber) {

      selectData('Plans').then(res => {
        setFetchedData(res)
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectPlans', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
          setFetchedData(result)

          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectPlans', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter.jobNumber, props.filter.gradeId])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {
      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid,
        type: fetchedData[i].type,
        description: fetchedData[i].description,
        sht: fetchedData[i].sht,
        x1: fetchedData[i].x1,
        x2: fetchedData[i].x2,
        y1: fetchedData[i].y1,
        y2: fetchedData[i].y2,
        n1: fetchedData[i].n1,
        n2: fetchedData[i].n2,
        e1: fetchedData[i].e1,
        e2: fetchedData[i].e2,
        angle: fetchedData[i].angle,
        m0: fetchedData[i].matrix0,
        m1: fetchedData[i].matrix1,
        m2: fetchedData[i].matrix2,
        m3: fetchedData[i].matrix3,
        m4: fetchedData[i].matrix4,
        m5: fetchedData[i].matrix5,
        base64: fetchedData[i].base64,
      }))

      if (props.component === 'Manage') {

        openEdit()

      } else {

        openDraw(fetchedData[i].id, fetchedData[i].modtime, fetchedData[i].base64)

      }

    }

  }

  const addPlans = () => {

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
        alert("Please provide a job number.");
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
        alert("Please specify 'Grading' or 'Post'.");
    } else if (isValidated.type === null || isValidated.type ==='') {
        alert("Please provide a type.");
    } else  if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description.");
    } else {

      getLocation(function(latlng){

        fetch('/api/addPlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            type: isValidated.type,
            description: isValidated.description
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add plan. Contact and admin.')
            console.log('Error: addPlan --> ' + error)
          }
        )

      })

    }

  }

  const editPlans = () => {

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
        alert("Please provide a job number.");
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
        alert("Please specify 'Grading' or 'Post'.");
    } else if (isValidated.type === null || isValidated.type ==='') {
        alert("Please provide a type.");
    } else  if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description.");
    } else {

      getLocation(function(latlng){

        fetch('/api/editPlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            type: isValidated.type,
            description: isValidated.description
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                type: isValidated.type,
                description: isValidated.description
              } :
              data
            ))

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit plan. Contact and admin.')
            console.log('Error: editPlan --> ' + error)
          }
        )

      })

    }

  }

  const deletePlans = () => {

    if (props.user.plan < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deletePlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete plan. Contact and admin.')
            console.log('Error: deletePlan --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,jobNumber: td[2].textContent}))
    closeJob()

  }

  const changedData = () => isChanged.current = true

  const savedData = () => isChanged.current = false

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const openAlert = (content) => setIsModal(prevState => ({...prevState, alert: true, alertContent: content}))

  const closeAlert = () => setIsModal(prevState => ({...prevState, alert: false}))

  const loadPDF = (url, urlAlt) => { // urlAlt allows reload attempt with actual url and not base64

    //Loads PDF into canvasDraw
    pdfjsLib.getDocument(url).promise.then(function(doc) {

        pdf.current.id = isValidated.id
        pdf.current.doc = doc

        // Initial/first page rendering
        renderPage(doc, pdf.current.scale);

    })
    .then(result => {

      setIsLoading(false)

    })
    .catch(error => {

      catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'loadPDF', JSON.stringify(error), props.user.username, props.user.device)

      if (countErrorLoadPDF.current < 4) {

        openAlert(`Failed to load. Trying again. Attempt ${countErrorLoadPDF.current} of 3`)

        let data = urlAlt === undefined ? url : urlAlt
        loadPDF(data)
        countErrorLoadPDF.current += 1

      } else {
        alert(`Could not load. Try 'Reload Plan' from the left menu.`)
        countErrorLoadPDF.current = 0
      }


    })

  }

  const reloadPDF = () => {

    setIsLoading(true)
    let url = `/plans/plan${isValidated.id}.pdf`
    loadPDF(url)

  }

  const openDraw = (id, modtime, base64) => {

    setIsModal(prevState => ({...prevState, draw: true}))

    let url = '/plans/plan' + id + '.pdf';
    //let url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf'
    //let url ='/plans/helloworld.pdf'

    // ######################################################################################################

    // TESTING SAVING PDF AS base64 and saved in localStorage

    // ######################################################################################################

    // loadPDF move outside function

    // If you are loading file from a remote server, be sure to configure “Access-Control-Allow-Origin”
    // For example, the following image can be loaded from anywhere

    // Initialize the XMLHttpRequest and wait until file is loaded

    const cachePlan = (url, id, modtime) => {

      getBase64OfPlan(url, id, modtime)
      .then(result => {
        //console.log(result)

        let data = {data: atob(result)}

        loadPDF(data, url)
      })
      .catch(error => {
        console.error('Error:', error.statusText)

        loadPDF(url)
      })

    }

    // is the plan in localStorage ?

    // getBase64OfPlan(url)
    // .then(result => console.log(result))
    // .catch(error => console.error('Error:', error.statusText))

    let localPlan = JSON.parse(localStorage.getItem('localPlan'))

    if (props.user.offline === props.filter.jobNumber) {

      let data = {data: atob(base64)}

      loadPDF(data)

    } else if (localStorage.getItem('localPlan') === null) {
      //console.log('remotePlan')
      cachePlan(url, id, modtime)

    } else if (localPlan.id === id && localPlan.modtime === modtime) {
      //console.log('localPlan')
      let data = {data: atob(localPlan.base64)}

      loadPDF(data)

    } else {
      //console.log('remotePlan')
      cachePlan(url)

    }

    // ######################################################################################################

    // //Loads PDF into canvasDraw
    // pdfjsLib.getDocument({ data: pdfData, }).promise.then(function(doc) {
    //
    //     pdf.current.id = id
    //     pdf.current.doc = doc
    //
    //     // Initial/first page rendering
    //     renderPage(doc, pdf.current.scale);
    //
    // });

  }

  const openPlan = () => openDraw(isValidated.id, isValidated.modtime, isValidated.base64)

  const openAdd = () => {

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openIndex = () => {

    let index = fetchedData.find(data => data.type === 'Index')

    if (index === undefined) {
      alert('An index map has not been attached. Contact a manager.')
    } else {

      planIndex.current = '/plans/plan' + index.id + '.png'

      setIsModal(prevState => ({...prevState, index: true}))

    }

  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, draw: false, add: false, edit: false}))
        clearIsValidated()
        setIsLoading(true)
      }
    } else {
      setIsModal(prevState => ({...prevState, draw: false, add: false, edit: false}))
      clearIsValidated()
      setIsLoading(true)
    }

  }

  const closeIndex = () => setIsModal(prevState => ({...prevState, index: false}))

  let listOfData = fetchedData.map((data, i) => {

    if (data.type === 'Plan') {

      let sht = data.sht === null ? '' : data.sht
      let description = data.description === null ? '' : data.description
      let entryby = data.entryby === null ? '' : data.entryby
      let entrytime = data.entrytime === null || data.entrytime === '' ? '' : formatDateYMD(data.entrytime, true)

      let offline = false
      if (localStorage.getItem('localPlan') !== null) {

        let localPlan = JSON.parse(localStorage.getItem('localPlan'))
        offline = localPlan.id === data.id ? true : false

      }

      if (
        searchValue === '' ||
        sht.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        entryby.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        entrytime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      ) {
        return (
          <tr key={data.id.toString()} onClick={selectRow}>
              <td style={{display: 'none'}}>{i}</td>
              <td>{sht}</td>
              <td>{description}</td>
              <td>{entryby}</td>
              <td>{entrytime}</td>
              <td>{offline ? <Icon name='offline_pin' /> : ''}</td>
          </tr>
        )
      }

    }

  })

  //========================================
  //==  Mapping
  //========================================

  // The link below explains how to use npm
  // https://github.com/mozilla/pdf.js/wiki/Setup-pdf.js-in-a-website

  // If absolute URL from the remote server is provided, configure the CORS
  // header on that server.

  // Loaded via <script> tag, create shortcut to access pdf.current.js exports.
  const pdfjsLib = window['pdfjs-dist/build/pdf'];
  //const pdfjsLib = require('pdfjs-dist/build/pdf.js')
  //const pdfjsLib = require('pdfjs-dist')

  // The workerSrc property shall be specified.
  pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.js';
  //pdfjsLib.GlobalWorkerOptions.workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.entry.js'

  const renderPage = (doc, scale) => {

    doc.getPage(1).then(function(page) {

      let viewport = page.getViewport({scale: scale})

      canvasDraw.current.height = viewport.height
      canvasDraw.current.width = viewport.width

      canvasPlan.current.height = viewport.height
      canvasPlan.current.width = viewport.width

      // Render PDF page into canvas context
      let renderContext = {

          canvasContext: canvasPlan.current.getContext('2d'),
          viewport: viewport

      };

      page.render(renderContext)

    })
    //.then(res => setIsLoading(false))
    .then(
      (result) => {

        setIsLoading(false)

      },
      (error) => {

        alert('Error: could not load document page. Contact and admin.')

        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'renderPage', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

  }

  const zoomIn = () => {

    let scale = pdf.current.scale + pdf.current.zoomFactor;

    pdf.current.scale = scale

    setIsLoading(true)

    renderPage(pdf.current.doc, scale);

  }

  const zoomOut = () => {

    if (pdf.current.scale <= pdf.current.zoomFactor) { // zoomFactor currently set to 0.5 in vars above per Daves request

      alert('Maximum zoom out reached.');

    } else {

      let scale = pdf.current.scale - pdf.current.zoomFactor;

      pdf.current.scale = scale

      setIsLoading(true)

      renderPage(pdf.current.doc, scale);

    }

  }

  let modalContent = (
    <div>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>JN
          <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} onClick={openJob} />
        </label><br />

        <label>Grading
          <select style={{width: 75}} className='select' pattern=".{1,}" name='gradeId' onInput={validate} onChange={changedData} defaultValue={isValidated.gradeId} required>
            <option value=''></option>
            <option value='RG'>Grading</option>
            <option value='PG'>Post</option>
          </select>
        </label><br />

        <label>Type
          <select style={{width: 75}} className='select' pattern=".{1,}" name='type' onInput={validate} onChange={changedData} defaultValue={isValidated.type} required>
            <option value=''></option>
            <option value='Index'>Index</option>
            <option value='Plan'>Plan</option>
          </select>
        </label><br />

        <label>Descrip
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z\s]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} required />
        </label><br />

        <CircleButton iconName='map' onClick={openPlan} />

      </div>

    </div>
  )

  // Plansdrawings

  // user={props.user} --> tracks who is drawing

  // selectLocate={props.selectLocate} --> handles passing N,E back to Tests
  // data={props.data} --> passes pre, testNo, suf, and elevD from Tests to PlanDrawings
  // closePlan={props.closePlan} --> closes plan modal if from Tests and test location accepted

  let content = (

    <div>
      {!isModal.loading ?
        <div>

          <div>
            {props.component === 'Manage' ? props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} /> : null}
            <Icon name='local_library' onClick={openIndex} />
            <Icon name='refresh' onClick={fetchData} />
          </div>

          <SearchBar search={search} clearSearch={clearSearch} />

          {fetchedData.length > 0 ?

            <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th>Sht</th>
                    <th>Descrip.</th>
                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>

            </div> :
            props.filter.jobNumber === null || props.filter.jobNumber === '' ?
            <p style={{margin: 10}}>Select a Job.</p> :
            <p style={{margin: 10}}>No plans found.</p>

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addPlans : isModal.edit ? editPlans : null} delete={isModal.edit ? deletePlans : null} content={modalContent} closeModal={closeModal} /> : null}
      {isModal.index ? <Modal content={<img style={{height: '100%', width: '100%'}} src={planIndex.current} />} closeModal={closeIndex} /> : null}
      {isModal.job ? <Job user={props.user} selectJob={selectJob} closeModal={closeJob} /> : null}
      {isModal.draw ?
        <PlanDrawings
          user={props.user}
          closeModal={closeModal}
          changedData={changedData}
          savedData={savedData}
          canvasPlan={canvasPlan}
          canvasDraw={canvasDraw}
          mode={props.planMode}
          planInfo={isValidated}
          pdf={pdf.current}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          filter={props.filter}
          selectLocate={props.selectLocate}
          reloadPDF={reloadPDF}
          data={props.data} // passes data from lab and test for display
          closePlan={props.closePlan} // used to close plan if opened from lab, tests, etc
          isLoading={isLoading}
        /> : null
      }
      {isModal.alert ? <Alert content={isModal.alertContent} close={closeAlert} /> : null}
    </div>

  )

  return props.modal ? <Modal content={content} closeModal={props.closePlan} /> : (props.filter.jobNumber !== null && props.filter.jobNumber !== '') || props.component === 'Manage' ? content : null // this allows plans to be used as a Modal inside Test, Lab, etc

}

export default Plans
