import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Stats from 'components/main/Stats'

//import { formatDateTime, formatTime, groupBy } from 'scripts/common'

const Analytics = (props) => {

  // const canvasRef = useRef(null)
  // const chartRef = useRef(null)
  //
  // const googleRef = useRef(null)
  // const mapRef = useRef(null)
  // const markerRef = useRef([])

  // const mapPosition = useRef({
  //   center: null,
  //   zoom: null
  // })

  //const [fetchedData, setFetchedData] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    table: true,
    chart: true,
    google: true
  })

  // async function fetchData() {
  //
  //   let response = await fetch('/api/selectDistinctUsersForChart', {
  //     method: 'post',
  //     headers: {
  //       'Accept': 'application/json, text/plain, */*',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       filter: props.filter
  //     })
  //   })
  //   .then(res=>res.json())
  //   .then(
  //     (user) => {
  //
  //       let users = user.map(data => data.entryby)
  //
  //       setFetchedDistinctUsers(users)
  //
  //       fetch('/api/selectActivityForChart', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           filter: props.filter
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //
  //           setFetchedData(result.map((data, i) => {
  //
  //             return {...data,
  //               time: formatDateTime(data.time),
  //               yLabelKey: users.indexOf(data.who)
  //             }
  //
  //           }))
  //         },
  //         (error) => {
  //           console.log('Error: selectActivityForChart --> ' + error)
  //         }
  //       )
  //
  //     },
  //     (error) => {
  //       console.log('Error: selectActivityForChart --> ' + error)
  //     }
  //   )
  //
  // }
  //
  // useEffect(() => {
  //   fetchData()
  // }, [props.filter])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     fetchData()
  //     .then(result => {
  //       if (isModal.chart) buildChart()
  //       //if (isModal.google) addMarkers()
  //     })
  //   }, 15000) // time in milliseconds (ie 1 mins)
  //   return () => clearInterval(interval);
  // }, [isModal, props.filter, searchValue, fetchedData])

  //const toggleTable = () => setIsModal(prevState => ({...prevState, table: isModal.table ? false : true}))

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  return (

    <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

      <div style={{margin: 10, flex: '1 0 auto'}}>

        <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

          <div>
            <Icon name='refresh' title='Refresh' onClick={null} />
            <Icon name='table_rows' title='Toggle Table' outline={true} color={isModal.table ? 'dodgerblue' : 'gray'} onClick={null} />
            <Icon name='timeline' title='Toggle Chart' color={isModal.chart ? 'dodgerblue' : 'gray'} onClick={null} />
            <Icon name='location_on' title='Toggle Map' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={null} />
          </div>

          <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

          <Stats filter={props.filter} />

        </div>

      </div>

    </div>

  )
}

export default Analytics
