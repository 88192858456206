import { formatDateTime, formatDateYMD, catchError, getLocation, getDeviceType } from 'scripts/common'

// formats plans for offline use

export const getBase64OfPlan = (url, id, modtime) => {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.onload = function () {
      //console.log(`status ${this.status}`)
      if (this.status >= 200 && this.status < 300) {

        // Create a Uint8Array from ArrayBuffer
        let bytes = new Uint8Array(xhr.response)

        // Get binary string from UTF-16 code units
        //let binary = String.fromCharCode.apply(null, codes)
        let binary = ''
        for (let i = 0; i < bytes.length; i++) {
          binary += String.fromCharCode(bytes[i])
        }

        // Convert binary to Base64
        let base64 = btoa(binary)
        //console.log(b64)

        let localPlan = {
          id: id,
          modtime: modtime,
          base64: base64
        }

        localStorage.setItem('localPlan', JSON.stringify(localPlan))

        resolve(base64);

      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }

    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    // Send HTTP request and fetch file as ArrayBuffer
    xhr.responseType = 'arraybuffer'
    xhr.send()
  });
}

// const connectToDatabase = (resolve, reject) => {
//   return new Promise((resolve, reject) => {
//
//     let req = window.indexedDB.open('albusData', 1);
//     req.onsuccess = (e) => {
//
//       //dbDef.dbCon = e.target.result;
//       resolve(e.target.result);
//     }
//     req.onupgradeneeded = (e) => {
//
//       //dbDef.dbCon = e.target.result;
//       //dbDef.dbInit = 1;
//       resolve(e.target.result);
//     }
//     req.onerror = (e) => {
//     // Returns error event
//       reject(console.log('failed to connect'));
//     }
//   });
// }

// async function connectToDatabase() {
//
//   if (window.indexedDB) {
//
//     let request = window.indexedDB.open('albusData', 1)
//
//     request.onerror = function(event) {
//       console.log("Error: connectToDatabase");
//     }
//
//     request.onsuccess = (event) => {
//        //db = event.target.result
//     }
//
//     return request.result
//
//   } else {
//     return null
//   }
//
// }

async function fetchWithTimeout(path, options) {
  let timeout = 3000
  let controller = new AbortController()
  let id = setTimeout(() => controller.abort(), timeout)

  let response = await fetch(path, {
    ...options,
    timeout: timeout,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}

export async function selectDataToSync() {

  let request = window.indexedDB.open('Pending', 1)

  request.onerror = (event) => {
    //db = event.target.result
    console.log("Error: selectDataToSync");

  }

  request.onupgradeneeded = (event) => {
    let db = event.target.result
    db.createObjectStore('Pending', {
       autoIncrement: true
    })
  }

  request.onsuccess = (event) => {

    let db = event.target.result

    //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
    if (db.objectStoreNames.contains('Pending')) {

      let txn = db.transaction('Pending', 'readwrite');
      let objectStore = txn.objectStore('Pending');
      let request = objectStore.openCursor()

      request.onerror = function(event) {
        console.log(`Error: selectDataToSync-->txn`)
      }

      request.onsuccess = function(event) {

        let cursor = event.target.result
        if (cursor) {
          let key = cursor.primaryKey
          let value = cursor.value
          console.log(`key1: ${key}`)

          getLocation(function(latlng){

            let data = {...value, lat: latlng.lat, lng: latlng.lng}



            // fetch(`/api/${data.action}`, {
            //   method: 'post',
            //   headers: {
            //    'Accept': 'application/json, text/plain, */*',
            //    'Content-Type': 'application/json'
            //   },
            //   body: JSON.stringify(data)
            // })

            fetchWithTimeout(`/api/${data.action}`, {
              method: 'post',
              headers: {
               'Accept': 'application/json, text/plain, */*',
               'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
            })
            .then(res=>res.json())
            .then(
             (result) => {
               //console.log(JSON.stringify(result))
               console.log('success')
               console.log(`key2: ${key}`)
               let txn = db.transaction('Pending', 'readwrite');
               let request = txn.objectStore('Pending').delete(key)

               request.onerror = function() {
                 // DEAL WITH THIS!!! OR LOOP AND POSSIBLE INFINITE INSERTS!!!!!
                 console.log('not Deleted.')
               };

               request.onsuccess = function() {
                 console.log('Deleted.')
               };

             },
             (error) => {
               console.log('Not Deleted - error: ' + error)
             }
            )

          })

          cursor.continue()

        } else {
           // no more results
        }

      }

    }

  }

}

// export async function selectDataToSync() {
//
//   let request = window.indexedDB.open('Pending', 1)
//
//   request.onerror = (event) => {
//     //db = event.target.result
//     console.log("Error: selectDataToSync");
//
//   }
//
//   request.onupgradeneeded = (event) => {
//     let db = event.target.result
//     db.createObjectStore('Pending', {
//        autoIncrement: true
//     })
//   }
//
//   request.onsuccess = (event) => {
//
//     let db = event.target.result
//
//     //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
//     if (db.objectStoreNames.contains('Pending')) {
//
//       let txn = db.transaction('Pending', 'readwrite');
//       let objectStore = txn.objectStore('Pending');
//       let request = objectStore.openCursor()
//
//       request.onerror = function(event) {
//         console.log(`Error: selectDataToSync-->txn`)
//       }
//
//       request.onsuccess = function(event) {
//
//         let cursor = event.target.result
//         if (cursor) {
//           let key = cursor.primaryKey
//           let value = cursor.value
//           console.log(`key1: ${key}`)
//
//           getLocation(function(latlng){
//
//             let data = {...value, lat: latlng.lat, lng: latlng.lng}
//
//             fetch(`/api/${data.action}`, {
//               method: 'post',
//               headers: {
//                'Accept': 'application/json, text/plain, */*',
//                'Content-Type': 'application/json'
//               },
//               body: JSON.stringify(data)
//             })
//             .then(res=>res.json())
//             .then(
//              (result) => {
//                //console.log(JSON.stringify(result))
//                console.log('success')
//                console.log(`key2: ${key}`)
//                let txn = db.transaction('Pending', 'readwrite');
//                let request = txn.objectStore('Pending').delete(key)
//
//                request.onerror = function() {
//                  // DEAL WITH THIS!!! OR LOOP AND POSSIBLE INFINITE INSERTS!!!!!
//                  console.log('not Deleted.')
//                };
//
//                request.onsuccess = function() {
//                  console.log('Deleted.')
//                };
//
//              },
//              (error) => {
//                console.log('Not Deleted - error: ' + error)
//              }
//             )
//
//           })
//
//           cursor.continue()
//
//         } else {
//            // no more results
//         }
//
//       }
//
//     }
//
//   }
//
// }

// export async function selectDataToSync() {
//
//   let request = window.indexedDB.open('Pending', 1)
//
//   request.onerror = (event) => {
//     //db = event.target.result
//     console.log("Error: selectDataToSync");
//
//   }
//
//   request.onupgradeneeded = (event) => {
//     let db = event.target.result
//     db.createObjectStore('Pending', {
//        autoIncrement: true
//     })
//   }
//
//   request.onsuccess = (event) => {
//
//     let db = event.target.result
//
//     //console.log(`db?? ${JSON.stringify(db.objectStoreNames)}`)
//     if (db.objectStoreNames.contains('Pending')) {
//
//       selectData('Pending').then(result => {
//
//         result.forEach(data => {
//
//           getLocation(function(latlng){
//
//             data = {...data, lat: latlng.lat, lng: latlng.lng}
//
//             fetch(`/api/${data.action}`, {
//               method: 'post',
//               headers: {
//                'Accept': 'application/json, text/plain, */*',
//                'Content-Type': 'application/json'
//               },
//               body: JSON.stringify(data)
//             })
//             .then(res=>res.json())
//             .then(
//              (result) => {
//                //console.log(JSON.stringify(result))
//
//                let key
//
//                switch(data.table) {
//                  case 'PlansDrawDrawings':
//                    key = data.drawId
//                    break
//                  default:
//                    key = data.id
//                }
//
//                console.log('success')
//                console.log(`key: ${key}`)
//                let txn = db.transaction('Pending', 'readwrite');
//                let request = txn.objectStore('Pending').delete(Number(key))
//
//                request.onerror = function() {
//                  // DEAL WITH THIS!!! OR LOOP AND POSSIBLE INFINITE INSERTS!!!!!
//                  console.log('not Deleted.')
//                };
//
//                request.onsuccess = function() {
//                  console.log('Deleted.')
//                };
//
//              },
//              (error) => {
//                console.log('Not Deleted - error: ' + error)
//              }
//             )
//
//           })
//
//         })
//
//       })
//
//     }
//
//   }
//
// }

export function addPending(data) {

  return new Promise((resolve, reject) => {

    let request = window.indexedDB.open('Pending', 1)

    request.onupgradeneeded = (event) => {
      let db = event.target.result
      db.createObjectStore('Pending', {
         autoIncrement: true
      })
    }

    request.onerror = (event) => {
      reject(event)
    }

    request.onsuccess = (event) => {

      let db = event.target.result
      let txn = db.transaction(['Pending'], 'readwrite')
      let store = txn.objectStore('Pending')
      let req = store.put(data)

      req.onerror = function(event) {
        reject(event)
      }
      req.onsuccess = function(event) {
        //resolve(event.target.result)
        console.log('added pending')

        if (data.table !== '') {

          let request = window.indexedDB.open('albusData', 1)

          request.onupgradeneeded = (event) => {
            let db = event.target.result
            db.createObjectStore(data.table, {
               autoIncrement: true
            })
          }

          request.onerror = (event) => {
            reject(event)
          }

          request.onsuccess = (event) => {
            console.log('added pending-->connected to albusData')

            console.log(`table ${data.table}`)

            let db = event.target.result
            let txn = db.transaction(data.table, 'readwrite')
            let store = txn.objectStore(data.table)
            let req = store.put(data)

            switch(data.actionId) {
              case 1:
                store.put(data)
                break
              case 2:

                switch(data.table) {
                  case 'PlansDrawDrawings':
                    store.put(data, data.drawId)
                    break
                  case 'PlansDrawTests':
                    store.put(data, data.testId)
                    break
                  case 'PlansDrawLab':
                    store.put(data, data.labId)
                    break
                  default:
                    store.put(data)
                }

                break
              case 3:

                switch(data.table) {
                  case 'PlansDrawDrawings':
                    store.delete(data.drawId)
                    break
                  case 'PlansDrawTests':
                    store.delete(data.testId)
                    break
                  case 'PlansDrawLab':
                    store.delete(data.labId)
                    break
                  default:
                    store.delete(data.id)
                }

                break
              default:
                alert('Error: actionId not recognized')
                catchError(data.jobNumber, data.gradeId, 'offline', 'addPending', 'actionId not recognized', '', '')
            }

            req.onerror = function(event) {
              reject(event)
            }
            req.onsuccess = function(event) {
              console.log(`added to table`)
              resolve(event.target.result)
            }

          }

        }

      }

    }

  })

  // connectToDatabase().then(
  //   (db) => {
  //     let txn = db.transaction(['Pending'], 'readwrite')
  //     let store = txn.objectStore('Pending')
  //     let query = store.put(data)
  //
  //     query.onerror = function (event) {
  //       console.log('error adding: ' + event.target.errorCode);
  //       return false
  //     }
  //
  //     let response = query.onsuccess = function (event) {
  //       return true
  //     }
  //
  //     return response
  //
  //   },
  //   (error) => console.log('error')
  //   //catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawDrawings', JSON.stringify(error), '', '')
  // )

}

export function selectData(table) {

  return new Promise((resolve, reject) => {

    let database = table === 'Pending' ? 'Pending' : 'albusData'

    let request = window.indexedDB.open(database, 1)

    request.onupgradeneeded = (event) => {

    }

    request.onerror = (event) => {
      reject(false)
    }

    request.onsuccess = (event) => {

      //console.log(`table: ${JSON.stringify(table)}`)
      let db = event.target.result;

      if (!db.objectStoreNames.contains(table)) {

        reject(false)

      } else {

        let txn = db.transaction(table);
        let objectStore = txn.objectStore(table);
        let req = objectStore.getAll();

        txn.onerror = function(event) {
          reject(false)
          //db.close()
        }

        req.onerror = function(event) {
          reject(false)
        }
        req.onsuccess = function(event) {
          resolve(event.target.result)
        }

      }

      db.close()

    }

  })

}

// CREATE DB AND TABLES

// IF YOU DELETE DB, THEN NO PENDING AND YOU LOSE RESULTS!!!!

async function destroyDatabase() {
  return new Promise(function (resolve, reject) {

    let req = indexedDB.deleteDatabase('albusData')

    req.onerror = function () {
      alert('Error: Could not download, refresh the app, and try again.')
      console.log("Couldn't delete database")
      reject(false)
    }
    req.onblocked = function () {
      alert('Blocked: Could not download, refresh the app, and try again.')
      console.log("Couldn't delete database due to the operation being blocked")
      reject(false)
    }
    req.onsuccess = function () {
      console.log("Deleted database")
      resolve(true)
    }

  })
}

async function createTable(db) {

  // connectToDatabase().then(
  //   (db) => {

      let arrTable = [
        'JobAndGrade',
        'Info',
        'DrawingPresets',
        'PresetTypes',
        'PlansDrawDrawings',
        'PlansDrawTests',
        'PlansDrawLab',
        'DistinctPresets',
        'Tests',
        'TestPrefixes',
        'TestCurves',
        'Plans',
        'Lab',
        'Dailies',
        'DailyTasks',
        'Users',
        'MyStatus',
        'MyNuke',
        'AvailableNukes',
        'Statuses'
      ]

      //db.onsuccess = async function(event) {

      let id

      for (let i=0; i < arrTable.length; i++) {
        console.log(`before ${i}`)
        let result = await Promise.resolve(i);
        //db.deleteObjectStore(tbl)

        switch(arrTable[i]) {
          case 'PlansDrawDrawings':
            id = {keyPath: 'drawId'}
            break
          case 'PlansDrawTests':
            id = {keyPath: 'testId'}
            break
          case 'PlansDrawLab':
            id = {keyPath: 'labId'}
            break
          case 'DistinctPresets':
            id = {autoIncrement: true} // SQl returns null, so fails with keypath: id
            break
          case 'DrawingPresets':
            id = {keyPath: 'presetId'}
            break
          // case 'Users':
          //   id = {autoIncrement: true} // throws error with keypath: id, even though it appears to work with id.. simple fix for now
          //   break
          default:
            id = {keyPath: 'id'}
        }

        db.createObjectStore(arrTable[i], id)
        //console.log(`after ${i}`)
      }



  //   },
  //   (error) => console.log('error')
  //   //catchError(jn, gd, 'offline', 'selectPlansDrawDrawings', JSON.stringify(error), '', '')
  // )

}

// IF YOU DELETE DB, THEN NO PENDING AND YOU LOSE RESULTS!!!!

// export async function downloadData(jn,gd){
//   destroyDatabase()
//   .then(res => connectToDatabase())
//   .then(res => createTable(res))
//   .then(res => fetchDrawings(res, jn,gd))
// }

export function downloadData(filter) {

  destroyDatabase()
  .then(res => {

    let request = window.indexedDB.open('albusData', 1);

    request.onupgradeneeded = function() {
      let db = request.result
      createTable(db)
    }

    request.onsuccess = function() {
      let db = request.result
      fetchData(db, filter)
    }

  })
  .catch(res => console.log('downloadData --> destroyDatabase --> failed'))

}

const addData = (db, table, data) => {

  let txn = db.transaction([table], 'readwrite')
  let store = txn.objectStore(table)
  let res = store.put(data)

  // Handle the success case
  res.onsuccess = function (event) {
    //console.log(event);
  };

  // Handle the error case
  res.onerror = function (event) {
    //console.log(event.target.errorCode);
  }

  // Close the database once the transaction completes
  txn.oncomplete = function () {
    //db.close();
  }

}

async function fetchData (db, filter) {

//   let arrFetch = [
//     {url: 'selectUserOffline', filter: {userName: filter.userName}},
//     {url: 'selectMenuJobAndGrade', filter: {userLevel: filter.userLevel,guestAccess: filter.guestAccess}},
//     {url: 'selectPlansDrawDrawings', filter: {filter: filter}},
//     {url: 'selectPlansDrawLab', filter: {filter: filter}},
//     {url: 'selectPlansDrawTests', filter: {filter: filter}},
//     {url: 'distinctPresets', filter: {filter: filter}},
//     {url: 'selectPresetTypes', filter: null},
//     {url: 'selectPresets', filter: null},
//     {url: 'selectTests', filter: {filter: filter}},
//     {url: 'selectMenuPrefixes', filter: {gradeId: filter.gradeId}},
//     {url: 'selectMenuCurves', filter: {jobNumber: filter.jobNumber}},
//     {url: 'selectPlans', filter: {filter: filter}},
//     {url: 'selectLab', filter: {filter: filter}},
//     {url: 'selectDailies', filter: {filter: filter, userLevel: filter.userlevel}},
//     {url: 'selectDailyTasks', filter: {gradeId: filter.gradeId}},
//     {url: 'selectInfo', filter: {filter: filter}},
//     {url: 'myStatus', filter: {by: filter.userName, time: formatDateTime(new Date())}},
//     {url: 'selectStatuses', filter: {by: filter.userName, time: formatDateTime(new Date())}},
//     {url: 'myNuke', filter: {by: filter.userName, time: formatDateTime(new Date())}},
//     {url: 'selectAvailableNukes', filter: {by: filter.userName, time: formatDateTime(new Date())}},
//   ]
//
//   // fetch('/api/selectUserOffline', {
//   //   method: 'post',
//   //   headers: {
//   //     'Accept': 'application/json, text/plain, */*',
//   //     'Content-Type': 'application/json'
//   //   },
//   //   body: JSON.stringify({userName: filter.userName})
//   // })
//   // .then(res=>res.json())
//   // .then(
//   //   (result) => {
//   //     //console.log(JSON.stringify(result))
//   //
//   //     result = result.map(data => ({...data, device: getDeviceType(), nukeId: ''}))
//   //     result.forEach(data => addData(db, 'Users', data))
//   //
//   //   },
//   //   (error) => catchError('', '', 'offline', 'login', JSON.stringify(error), '', '')
//   // )
//
//   await Promise.all(arrFetch.map(data => {
// console.log(`data: ${JSON.stringify(data.filter)}`)
//
//     // let response = fetch('/api/selectUserOffline', {
//     //   method: 'post',
//     //   headers: {
//     //     'Accept': 'application/json, text/plain, */*',
//     //     'Content-Type': 'application/json'
//     //   },
//     //   body: JSON.stringify({userName: filter.userName})
//     // })
//     // .then(res=>res.json())
//
//
//     let response = fetch(`/api/${data.url}`, {
//       method: 'post',
//       headers: {
//         'Accept': 'application/json, text/plain, */*',
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(data.filter)
//     })
//     .then(res => ({res: res.json(), url: data.url}))
//     //.catch(error => catchError(filter.jn, filter.gd, 'offline', data.url, JSON.stringify(error), '', ''))
//     console.log(`response: ${JSON.stringify(response)}`)
//     return response
//
//
//   }))
//   .then(result => {
//     console.log(`result: ${JSON.stringify(result)}`)
//     for (let i=0; i < result.length; i++) {
//       //console.log(`before ${i}`)
//       //let response = await Promise.resolve(i)
//       let arr
//       switch(result[i].url) {
//         case 'selectUserOffline':
//           //result[i].resmap(data => addData(db, 'Users', {...data, device: getDeviceType(), nukeId: ''}))
//           //arr = result[i].res.map(data => ({...data, device: getDeviceType(), nukeId: ''}))
//           result[i].res.forEach(data => addData(db, 'Users', {...data, device: getDeviceType(), nukeId: ''}))
//           break
//         case 'selectMenuJobAndGrade':
//           result[i].res.forEach(data => addData(db, 'JobAndGrade', data))
//           break
//         case 'selectPlansDrawDrawings':
//           //result[i].res.map(data => addData(db, 'PlansDrawDrawings', {...data, x: data.x.split(","), y: data.y.split(","), n: data.n.split(","), e: data.e.split(","), visible: true, entrytime: formatDateTime(data.entrytime), modtime: formatDateTime(data.modtime)}))
//           //arr = result[i].res.map(data => ({...data, x: data.x.split(","), y: data.y.split(","), n: data.n.split(","), e: data.e.split(","), visible: true, entrytime: formatDateTime(data.entrytime), modtime: formatDateTime(data.modtime)}))
//           result[i].res.forEach(data => addData(db, 'PlansDrawDrawings', {...data, x: data.x.split(","), y: data.y.split(","), n: data.n.split(","), e: data.e.split(","), visible: true, entrytime: formatDateTime(data.entrytime), modtime: formatDateTime(data.modtime)}))
//           break
//         case 'selectPlansDrawTests':
//           //arr = result[i].res.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
//           result[i].res.forEach(data => addData(db, 'PlansDrawTests', {...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
//           break
//         case 'selectPlansDrawLab':
//           //arr = result[i].res.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
//           result[i].res.forEach(data => addData(db, 'PlansDrawLab', {...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
//           break
//         case 'distinctPresets':
//           //arr = result[i].res.map(data => ({...data, status: true}))
//
//           result[i].res.push(
//             {
//               id: -1,
//               presetName: 'Tests',
//               color: '#000000FF'
//             },
//             {
//               id: -2,
//               presetName: 'Lab',
//               color: '#964B00FF'
//             },
//             {
//               id: -3,
//               presetName: 'Hide All',
//               color: '#000000FF'
//             }
//           )
//
//           result[i].res.forEach(data => addData(db, 'DistinctPresets', {...data, status: data.id === -3 ? false : true}))
//           break
//         case 'selectPresetTypes':
//           result[i].res.forEach(data => addData(db, 'PresetTypes', data))
//           break
//         case 'selectPresets':
//           result[i].res.forEach(data => addData(db, 'DrawingPresets', data))
//           break
//         case 'selectTests':
//           // arr = result[i].res.map((data, i) => {
//           //
//           //   let relComp = Math.round((data.drydens/data.optd)*100)
//           //
//           //   let passFail = relComp >= data.reqcomp ? 'P' : 'F'
//           //
//           //   return {...data,
//           //     relcomp: relComp,
//           //     passfail: passFail,
//           //     entrytime: formatDateTime(data.entrytime),
//           //     modtime: formatDateTime(data.modtime),
//           //     testdate: formatDateYMD(data.testdate)
//           //   }
//           //
//           // })
//
//           result[i].res.forEach(data => {
//             let relComp = Math.round((data.drydens/data.optd)*100)
//             let passFail = relComp >= data.reqcomp ? 'P' : 'F'
//
//             addData(db, 'Tests', {...data,
//               relcomp: relComp,
//               passfail: passFail,
//               entrytime: formatDateTime(data.entrytime),
//               modtime: formatDateTime(data.modtime),
//               testdate: formatDateYMD(data.testdate)
//             })
//           })
//           break
//         case 'selectMenuPrefixes':
//           result[i].res.forEach(data => addData(db, 'TestPrefixes', data))
//           break
//         case 'selectMenuCurves':
//           result[i].res.forEach(data => addData(db, 'TestCurves', data))
//           break
//         case 'selectPlans':
//           result[i].res.forEach(data => {
//
//             //getBase64OfPlan(`/plans/plan${data.id}.pdf`)
//             //__dirname + `/../../public_html/field
//             //https://beta.albus-data.net/field/plans/plan87.pdf
//             //getBase64OfPlan(`https://albus-data.net/field/plans/plan${data.id}.pdf`)
//             getBase64OfPlan(`/plans/plan${data.id}.pdf`)
//             .then(result => {
//               addData(db, 'Plans', {...data,
//                 base64: result
//               })
//             })
//             .catch(error => {
//               catchError('', '', 'offline', 'getBase64OfPlan', JSON.stringify(error), '', '')
//             })
//
//           })
//           break
//         case 'selectLab':
//           // result = result.map(data => ({...data,
//           //   entrytime: formatDateTime(data.entrytime),
//           //   modtime: formatDateTime(data.modtime),
//           //   sampledate: formatDateYMD(data.sampledate)
//           // }))
//
//           result[i].res.forEach(data => addData(db, 'Lab', {...data,
//             entrytime: formatDateTime(data.entrytime),
//             modtime: formatDateTime(data.modtime),
//             sampledate: formatDateYMD(data.sampledate)
//           }))
//           break
//         case 'selectDailies':
//           result[i].res.forEach(data => addData(db, 'Dailies', {...data,
//             entrytime: formatDateTime(data.entrytime),
//             modtime: formatDateTime(data.modtime),
//             dailiesdate: formatDateYMD(data.dailiesdate)
//           }))
//           break
//         case 'selectDailyTasks':
//           result[i].res.forEach(data => addData(db, 'DailyTasks', data))
//           break
//         case 'selectInfo':
//           result[i].res.forEach(data => addData(db, 'Info', data))
//           break
//         case 'myStatus':
//           result[i].res.forEach(data => addData(db, 'MyStatus', data))
//           break
//         case 'selectStatuses':
//           result[i].res.forEach(data => addData(db, 'Statuses', data))
//           break
//         case 'myNuke':
//           result[i].res.forEach(data => addData(db, 'MyNuke', data))
//           break
//         case 'selectAvailableNukes':
//           result[i].res.forEach(data => addData(db, 'AvailableNukes', data))
//           break
//         default:
//           console.log('nothing')
//       }
//
//       //console.log(`after ${i}`)
//     }
//   })
//   .then(result => db.close)
//   .catch(error => console.log(`Error: fetchData --> ${error}`))

  // User

  fetch('/api/selectUserOffline', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({userName: filter.userName})
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log(JSON.stringify(result))

      result = result.map(data => ({...data, device: getDeviceType(), nukeId: ''}))
      result.forEach(data => addData(db, 'Users', data))

    },
    (error) => catchError('', '', 'offline', 'login', JSON.stringify(error), '', '')
  )

  // Jobs and Grades

  fetch('/api/selectMenuJobAndGrade', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userLevel: filter.userLevel,
      guestAccess: filter.guestAccess
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'JobAndGrade', data))
    },
    (error) => catchError('', '', 'offline', 'selectMenuJobAndGrade', JSON.stringify(error), '', '')

  )

  // Drawings

  fetch('/api/selectPlansDrawDrawings', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      //console.log(`db addData: ${db}`)
      let arrayDraw = result.map(data => ({...data, x: data.x.split(","), y: data.y.split(","), n: data.n.split(","), e: data.e.split(","), visible: true, entrytime: formatDateTime(data.entrytime), modtime: formatDateTime(data.modtime)}))
      //console.log('data: ' + arrayDraw)
      arrayDraw.forEach(data => addData(db, 'PlansDrawDrawings', data))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawDrawings', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPlansDrawLab', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      //arrayLab.current = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
      //arrayLabTemp.current = arrayLab.current

      let arrayLab = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))

      arrayLab.forEach(data => addData(db, 'PlansDrawLab', data))

      //redraw('refresh')

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawLab', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPlansDrawTests', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      //arrayTest.current = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
      //arrayTestTemp.current = arrayTest.current

      let arrayTest = result.map(data => ({...data, visible: true, entrytime: formatDateTime(data.entrytime, true), modtime: formatDateTime(data.modtime, true)}))
      arrayTest.forEach(data => addData(db, 'PlansDrawTests', data))


      //redraw('refresh')

      // fetch here since arrayTest.current.length is used

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'selectPlansDrawTests', JSON.stringify(error), '', '')

  )

  fetch('/api/distinctPresets', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))

      result = result.map(data => ({...data, status: true}))

      result.push(
        {
          id: -1,
          presetName: 'Tests',
          color: '#000000FF',
          status: true
        },
        {
          id: -2,
          presetName: 'Lab',
          color: '#964B00FF',
          status: true
        },
        {
          id: -3,
          presetName: 'Hide All',
          color: '#000000FF',
          status: false
        }
      )

      result.forEach(data => addData(db, 'DistinctPresets', data))

      //setDistinctPresets(result.map(preset => ({...preset, status: preset.presetName === 'Hide All' ? false : true})))

    },
    (error) => catchError(filter.jn, filter.gd, 'offline', 'distinctPresets', JSON.stringify(error), '', '')

  )

  fetch('/api/selectPresetTypes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'PresetTypes', data))
    },
    (error) => catchError('', '', 'offline', 'selectPresetTypes', JSON.stringify(error), '', '')
  )

  fetch('/api/selectPresets', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'DrawingPresets', data))
    },
    (error) => catchError('', '', 'offline', 'selectPresets', JSON.stringify(error), '', '')
  )

  // Tests

  fetch('/api/selectTests', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result = result.map((data, i) => {

        let relComp = Math.round((data.drydens/data.optd)*100)

        let passFail = relComp >= data.reqcomp ? 'P' : 'F'

        return {...data,
          relcomp: relComp,
          passfail: passFail,
          entrytime: formatDateTime(data.entrytime),
          modtime: formatDateTime(data.modtime),
          testdate: formatDateYMD(data.testdate)
        }

      })

      result.forEach(data => addData(db, 'Tests', data))

    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectTests', JSON.stringify(error), '', '')

  )

  fetch('/api/selectMenuPrefixes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      gradeId: filter.gradeId
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'TestPrefixes', data))
    },
    (error) => {
      catchError('', filter.gradeId, 'offline', 'selectMenuPrefixes', JSON.stringify(error), '', '')
    }
  )

  fetch('/api/selectMenuCurves', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      jobNumber: filter.jobNumber
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'TestCurves', data))
    },
    (error) => {
      catchError(filter.jobNumber, '', 'offline', 'selectMenuCurves', JSON.stringify(error), '', '')
    }
  )

  // Plans

  fetch('/api/selectPlans', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('Plan result: ' + JSON.stringify(result))

      result.forEach(data => {

        //getBase64OfPlan(`/plans/plan${data.id}.pdf`)
        //__dirname + `/../../public_html/field
        //https://beta.albus-data.net/field/plans/plan87.pdf
        //getBase64OfPlan(`https://albus-data.net/field/plans/plan${data.id}.pdf`)
        getBase64OfPlan(`/plans/plan${data.id}.pdf`)
        .then(result => {
          //console.log('Plan 64: ' + JSON.stringify(result))
          addData(db, 'Plans', {...data,
            base64: result
          })
        })
        .catch(error => {
          catchError('', '', 'offline', 'getBase64OfPlan', JSON.stringify(error), '', '')
        })

      })
    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectPlans', JSON.stringify(error), '', '')
    }
  )

  // Lab

  fetch('/api/selectLab', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result = result.map(data => ({...data,
        entrytime: formatDateTime(data.entrytime),
        modtime: formatDateTime(data.modtime),
        sampledate: formatDateYMD(data.sampledate)
      }))

      result.forEach(data => addData(db, 'Lab', data))

    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectLab', JSON.stringify(error), '', '')
    }
  )

  // Dailies

  fetch('/api/selectDailies', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter,
      userLevel: filter.userlevel
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('fetching data...')

      result = result.map(data => ({...data,
        entrytime: formatDateTime(data.entrytime),
        modtime: formatDateTime(data.modtime),
        dailiesdate: formatDateYMD(data.dailiesdate)
      }))

      result.forEach(data => addData(db, 'Dailies', data))

    },
    (error) => {
      catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectDailies', JSON.stringify(error), '', '')
    }
  )

  fetch('/api/selectDailyTasks', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      gradeId: filter.gradeId
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      result.forEach(data => addData(db, 'DailyTasks', data))

    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectDailyTasks', JSON.stringify(error), '', '')
  )

  // Info

  fetch('/api/selectInfo', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      filter: filter
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)
      result.forEach(data => addData(db, 'Info', data))
    },
    (error) => catchError(filter.jobNumber, filter.gradeId, 'offline', 'selectInfo', JSON.stringify(error), '', '')
  )

  // Status

  fetch('/api/myStatus', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'MyStatus', data))
    },
    (error) => catchError('', '', 'offline', 'myStatus', JSON.stringify(error), '', '')
  )

  fetch('/api/selectStatuses', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'Statuses', data))
    },
    (error) => catchError('', '', 'offline', 'selectStatuses', JSON.stringify(error), '', '')

  )

  // Nukes

  fetch('/api/myNuke', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'MyNuke', data))
    },
    (error) => catchError('', '', 'offline', 'myNuke', JSON.stringify(error), '', '')
  )

  fetch('/api/selectAvailableNukes', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      by: filter.userName,
      time: formatDateTime(new Date())
    })
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + JSON.stringify(result))
      result.forEach(data => addData(db, 'AvailableNukes', data))
    },
    (error) => catchError('', '', 'offline', 'selectAvailableNukes', JSON.stringify(error), '', '')
  )

}
