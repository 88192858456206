import React, { useState, useEffect, useRef} from 'react'

import { formatDateYMD, formatDateTime } from 'scripts/common'

const Stats = (props) => {

  //const [fetchedData, setFetchedData] = useState([])

  const [fetchedUsers, setFetchedUsers] = useState([])
  const [fetchedStatuses, setFetchedStatuses] = useState([])

  const [fetchedTests, setFetchedTests] = useState([])
  const [fetchedTestTypes, setFetchedTestTypes] = useState([])
  const [fetchedTestDateDiff, setFetchedTestDateDiff] = useState([])

  const [fetchedLab, setFetchedLab] = useState([])

  const [fetchedDailies, setFetchedDailies] = useState([])
  //const [fetchedDailiesDateDiff, setFetchedDailiesDateDiff] = useState([])

  const [fetchedDrawings, setFetchedDrawings] = useState([])

  const [fetchedDevices, setFetchedDevices] = useState([])

  // ======================

  //const [myUnsignedDailies, setMyUnsignedDailies] = useState([])
  //const [myDailies, setMyDailies] = useState([])

  const fetchData = () => {

    // fetch('/api/selectStatsUsers', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     //setFetchedUsers(result)
    //
    //     //let arrUser = result.map(data => {...data, daily: 0})
    //
    //     const objUser = result.reduce((o, data) => ({...o, [data.username]: {daily: 0}}), {})
    //
    //     console.log(`users: ${JSON.stringify(objUser)}`)
    //
    //     userScores.current = objUser
    //
    //     //setFetchedUsers(arrUser)
    //
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsUsers --> ' + error)
    //   }
    // )

    fetch('/api/selectStatsStatuses', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedStatuses(result)
      },
      (error) => {
        console.log('Error: selectStatsStatuses --> ' + error)
      }
    )

    // fetch('/api/selectStatsTests', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     setFetchedTests(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsTests --> ' + error)
    //   }
    // )

    fetch('/api/selectStatsTestTypes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedTestTypes(result)
      },
      (error) => {
        console.log('Error: selectStatsTestTypes --> ' + error)
      }
    )

    // fetch('/api/selectStatsTestDateDiff', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + JSON.stringify(result))
    //     setFetchedTestDateDiff(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsTestDateDiff --> ' + error)
    //   }
    // )

    // fetch('/api/selectStatsLab', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     setFetchedLab(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectLab --> ' + error)
    //   }
    // )
    //
    fetch('/api/selectStatsDailies', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDailies(result)
      },
      (error) => {
        console.log('Error: selectStatsDailies --> ' + error)
      }
    )

    fetch('/api/selectAnalyzeDevices', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedDevices(result)
      },
      (error) => {
        console.log('Error: selectAnalyzeDevices --> ' + error)
      }
    )

    // fetch('/api/selectStatsDailiesDateDiff', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     filter: props.filter
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + JSON.stringify(result))
    //     setFetchedDailiesDateDiff(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsDailiesDateDiff --> ' + error)
    //   }
    // )

    // fetch('/api/selectStatsDrawings', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     setFetchedDrawings(result)
    //   },
    //   (error) => {
    //     console.log('Error: selectStatsDrawings --> ' + error)
    //   }
    // )

    // =================

    // fetch('/api/myUnsignedDailies', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     setMyUnsignedDailies(result)
    //   },
    //   (error) => {
    //     console.log('Error: myUnsignedDailies --> ' + error)
    //   }
    // )

    // fetch('/api/myDailies', {
    //   method: 'post',
    //   headers: {
    //     'Accept': 'application/json, text/plain, */*',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     by: props.user.username,
    //     time: formatDateTime(new Date())
    //   })
    // })
    // .then(res=>res.json())
    // .then(
    //   (result) => {
    //     //console.log('result: ' + result)
    //     setMyDailies(result)
    //   },
    //   (error) => {
    //     console.log('Error: myDailies --> ' + error)
    //   }
    // )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  let styleProgressBar = {
    cursor: 'pointer',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    marginRight: 5,
    overflow: 'hidden'
  }

  let titleDateDiff = 'Entry time VS selected Date (days). 0 = good, >0 = not good'

  // ========= HANDLE STATUSES =========

  let styleStart, styleArrive, styleBreak, styleDepart, styleEnd

  let statsStatuses = fetchedStatuses.map(data => {
    let total = data.countStart + data.countArrive + data.countBreak + data.countDepart + data.countEnd
    let sPer = Math.round(10*data.countStart/total*100)/10
    let aPer = Math.round(10*data.countArrive/total*100)/10
    let bPer = Math.round(10*data.countBreak/total*100)/10
    let dPer = Math.round(10*data.countDepart/total*100)/10
    let ePer = Math.round(10*data.countEnd/total*100)/10

    styleStart = {...styleProgressBar, backgroundColor: 'dodgerblue', width: `${sPer}%`}
    styleArrive = {...styleProgressBar, backgroundColor: 'mediumseagreen', width: `${aPer}%`}
    styleBreak = {...styleProgressBar, backgroundColor: 'lightgray', width: `${bPer}%`}
    styleDepart = {...styleProgressBar, backgroundColor: 'orange', width: `${dPer}%`}
    styleEnd = {...styleProgressBar, backgroundColor: 'tomato', width: `${ePer}%`}

    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>{data.entryby} ({total})</div></div>
        {sPer > 0 ? <div title={`${data.countStart} (${sPer}%)`} style={styleStart}><small>{data.countStart} ({sPer}%)</small></div> : null}
        {aPer > 0 ? <div title={`${data.countArrive} (${aPer}%)`} style={styleArrive}><small>{data.countArrive} ({aPer}%)</small></div> : null}
        {bPer > 0 ? <div title={`${data.countBreak} (${bPer}%)`} style={styleBreak}><small>{data.countBreak} ({bPer}%)</small></div> : null}
        {dPer > 0 ? <div title={`${data.countDepart} (${dPer}%)`} style={styleDepart}><small>{data.countDepart} ({dPer}%)</small></div> : null}
        {ePer > 0 ? <div title={`${data.countEnd} (${ePer}%)`} style={styleEnd}><small>{data.countEnd} ({ePer}%)</small></div> : null}
      </div>
    )
  })

  // ========= HANDLE TESTS =========

  let styleS, styleN, styleD, avgTestPerHour

  let statsTestTypes = fetchedTestTypes.map(data => {
    let total = data.countS + data.countN + data.countD
    let sPer = Math.round(10*data.countS/total*100)/10
    let nPer = Math.round(10*data.countN/total*100)/10
    let dPer = Math.round(10*data.countD/total*100)/10
    let sumHours = fetchedDailies.find(res => res.entryby === data.entryby)

    //console.log(`sumHours: ${JSON.stringify(sumHours)}`)
    let avgTestPerHour = sumHours === undefined ? 'no tests' : Math.round(total / sumHours.sumHours * 10) / 10
    //console.log(`avgTestPerHour: ${avgTestPerHour}`)

    styleS = {...styleProgressBar, backgroundColor: 'dodgerblue', width: `${sPer}%`}
    styleN = {...styleProgressBar, backgroundColor: 'orange', width: `${nPer}%`}
    styleD = {...styleProgressBar, backgroundColor: 'lightgray', width: `${dPer}%`}

    //console.log(` total: ${total}, incPer: ${incPer}, comPer: ${comPer}, signPer: ${signPer}`)
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>{data.entryby} ({total})</div></div>
        {sPer > 0 ? <div title={`${data.countS} (${sPer}%)`} style={styleS}><small>{data.countS} ({sPer}%)</small></div> : null}
        {nPer > 0 ? <div title={`${data.countN} (${nPer}%)`} style={styleN}><small>{data.countN} ({nPer}%)</small></div> : null}
        {dPer > 0 ? <div title={`${data.countD} (${dPer}%)`} style={styleD}><small>{data.countD} ({dPer}%)</small></div> : null}
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}} title={titleDateDiff}>{Math.round(data.dateDiff * 100) / 100}</div></div>
        <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>{avgTestPerHour}</div></div>
      </div>
    )
  })

  // ========= HANDLE DAILIES =========

  let styleInc, styleCom, styleSign

  let statsDailies = fetchedDailies.map(data => {
    let total = data.countIncomplete + data.countComplete + data.countSigned
    let incPer = Math.round(10*data.countIncomplete/total*100)/10
    let comPer = Math.round(10*data.countComplete/total*100)/10
    let signPer = Math.round(10*data.countSigned/total*100)/10

    styleInc = {...styleProgressBar, backgroundColor: 'tomato', width: `${incPer}%`}
    styleCom = {...styleProgressBar, backgroundColor: 'orange', width: `${comPer}%`}
    styleSign = {...styleProgressBar, backgroundColor: 'dodgerblue', width: `${signPer}%`}

    //console.log(` total: ${total}, incPer: ${incPer}, comPer: ${comPer}, signPer: ${signPer}`)
    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>{data.entryby} ({total})</div></div>
        {incPer > 0 ? <div title={`${data.countIncomplete} (${incPer}%)`} style={styleInc}><small>{data.countIncomplete} ({incPer}%)</small></div> : null}
        {comPer > 0 ? <div title={`${data.countComplete} (${comPer}%)`} style={styleCom}><small>{data.countComplete} ({comPer}%)</small></div> : null}
        {signPer > 0 ? <div title={`${data.countSigned} (${signPer}%)`} style={styleSign}><small>{data.countSigned} ({signPer}%)</small></div> : null}
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}} title={titleDateDiff}>{Math.round(data.dateDiff * 100) / 100}</div></div>
        <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>{data.sumHours}</div></div>
        <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>{Math.round(10*data.sumHours/total)/10}</div></div>
      </div>
    )
  })


  // ========= HANDLE DEVICES =========

  let styleT, styleDe, styleM

  let statsDevices = fetchedDevices.map(data => {
    let total = data.countT + data.countD + data.countM
    let tPer = Math.round(10*data.countT/total*100)/10
    let dPer = Math.round(10*data.countD/total*100)/10
    let mPer = Math.round(10*data.countM/total*100)/10

    styleT = {...styleProgressBar, backgroundColor: 'dodgerblue', width: `${tPer}%`}
    styleDe = {...styleProgressBar, backgroundColor: 'orange', width: `${dPer}%`}
    styleM = {...styleProgressBar, backgroundColor: 'lightgray', width: `${mPer}%`}

    return (
      <div style={{display: 'flex', justifyContent: 'flex-start', width: '90%', height: 20, margin: 10}}>
        <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>{data.entryby} ({total})</div></div>
        {tPer > 0 ? <div title={`${data.countT} (${tPer}%)`} style={styleT}><small>{data.countT} ({tPer}%)</small></div> : null}
        {dPer > 0 ? <div title={`${data.countD} (${dPer}%)`} style={styleDe}><small>{data.countD} ({dPer}%)</small></div> : null}
        {mPer > 0 ? <div title={`${data.countM} (${mPer}%)`} style={styleM}><small>{data.countM} ({mPer}%)</small></div> : null}
      </div>
    )
  })

  return (
    <>
      <div>
      <h3>Statuses</h3>
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>

          <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>User</div></div>

          <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div style={{marginRight: 5}}>Start</div>
            <div style={{backgroundColor: 'dodgerblue', borderRadius: 5, marginRight: 5, width: 25}}></div>
            <div style={{marginRight: 5}}>Arrive</div>
            <div style={{backgroundColor: 'mediumseagreen', borderRadius: 5, marginRight: 5, width: 25}}></div>
            <div style={{marginRight: 5}}>Break</div>
            <div style={{backgroundColor: 'lightgray', borderRadius: 5, marginRight: 5, width: 25}}></div>
            <div style={{marginRight: 5}}>Depart</div>
            <div style={{backgroundColor: 'orange', borderRadius: 5, marginRight: 5, width: 25}}></div>
            <div style={{marginRight: 5}}>End</div>
            <div style={{backgroundColor: 'tomato', borderRadius: 5, marginRight: 5, width: 25}}></div>
          </div>

        </div>
        {statsStatuses}
      </div>
      </div>

      <div>
        <h3>Tests</h3>
        <div>
          <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>

            <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>User</div></div>

            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <div style={{marginRight: 5}}>Sand</div>
              <div style={{backgroundColor: 'dodgerblue', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Nuke</div>
              <div style={{backgroundColor: 'orange', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Knocker</div>
              <div style={{backgroundColor: 'lightgray', borderRadius: 5, marginRight: 5, width: 25}}></div>
            </div>

            <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}} title={titleDateDiff}>Time to Enter (days)</div></div>

            <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>Avg Test/hr</div></div>

          </div>
          {statsTestTypes}
        </div>
      </div>

      <div>
        <h3>Dailies</h3>

        <div>
          <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>

            <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>User</div></div>

            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <div style={{marginRight: 5}}>Incomp.</div>
              <div style={{backgroundColor: 'tomato', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Complete</div>
              <div style={{backgroundColor: 'orange', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Signed</div>
              <div style={{backgroundColor: 'dodgerblue', borderRadius: 5, marginRight: 5, width: 25}}></div>
            </div>

            <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}} title={titleDateDiff}>Time to Enter (days)</div></div>

            <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>Total Hrs</div></div>

            <div style={{textAlign: 'right'}}><div style={{width: 100, marginRight: 5}} title={titleDateDiff}>Avg Hrs/day</div></div>

          </div>
          {statsDailies}
        </div>
      </div>

      <div>
        <h3>Lab</h3>

        <div>
          <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>

              <p>hmm</p>

          </div>
        </div>
      </div>

      <div>
        <h3>Devices</h3>
        <div>
          <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', height: 20, margin: 10}}>

            <div style={{textAlign: 'right'}}><div style={{width: 150, marginRight: 5}}>User</div></div>

            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
              <div style={{marginRight: 5}}>Tablet</div>
              <div style={{backgroundColor: 'dodgerblue', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Desktop</div>
              <div style={{backgroundColor: 'orange', borderRadius: 5, marginRight: 5, width: 25}}></div>
              <div style={{marginRight: 5}}>Mobile</div>
              <div style={{backgroundColor: 'lightgray', borderRadius: 5, marginRight: 5, width: 25}}></div>
            </div>

          </div>
          {statsDevices}
        </div>
      </div>
    </>
  )
}

export default Stats
