import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import CustomTools from 'components/utils/CustomTools'
import Plans from 'components/main/Plans'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'

const Drawings = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    plan: false,
    google: false,
    loading: true
  })
  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    typeId: null,
    elev: '',
    description: '',
    tool: '',
    color: '',
    width: '',
    line: '',
    lineName: 'solid', // this determines which line is selected, had issues comparing an array for the border
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    typeId: null,
    elev: '',
    description: '',
    tool: '',
    color: '',
    width: '',
    line: '',
    lineName: 'solid', // this determines which line is selected, had issues comparing an array for the border
  })

  const fetchData = () => {

    fetch('/api/selectDrawings', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: props.filter.jobNumber,
        gradeId: props.filter.gradeId,
        user: props.filter.user,
        startDate: props.filter.startDate,
        endDate: props.filter.endDate
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)

        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('Error: selectDrawings --> ' + error)
      }
    )

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {

        let jn = data.jobnumber === null ? '' : data.jobnumber
        let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
        let presetName = data.presetName === null ? '' : data.presetName
        let description = data.description === null ? '' : data.description
        let tool =  data.presetId === 0 ? data.tool === null ? '' : data.tool : data.presetTool === null ? '' : data.presetTool
        let color =  data.presetId === 0 ? data.color === null ? '' : data.color : data.presetColor === null ? '' : data.presetColor
        let width =  data.presetId === 0 ? data.width === null ? '' : data.width : data.presetWidth === null ? '' : data.presetWidth
        let line =  data.presetId === 0 ? data.line === null ? '' : data.line : data.presetLine === null ? '' : data.presetLine
        let entryby = data.entryby === null ? '' : data.entryby
        let entrytime = data.entrytime === null || data.entrytime === '' ? '' : formatDateYMD(data.entrytime, true)

        return true

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, pre, number, suf, testNo

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      // pre = data[i].pre === null ? '' : data[i].pre
      // number = data[i].testno === null ? '' : data[i].testno
      // suf = data[i].suf === null ? '' : data[i].suf
      // testNo = `${pre} ${number} ${suf}`

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: '',
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i])
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id
      }))
      openEdit()

    }

  }

  const editDrawing = () => {

    alert('still sorting out the full scope of this component')

    // if (props.user.drawing < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    // } else if (isValidated.userName === null || isValidated.userName ==='') {
    //     alert("Please provide a user name.");
    // } else if (isValidated.userPW === null || isValidated.userPW ==='') {
    //     alert("Please provide a user password.");
    // } else if (isValidated.userLevel === null || isValidated.userLevel ==='') {
    //     alert("Please provide a user level.");
    // } else  if (isValidated.fullName === null || isValidated.fullName ==='') {
    //     alert("Please provide a full name.");
    // } else if (isValidated.email === null || isValidated.email ==='') {
    //     alert("Please provide an email.");
    // } else if (isValidated.phone === null || isValidated.phone ==='') {
    //     alert("Please a phone number.");
    // } else if (isValidated.userLevel === 'guest' && (isValidated.guestAccess === null || isValidated.guestAccess === '')) {
    //     alert("This user is a guest. Please provide jobs they may access.");
    // } else {
    //
    //   getLocation(function(latlng){
    //
    //     fetch('/api/editUser', {
    //       method: 'post',
    //       headers: {
    //         'Accept': 'application/json, text/plain, */*',
    //         'Content-Type': 'application/json'
    //       },
    //       body: JSON.stringify({
    //         by: props.user.username,
    //         time: formatDateTime(new Date()),
    //         lat: latlng.lat,
    //         lng: latlng.lng,
    //         id: isValidated.id,
    //         userName: isValidated.userName,
    //         userPW: isValidated.userPW,
    //         userLevel: isValidated.userLevel,
    //         fullName: isValidated.fullName,
    //         email: isValidated.email,
    //         phone: isValidated.phone,
    //         guestAccess: isValidated.guestAccess,
    //         status: isValidated.status
    //       })
    //     })
    //     .then(res=>res.json())
    //     .then(
    //       (result) => {
    //         //console.log('result: ' + JSON.stringify(result))
    //
    //         setFetchedData(fetchedData.map(data =>
    //           data.userid === isValidated.id ?
    //           {...data,
    //             modby: props.user.username,
    //             modtime: formatDateTime(new Date()),
    //             modlat: latlng.lat,
    //             modlng: latlng.lng,
    //             username: isValidated.userName,
    //             userpw: isValidated.userPW,
    //             userlevel: isValidated.userLevel,
    //             fullname: isValidated.fullName,
    //             email: isValidated.email,
    //             phone: isValidated.phone,
    //             guestaccess: isValidated.guestAccess,
    //             status: isValidated.status
    //           } :
    //           data
    //         ))
    //
    //         closeModal()
    //         alert('Updated')
    //
    //       },
    //       (error) => {
    //
    //         alert('Error: could not edit user. Contact and admin.')
    //         console.log('Error: editUser --> ' + error)
    //       }
    //     )
    //
    //   })
    //
    // }

  }

  const deleteDrawing = () => {

    if (props.user.drawing < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteDrawing', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData()
            alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete drawing. Contact and admin.')
            console.log('Error: deleteDrawing --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const missingLocation = () => alert('Missing latitude and longitude.')

  const viewEntryLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.entrylat},${isValidated.entrylng}`)

  const viewModLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.modlat},${isValidated.modlng}`)

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const togglePlan = () => setIsModal(prevState => ({...prevState, plan: isModal.plan ? false : true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const styleTool = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, tool: value}))
  }

  const styleColor = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, color: value}))
  }

  const styleColorOther = () => alert('under construction')

  const styleWidth = (e) => {
    let value = e.target.getAttribute('data-id')
    setIsValidated(prevState => ({...prevState, width: value}))
  }

  const styleLine = (e) => {

    let name = e.target.getAttribute('data-name') // i struggled with comparing an array for the border, so this was a quick fix
    let value = e.target.getAttribute('data-id')

    setIsValidated(prevState => ({...prevState, line: value, lineName: name}))
  }

  const filterData = (data) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let presetName = data.presetName === null ? '' : data.presetName
    let description = data.description === null ? '' : data.description
    let tool =  data.presetId === 0 ? data.tool === null ? '' : data.tool : data.presetTool === null ? '' : data.presetTool
    let color =  data.presetId === 0 ? data.color === null ? '' : data.color : data.presetColor === null ? '' : data.presetColor
    let width =  data.presetId === 0 ? data.width === null ? '' : data.width : data.presetWidth === null ? '' : data.presetWidth
    let line =  data.presetId === 0 ? data.line === null ? '' : data.line : data.presetLine === null ? '' : data.presetLine
    let entryby = data.entryby === null ? '' : data.entryby
    let entrytime = data.entrytime === null || data.entrytime === '' ? '' : formatDateYMD(data.entrytime, true)

    if (
      searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      presetName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      tool.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryby.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entrytime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {

      return true

    } else {

      return false

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    //let select = "SELECT plansdrawid AS drawId, x, y, n, e, presetid AS presetId, tblplansdraw.tool AS tool, tblplansdraw.color AS color, tblplansdraw.width AS width, tblplansdraw.line AS line, fill, tblplansdraw.description AS description, tblplansdraw.entryby AS entryby, tblplansdraw.entrytime AS entrytime, tblplansdraw.modby AS modby, tblplansdraw.modtime AS modtime,"
    //let selectP = " tblplansdraw_style_preset.description AS presetName, tblplansdraw_style_preset.tool AS presetTool, tblplansdraw_style_preset.color AS presetColor, tblplansdraw_style_preset.width AS presetWidth, tblplansdraw_style_preset.line AS presetLine"

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let presetName = data.presetName === null ? '' : data.presetName
    let description = data.description === null ? '' : data.description
    let tool =  data.presetId === 0 ? data.tool === null ? '' : data.tool : data.presetTool === null ? '' : data.presetTool
    let color =  data.presetId === 0 ? data.color === null ? '' : data.color : data.presetColor === null ? '' : data.presetColor
    let width =  data.presetId === 0 ? data.width === null ? '' : data.width : data.presetWidth === null ? '' : data.presetWidth
    let line =  data.presetId === 0 ? data.line === null ? '' : data.line : data.presetLine === null ? '' : data.presetLine
    let entryby = data.entryby === null ? '' : data.entryby
    let entrytime = data.entrytime === null || data.entrytime === '' ? '' : formatDateYMD(data.entrytime, true)

    let filter = filterData(data)
    //console.log(`filter ${filter}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>{formatDateYMD(entrytime, true)}</td>
          <td>{entryby}</td>
          <td>{jn} {gd}</td>
          <td>{presetName}</td>
          <td>{tool}</td>
          <td style={{backgroundColor: color, width: 50}}></td>
          <td>{description}</td>
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  return (
    (props.filter.jobNumber !== null && props.filter.jobNumber !== '') || props.component === 'Manage' ?
    <>
      {isModal.edit ?
        <Modal
          add={isModal.edit ? editDrawing : null}
          content={
            <CustomTools
              style={isValidated}
              styleTool={styleTool}
              styleColor={styleColor}
              styleColorOther={styleColorOther}
              styleWidth={styleWidth}
              styleLine={styleLine}
              validate={validate}
              who={'drawings'}
            />
          }
          closeModal={closeModal}
        /> : null
      }
      {!isModal.loading ?

        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: isModal.google || isModal.plan ? '0 1 auto' : '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>

                <Icon name='announcement' color='tomato' onClick={null} />
                <Icon name='refresh' onClick={fetchData} />

                {props.component === 'Manage' ?
                  <>
                    <Icon name='location_on' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
                    <Icon name='map' color={isModal.plan ? 'dodgerblue' : 'gray'} onClick={togglePlan} />
                  </> : null
                }
              </div>

              <SearchBar search={search} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table>

                    <thead>
                      <tr>
                        {isModal.google ? <th></th> : null}
                        <th>Date</th>
                        <th>Tech</th>
                        <th>JN</th>
                        <th>Preset</th>
                        <th>Tool</th>
                        <th>Color</th>
                        <th>Descrip.</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>

                  </table>

                </div> :
                props.filter.jobNumber === null || props.filter.jobNumber === '' ?
                <p style={{margin: 10}}>Select a Job.</p> :
                <p style={{margin: 10}}>No drawings found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

          {isModal.plan ?

            <div style={{margin: 10, flex: '0 1 auto', overflow: 'auto'}}>
              <Plans user={props.user} filter={props.filter} planMode={props.planMode} />
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </> : null
  )

}

export default Drawings
