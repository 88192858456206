import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import PlanDrawings from 'components/main/PlanDrawings'
import ViewLocation from 'components/utils/ViewLocation'
import Job from 'components/menu/Job'
import Alert from 'components/utils/Alert'

import { plansMatrix } from 'scripts/plans'

import { formatDateYMD, formatDateTime, getLocation, catchError, formatBytes } from 'scripts/common'

const Plans = (props) => {

  const fileRef = useRef(null)

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    job: false,
    draw: false,
    index: false,
    loading: true,
    alert: false,
    alertContent: ''
  })

  const [isLoading, setIsLoading] = useState(true)

  const countErrorLoadPDF = useRef(0)

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const canvasPlan = useRef(null)
  const canvasDraw = useRef(null)

  const plansMode = useRef('plansdraw')
  const planIndex = useRef(null)

  const pdf = useRef({
    id: null,
    zoomFactor: 0.5,
    doc: null,
    scale: 1
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    type: '',
    description: '',
    fileName: '',
    fileSize: '',
    sht: '',
    xmax: null,
    ymax: null,
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    n1: null,
    n2: null,
    e1: null,
    e2: null,
    ax1: null,
    ax2: null,
    ay1: null,
    ay2: null,
    angle: null,
    m0: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    jobNumber: '',
    gradeId: '',
    type: '',
    description: '',
    fileName: '',
    fileSize: '',
    sht: '',
    xmax: null,
    ymax: null,
    x1: null,
    x2: null,
    y1: null,
    y2: null,
    n1: null,
    n2: null,
    e1: null,
    e2: null,
    ax1: null,
    ax2: null,
    ay1: null,
    ay2: null,
    angle: null,
    m0: null,
    m1: null,
    m2: null,
    m3: null,
    m4: null,
    m5: null
  })

  const fetchData = () => {

    fetch('/api/selectManagePlans', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)

        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('Error: selectPlans --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {
      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        jobNumber: fetchedData[i].jobnumber,
        gradeId: fetchedData[i].gradeid,
        type: fetchedData[i].type,
        description: fetchedData[i].description,
        fileName: fetchedData[i].fileName,
        fileSize: fetchedData[i].fileSize,
        sht: fetchedData[i].sht,
        xmax: fetchedData[i].xmax,
        ymax: fetchedData[i].ymax,
        x1: fetchedData[i].x1,
        x2: fetchedData[i].x2,
        y1: fetchedData[i].y1,
        y2: fetchedData[i].y2,
        n1: fetchedData[i].n1,
        n2: fetchedData[i].n2,
        e1: fetchedData[i].e1,
        e2: fetchedData[i].e2,
        ax1: fetchedData[i].ax1,
        ax2: fetchedData[i].ax2,
        ay1: fetchedData[i].ay1,
        ay2: fetchedData[i].ay2,
        angle: fetchedData[i].angle,
        m0: fetchedData[i].matrix0,
        m1: fetchedData[i].matrix1,
        m2: fetchedData[i].matrix2,
        m3: fetchedData[i].matrix3,
        m4: fetchedData[i].matrix4,
        m5: fetchedData[i].matrix5
      }))

      if (props.component === 'Manage') {

        openEdit()

      } else {

        openDraw(fetchedData[i].id, fetchedData[i].modtime)

      }

    }

  }

  const addPlans = () => {

    let file = fileRef.current.files[0]
    //console.log(`file ${file.name} size: ${file.size}`)

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
        alert("Please provide a job number.");
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
        alert("Please specify 'Grading' or 'Post'.");
    } else if (isValidated.type === null || isValidated.type ==='') {
        alert("Please provide a type.");
    } else  if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description.");
    } else if (!file) {
      alert("Please add a file")
    } else if (isValidated.n1 === null || isValidated.n1 === '') {
      alert("Please provide a Pt1 Northing.");
    } else if (isValidated.e1 === null || isValidated.e1 === '') {
      alert("Please provide a Pt1 Easting.");
    } else if (isValidated.n2 === null || isValidated.n2 === '') {
      alert("Please provide a Pt2 Northing.");
    } else if (isValidated.e2 === null || isValidated.e2 === '') {
      alert("Please provide a Pt2 Easting.");
    } else {

      getLocation(function(latlng){

        fetch('/api/addPlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            type: isValidated.type,
            description: isValidated.description,
            fileName: file.name,
            fileSize: file.size,
            sht: isValidated.sht,
            xmax: isValidated.xmax,
            ymax: isValidated.ymax,
            x1: isValidated.x1,
            x2: isValidated.x2,
            y1: isValidated.y1,
            y2: isValidated.y2,
            n1: isValidated.n1,
            n2: isValidated.n2,
            e1: isValidated.e1,
            e2: isValidated.e2,
            ax1: isValidated.ax1,
            ax2: isValidated.ax2,
            ay1: isValidated.ay1,
            ay2: isValidated.ay2,
            angle: isValidated.angle,
            m0: isValidated.m0,
            m1: isValidated.m1,
            m2: isValidated.m2,
            m3: isValidated.m3,
            m4: isValidated.m4,
            m5: isValidated.m5
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            if (result.length > 0) {
              uploadPlan(result[0].lastId)
            } else {
              alert('Error: plan id not found. Could not upload PDF.')
              catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'uploadPlan', 'missing lastId', props.user.username, props.user.device)
            }

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add plan. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'addPlan', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const editPlans = () => {

    let file = fileRef.current.files[0]
    //console.log(`file ${file.name} size: ${file.size}`)

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.jobNumber === null || isValidated.jobNumber ==='') {
      alert("Please provide a job number.");
    } else if (isValidated.gradeId === null || isValidated.gradeId ==='') {
      alert("Please specify 'Grading' or 'Post'.");
    } else if (isValidated.type === null || isValidated.type ==='') {
      alert("Please provide a type.");
    } else  if (isValidated.description === null || isValidated.description ==='') {
      alert("Please provide a description.");
    } else if (isValidated.n1 === null || isValidated.n1 === '') {
      alert("Please provide a Pt1 Northing.");
    } else if (isValidated.e1 === null || isValidated.e1 === '') {
      alert("Please provide a Pt1 Easting.");
    } else if (isValidated.n2 === null || isValidated.n2 === '') {
      alert("Please provide a Pt2 Northing.");
    } else if (isValidated.e2 === null || isValidated.e2 === '') {
      alert("Please provide a Pt2 Easting.");
    } else {

      getLocation(function(latlng){

        fetch('/api/editPlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            jobNumber: isValidated.jobNumber,
            gradeId: isValidated.gradeId,
            type: isValidated.type,
            description: isValidated.description,
            fileName: file ? file.name : isValidated.fileName,
            fileSize: file ? file.size : isValidated.fileSize,
            sht: isValidated.sht,
            x1: isValidated.x1,
            x2: isValidated.x2,
            y1: isValidated.y1,
            y2: isValidated.y2,
            n1: isValidated.n1,
            n2: isValidated.n2,
            e1: isValidated.e1,
            e2: isValidated.e2,
            ax1: isValidated.ax1,
            ax2: isValidated.ax2,
            ay1: isValidated.ay1,
            ay2: isValidated.ay2,
            angle: isValidated.angle,
            m0: isValidated.m0,
            m1: isValidated.m1,
            m2: isValidated.m2,
            m3: isValidated.m3,
            m4: isValidated.m4,
            m5: isValidated.m5
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            // if new file, then upload
            if (file) uploadPlan(isValidated.id)

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                jobNumber: isValidated.jobNumber,
                gradeId: isValidated.gradeId,
                type: isValidated.type,
                description: isValidated.description,
                fileName: file ? file.name : isValidated.fileName,
                fileSize: file ? file.size : isValidated.fileSize,
                sht: isValidated.sht,
                xmax: isValidated.xmax,
                ymax: isValidated.ymax,
                x1: isValidated.x1,
                x2: isValidated.x2,
                y1: isValidated.y1,
                y2: isValidated.y2,
                n1: isValidated.n1,
                n2: isValidated.n2,
                e1: isValidated.e1,
                e2: isValidated.e2,
                ax1: isValidated.ax1,
                ax2: isValidated.ax2,
                ay1: isValidated.ay1,
                ay2: isValidated.ay2,
                angle: isValidated.angle,
                m0: isValidated.m0,
                m1: isValidated.m1,
                m2: isValidated.m2,
                m3: isValidated.m3,
                m4: isValidated.m4,
                m5: isValidated.m5
              } :
              data
            ))

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit plan. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'editPlan', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      })

    }

  }

  const deletePlans = () => {

    if (props.user.plan < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deletePlans', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            //fetchData()
            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()
            //alert('Deleted.')

          },
          (error) => {

            alert('Error: could not delete plan. Contact and admin.')
            catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'deletePlan', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      }

    }

  }

  const uploadPlan = (id) => {

    let file = fileRef.current.files[0]
    // console.log(`file ${file.name} size: ${file.size}`)

    let fileData = new FormData()
    fileData.append('filePath', 'plans')
    fileData.append('fileName', `plan${id}.pdf`)
    fileData.append('filePDF', file)

    //console.log('upload it');

    fetch('/api/uploadPlan', {
      method: 'post',
      body: fileData
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

        openDraw(id, null)

      },
      (error) => {

        alert('Error: could not upload Plan. Contact and admin.')
        catchError(isValidated.jobNumber, isValidated.gradeId, props.component, 'uploadPlan', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setIsValidated(prevState => ({...prevState,jobNumber: td[2].textContent}))
    closeJob()

  }

  const changedData = () => isChanged.current = true

  const savedData = () => isChanged.current = false

  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, job: false}))

  const openAlert = (content) => setIsModal(prevState => ({...prevState, alert: true, alertContent: content}))

  const closeAlert = () => setIsModal(prevState => ({...prevState, alert: false}))

  const loadPDF = (url, urlAlt) => { // urlAlt allows reload attempt with actual url and not base64

    //Loads PDF into canvasDraw
    pdfjsLib.getDocument(url).promise.then(function(doc) {

        pdf.current.id = isValidated.id
        pdf.current.doc = doc

        // Initial/first page rendering
        renderPage(doc, pdf.current.scale);

    })
    .then(result => {

      setIsLoading(false)

    })
    .catch(error => {

      catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'loadPDF', JSON.stringify(error), props.user.username, props.user.device)

      if (countErrorLoadPDF.current < 4) {

        openAlert(`Failed to load. Trying again. Attempt ${countErrorLoadPDF.current} of 3`)

        let data = urlAlt === undefined ? url : urlAlt
        loadPDF(data)
        countErrorLoadPDF.current += 1

      } else {
        alert(`Could not load. Try 'Reload Plan' from the left menu.`)
        countErrorLoadPDF.current = 0
      }


    })

  }

  const selectPlanCoords = (x1,y1,x2,y2,n1,e1,n2,e2,ax1,ay1,ax2,ay2,angle) => {

    let m = plansMatrix(x1,y1,x2,y2,n1,e1,n2,e2,angle)

    console.log(`
      x1: ${x1},
      x2: ${x2},
      y1: ${y1},
      y2: ${y2},
      n1: ${n1},
      n2: ${n2},
      e1: ${e1},
      e2: ${e2},
      ax1: ${ax1},
      ax2: ${ax2},
      ay1: ${ay1},
      ay2: ${ay2},
      angle: ${angle},
      m0: ${m.m0},
      m1: ${m.m1},
      m2: ${m.m2},
      m3: ${m.m3},
      m4: ${m.m4},
      m5: ${m.m5}
    `)

    setIsValidated(prevState => ({...prevState,
      x1: x1,
      x2: x2,
      y1: y1,
      y2: y2,
      n1: n1,
      n2: n2,
      e1: e1,
      e2: e2,
      ax1: ax1,
      ax2: ax2,
      ay1: ay1,
      ay2: ay2,
      angle: angle,
      m0: m.m0,
      m1: m.m1,
      m2: m.m2,
      m3: m.m3,
      m4: m.m4,
      m5: m.m5
    }))

  }

  const reloadPDF = () => {

    setIsLoading(true)
    let url = `/plans/plan${isValidated.id}.pdf`
    loadPDF(url)

  }

  const openDraw = (id, modtime) => {

    setIsModal(prevState => ({...prevState, draw: true}))

    let url = '/plans/plan' + id + '.pdf';
    //let url = 'https://raw.githubusercontent.com/mozilla/pdf.js/ba2edeae/examples/learning/helloworld.pdf'
    //let url ='/plans/helloworld.pdf'

    // ######################################################################################################

    // TESTING SAVING PDF AS base64 and saved in indexedDB

    // ######################################################################################################

    let request

    if (window.indexedDB) {

      request = window.indexedDB.open('MyTestDatabase', 1)

      request.onerror = function(event) {
        console.log("Why didn't you allow my web app to use IndexedDB?!");
      };

      // db.onerror = function(event) {
      //   // Generic error handler for all errors targeted at this database's
      //   // requests!
      //   console.error("Database error: " + event.target.errorCode);
      // };

      request.onsuccess = (event) => {
         const db = event.target.result;
         //console.log(`db: ${JSON.stringify(db)}`)
         // insertUser(db, {
         //   email: 'john.doe@outlook.com',
         //   firstName: 'John',
         //   lastName: 'Doe',
         // });
         // insertUser(db, {
         //   email: 'ann.doe@gmail.com',
         //   firstName: 'Ann',
         //   lastName: 'Doe'
         // });
         getUser(db, 1)
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        // Create the UserDetails object store
        // with auto-increment id
        let store = db.createObjectStore('UserDetails', {
           autoIncrement: true
        });

        // Create an index on the NIC property
        let index = store.createIndex('nic', 'nic', {
           unique: true
        });
      };

      function insertUser(db, user) {
        // Create a new transaction
        const txn = db.transaction(['UserDetails'], 'readwrite');

        // Get the UserDetails object store
        const store = txn.objectStore('UserDetails');
        // Insert a new record
        let query = store.put(user);

        // Handle the success case
        query.onsuccess = function (event) {
          console.log(event);
        };

        // Handle the error case
        query.onerror = function (event) {
          console.log(event.target.errorCode);
        }

        // Close the database once the transaction completes
        txn.oncomplete = function () {
          db.close();
        };
      }

      function getUser(db, id) {
        var transaction = db.transaction(["UserDetails"]);
        var objectStore = transaction.objectStore("UserDetails");
        var request = objectStore.get(id);
        request.onerror = function(event) {
          // Handle errors!
        };
        request.onsuccess = function(event) {
          // Do something with the request.result!
          //console.log("email " + JSON.stringify(request.result));
        };
      }

    }

    // ######################################################################################################

    // TESTING SAVING PDF AS base64 and saved in localStorage

    // ######################################################################################################

    // loadPDF move outside function

    // If you are loading file from a remote server, be sure to configure “Access-Control-Allow-Origin”
    // For example, the following image can be loaded from anywhere

    // Initialize the XMLHttpRequest and wait until file is loaded

    const getBase64OfPlan = (url) => {
      return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.onload = function () {

          if (this.status >= 200 && this.status < 300) {

            // Create a Uint8Array from ArrayBuffer
            let bytes = new Uint8Array(xhr.response)

            // Get binary string from UTF-16 code units
            //let binary = String.fromCharCode.apply(null, codes)
            let binary = ''
            for (let i = 0; i < bytes.length; i++) {
              binary += String.fromCharCode(bytes[i])
            }

            // Convert binary to Base64
            let base64 = btoa(binary)
            //console.log(b64)

            let localPlan = {
              id: id,
              modtime: modtime,
              base64: base64
            }

            localStorage.setItem('localPlan', JSON.stringify(localPlan))

            resolve(base64);

          } else {
            reject({
              status: this.status,
              statusText: xhr.statusText
            });
          }

        };
        xhr.onerror = function () {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        };
        // Send HTTP request and fetch file as ArrayBuffer
        xhr.responseType = 'arraybuffer'
        xhr.send()
      });
    }

    const getBase64ofFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.replace(/^.*,/, ''))
        reader.onerror = error => reject(error)
      })
    }

    const cachePlan = (url) => {

      getBase64OfPlan(url)
      .then(result => {
        //console.log(result)

        let data = {data: atob(result)}

        loadPDF(data, url)
      })
      .catch(error => {
        console.error('Error:', error.statusText)

        loadPDF(url)
      })

    }

    let localPlan = JSON.parse(localStorage.getItem('localPlan'))

    if (isModal.add) {

      let file = fileRef.current.files[0]

      if (!file) {
        alert('Please add a file')
      } else {

        getBase64ofFile(file).then(res => {

          let data = {data: atob(res)}

          loadPDF(data)

        })

      }

    } else if (localStorage.getItem('localPlan') === null) {
      //console.log('remotePlan')
      cachePlan(url)

    } else if (localPlan.id === id && localPlan.modtime === modtime) {
      //console.log('localPlan')
      let data = {data: atob(localPlan.base64)}

      loadPDF(data)

    } else {
      //console.log('remotePlan')
      cachePlan(url)

    }

    // ######################################################################################################

    // //Loads PDF into canvasDraw
    // pdfjsLib.getDocument({ data: pdfData, }).promise.then(function(doc) {
    //
    //     pdf.current.id = id
    //     pdf.current.doc = doc
    //
    //     // Initial/first page rendering
    //     renderPage(doc, pdf.current.scale);
    //
    // });

  }

  const openPlan = () => {

    if (isModal.add) {

      let file = fileRef.current.files[0]

      if (!file) {
        alert('Please add a file')
      } else {
        openDraw(isValidated.id, isValidated.modtime)
      }

    } else {
      openDraw(isValidated.id, isValidated.modtime)
    }

  }

  const openAdd = () => {

    if (props.user.plan < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openIndex = () => {

    let index = fetchedData.find(data => data.type === 'Index')

    if (index === undefined) {
      alert('An index map has not been attached. Contact a manager.')
    } else {

      planIndex.current = '/plans/plan' + index.id + '.png'

      setIsModal(prevState => ({...prevState, index: true}))

    }

  }

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const closeDraw = () => setIsModal(prevState => ({...prevState, draw: false}))

  const closeIndex = () => setIsModal(prevState => ({...prevState, index: false}))

  let listOfData = fetchedData.map((data, i) => {

    if (data.type === 'Plan') {

      let jn = data.jobnumber === null ? '' : data.jobnumber
      let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
      let sht = data.sht === null ? '' : data.sht
      let description = data.description === null ? '' : data.description
      let fileName = data.fileName === null ? '' : data.fileName
      let fileSize = data.fileSize === null ? '' : formatBytes(Number(data.fileSize))
      let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
      let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime)
      let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
      let modTime = data.modtime === null ? '' : formatDateTime(data.modtime)

      let offline = false
      if (localStorage.getItem('localPlan') !== null) {

        let localPlan = JSON.parse(localStorage.getItem('localPlan'))
        offline = localPlan.id === data.id ? true : false

      }

      if (
        searchValue === '' ||
        jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        sht.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        fileName.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        modBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        modTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      ) {
        return (
          <tr key={data.id.toString()} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
              <>
                <td>{jn}</td>
                <td>{gd}</td>
              </> : null
            }
            <td>{sht}</td>
            <td>{description}</td>
            <td>{fileName}</td>
            <td>{fileSize}</td>
            <td>{entryBy}</td>
            <td>{entryTime}</td>
            <td>{modBy}</td>
            <td>{modTime}</td>
            <td>{offline ? <Icon name='offline_pin' /> : ''}</td>
          </tr>
        )
      }

    }

  })

  //========================================
  //==  Mapping
  //========================================

  // The link below explains how to use npm
  // https://github.com/mozilla/pdf.js/wiki/Setup-pdf.js-in-a-website

  // If absolute URL from the remote server is provided, configure the CORS
  // header on that server.

  // Loaded via <script> tag, create shortcut to access pdf.current.js exports.
  const pdfjsLib = window['pdfjs-dist/build/pdf'];
  //const pdfjsLib = require('pdfjs-dist/build/pdf.js')
  //const pdfjsLib = require('pdfjs-dist')

  // The workerSrc property shall be specified.
  //pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
  pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdn.jsdelivr.net/npm/pdfjs-dist@2.7.570/build/pdf.worker.js';
  //pdfjsLib.GlobalWorkerOptions.workerSrc = './node_modules/pdfjs-dist/build/pdf.worker.entry.js'

  const renderPage = (doc, scale) => {

    doc.getPage(1).then(function(page) {

      let viewport = page.getViewport({scale: scale})

      canvasDraw.current.height = viewport.height
      canvasDraw.current.width = viewport.width

      canvasPlan.current.height = viewport.height
      canvasPlan.current.width = viewport.width

      // Render PDF page into canvas context
      let renderContext = {

          canvasContext: canvasPlan.current.getContext('2d'),
          viewport: viewport

      };

      page.render(renderContext)

    })
    //.then(res => setIsLoading(false))
    .then(
      (result) => {

        setIsLoading(false)

      },
      (error) => {

        alert('Error: could not load document page. Contact and admin.')

        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'renderPage', JSON.stringify(error), props.user.username, props.user.device)

      }
    )

  }

  const zoomIn = () => {

    let scale = pdf.current.scale + pdf.current.zoomFactor;

    pdf.current.scale = scale

    setIsLoading(true)

    renderPage(pdf.current.doc, scale);

  }

  const zoomOut = () => {

    if (pdf.current.scale <= pdf.current.zoomFactor) { // zoomFactor currently set to 0.5 in vars above per Daves request

      alert('Maximum zoom out reached.');

    } else {

      let scale = pdf.current.scale - pdf.current.zoomFactor;

      pdf.current.scale = scale

      setIsLoading(true)

      renderPage(pdf.current.doc, scale);

    }

  }

  let modalContent = (
    <div>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

        <div style={{display: 'inline-block', textAlign: 'right'}}>

          <div>
            <label className='label'>JN</label>
            <input style={{width: 75}} className='input' type="text" value={isValidated.jobNumber} onClick={openJob} />
          </div>

          <div>
            <label className='label'>Grading</label>
            <select style={{width: 75}} className='select' pattern=".{1,}" name='gradeId' onInput={validate} onChange={changedData} defaultValue={isValidated.gradeId} required>
              <option value=''></option>
              <option value='RG'>Grading</option>
              <option value='PG'>Post</option>
            </select>
          </div>

          <div>
            <label className='label'>Type</label>
            <select style={{width: 75}} className='select' pattern=".{1,}" name='type' onInput={validate} onChange={changedData} defaultValue={isValidated.type} required>
              <option value=''></option>
              <option value='Index'>Index</option>
              <option value='Plan'>Plan</option>
            </select>
          </div>

          <div>
            <label className='label'>Sht</label>
            <input style={{width: 75}} className='input' type="text" pattern="[0-9]{1,}" name='sht' onInput={validate} defaultValue={isValidated.sht} required />
          </div>

        </div>

        <div>
          <label className='label'>Description</label><br/>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} defaultValue={isValidated.description} required></textarea>
        </div>

        {isModal.edit ?
          <>
            <p>{isValidated.fileName}</p>
            <div style={{margin: 10}}><input type="file" ref={fileRef} accept="application/pdf" /></div>
            <div><small>*The old app did not save file names.</small></div>
          </> :
          <>
            <div style={{margin: 10}}><input type="file" ref={fileRef} accept="application/pdf" /></div>
            <div><small>*The old app did not save file names.</small></div>
          </>
        }

        <CircleButton iconName='map' onClick={openPlan} />

      </div>

    </div>
  )

  // Plansdrawings

  // user={props.user} --> tracks who is drawing

  // selectLocate={props.selectLocate} --> handles passing N,E back to Tests
  // data={props.data} --> passes pre, testNo, suf, and elevD from Tests to PlanDrawings
  // closePlan={props.closePlan} --> closes plan modal if from Tests and test location accepted

  let content = (

    <div>
      {!isModal.loading ?
        <div>

          <div>
            {props.component === 'Manage' ? props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} /> : null}
            <Icon name='local_library' onClick={openIndex} />
            <Icon name='refresh' onClick={fetchData} />
          </div>

          <SearchBar search={search} clearSearch={clearSearch} />

          {fetchedData.length > 0 ?

            <>

              <p style={{margin: 10}}>{fetchedData.length} Total Records. (If no filters, limit 500)</p>

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <><th>JN</th><th></th></> : null}
                      <th>Sht</th>
                      <th>Descrip.</th>
                      <th>File Name</th>
                      <th>File Size</th>
                      <th>Entry by</th>
                      <th>Entry time</th>
                      <th>Mod by</th>
                      <th>Mod time</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

              </div>

            </> :
            <p style={{margin: 10}}>No plans found.</p>

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addPlans : isModal.edit ? editPlans : null} delete={isModal.edit ? deletePlans : null} content={modalContent} closeModal={closeModal} /> : null}
      {isModal.index ? <Modal content={<img style={{height: '100%', width: '100%'}} src={planIndex.current} />} closeModal={closeIndex} /> : null}
      {isModal.job ? <Job user={props.user} selectJob={selectJob} closeModal={closeJob} /> : null}
      {isModal.draw ?
        <PlanDrawings
          user={props.user}
          closeModal={closeDraw}
          changedData={changedData}
          savedData={savedData}
          canvasPlan={canvasPlan}
          canvasDraw={canvasDraw}
          mode={props.planMode}
          planInfo={isValidated}
          pdf={pdf.current}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          filter={props.filter}
          selectLocate={props.selectLocate}
          selectPlanCoords={selectPlanCoords}
          reloadPDF={reloadPDF}
          data={props.data} // passes data from lab and test for display
          closePlan={props.closePlan} // used to close plan if opened from lab, tests, etc
          isLoading={isLoading}
        /> : null
      }
      {isModal.alert ? <Alert content={isModal.alertContent} close={closeAlert} /> : null}
    </div>

  )

  return props.modal ? <Modal content={content} closeModal={props.closePlan} /> : content // this allows plans to be used as a Modal inside Test, Lab, etc

}

export default Plans
