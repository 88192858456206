import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Prefix from 'components/menu/Prefix'
import Curve from 'components/menu/Curve'
import Plans from 'components/main/Plans'

import { formatDateYMD, formatDateTime, getLocation, catchError } from 'scripts/common'

import { addPending, selectData } from 'scripts/offline'

const Tests = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    curve: false,
    prefix: false,
    plan: false,
    google: false,
    locate: false,
    warning: 0,
    warningContent: '',
    loading: true
  })

  const isChanged = useRef(false)

  const [isRetest, setIsRetest] = useState(false)
  const [isFailingTest, setIsFailingTest] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  // const [isGoogleMap, setIsGoogleMap] = useState(false)
  // const [isPlan, setIsPlan] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  // used to update passFail in Modal
  const updatePassFail = useRef({
    dryDens: null,
    optD: null,
    reqComp: null
  })

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    reTest: false, // tracks if a test is eleigible for a reTest
    reTestActive: false, // tracks if a reTest was taken. This way it inserts records and does not update existing
    showRemoved: true,
    removed: null,
    nukeId: '',
    testDate: '',
    pre: '',
    testNo: null,
    suf: '',
    testType: '',
    north: null,
    east: null,
    elevD: null,
    moistCont: null,
    dryDens: null,
    maxId: null,
    curve: '',
    optD: null,
    optM: null,
    reqComp: null,
    relComp: null,
    passFail: ''
  })

  const fetchData = () => {

    if (props.user.offline === props.filter.jobNumber) {

      selectData('Tests').then(res => {
        setFetchedData(res.sort((a, b) => Number(b.testno) - Number(a.testno)))
        setIsModal(prevState => ({...prevState, loading: false}))
      })

    } else {

      fetch('/api/selectTests', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          filter: props.filter
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)

          setFetchedData(result.map((data, i) => {

            let relComp = Math.round((data.drydens/data.optd)*100)

            let passFail = relComp >= data.reqcomp ? 'P' : 'F'

            return {...data,
              relcomp: relComp,
              passfail: passFail,
              entrytime: formatDateTime(data.entrytime),
              modtime: formatDateTime(data.modtime),
              testdate: formatDateYMD(data.testdate)
            }

          }))

          setIsModal(prevState => ({...prevState, loading: false}))

        },
        (error) => {
          catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectTests', JSON.stringify(error), props.user.username, props.user.device)

        }
      )

    }

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    if (
      (props.filter.jobNumber !== '' || props.filter.user !== '' || props.filter.startDate !== '') &&
      (props.filter.jobNumber == '' || props.filter.dateName !== 'All')
    ) fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.entrylat !== '' && data.entrylat !== null && data.entrylng !== '' && data.entrylng !== null) {

        let pre = data.pre === null ? '' : data.pre
        let number = data.testno === null ? '' : data.testno
        let suf = data.suf === null ? '' : data.suf
        let testNo = `${pre} ${number} ${suf}`
        let curve = data.curve === null ? '' : data.curve
        let elevD = data.elevd === null ? '' : data.elevd
        let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
        let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''
        let entryTime = data.entrytime === null ? '' : data.entrytime

        return true

        //{testNo: testNo, lat: data.entrylat, lng: data.entrylng}

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, pre, number, suf, testNo

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      pre = data[i].pre === null ? '' : data[i].pre
      number = data[i].testno === null ? '' : data[i].testno
      suf = data[i].suf === null ? '' : data[i].suf
      testNo = `${pre} ${number} ${suf}`

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: testNo,
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i])
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'yellow'

    if (name === 'dryDens' || name === 'optD' || name === 'reqComp') updatePassFail.current.[name] = value

    // update in case
    let relComp = Math.round((Number(updatePassFail.current.dryDens)/Number(updatePassFail.current.optD))*100)
    let passFail = relComp >= updatePassFail.current.reqComp ? 'P' : 'F'

    // console.log(
    //   `
    //   dryDens: ${updatePassFail.current.dryDens}
    //   optD: ${updatePassFail.current.optD}
    //   reqComp: ${updatePassFail.current.reqComp}
    //   relComp: ${relComp},
    //   passFail: ${passFail},
    //   `
    // )

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null, relComp: relComp, passFail: passFail}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      // check for warning => 0: can edit/delete, 1: can edit, 2: cannot edit/delete
      let today = new Date()
      let entry = new Date(fetchedData[i].entrytime)
      let timeDiff = Math.abs(entry.getTime() - today.getTime())
      let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) // 1 day

      if (props.user.test < 2) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: 'Cannot Edit/Delete: You do not have required permission. Contact an Admin.'}))

      } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username !== fetchedData[i].entryby) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: ${fetchedData[i].entryby} is the owner.`}))

      } else if ((props.user.test === 2 || props.user.test === 3) && props.user.username === fetchedData[i].entryby && diffDays > 1) {

        setIsModal(prevState => ({...prevState, warning: 2, warningContent: `Cannot Edit/Delete: Time has elasped (24 hrs). Contact a Manager`}))

      } else if (props.user.test === 4) {

        setIsModal(prevState => ({...prevState, warning: 1, warningContent: `Cannot Delete: You do not have required permission. Contact an Admin.`}))

      } else {

        setIsModal(prevState => ({...prevState, warning: 0, warningContent: ``}))

      }

      // this is for removed, if returns undefined, then not removed
      let removed =  fetchedData.find(test => test.testno === fetchedData[i].testno && test.suf === 'R')
      let reTest = false

      if (!removed && fetchedData[i].passfail === 'F') {

        // this is for retest, if returns undefined, then not found, so retest needed
        reTest = fetchedData.find(test => test.testno === fetchedData[i].testno && test.passfail === 'P')
        //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)

      }

      // used to dynamically update relComp and passFail during input
      updatePassFail.current = {
        dryDens: fetchedData[i].drydens,
        optD: fetchedData[i].optd,
        reqComp: fetchedData[i].reqcomp
      }

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        reTest: reTest === undefined ? true : false,
        showRemoved: removed === undefined ? true : false,
        removed: removed === undefined ? false : true,
        nukeId: fetchedData[i].nukeId,
        testDate: fetchedData[i].testdate,
        pre: fetchedData[i].pre,
        testNo: fetchedData[i].testno,
        suf: fetchedData[i].suf,
        testType: fetchedData[i].testtype,
        north: fetchedData[i].north,
        east: fetchedData[i].east,
        elevD: fetchedData[i].elevd,
        moistCont: fetchedData[i].moistcont,
        dryDens: fetchedData[i].drydens,
        maxId: fetchedData[i].maxId,
        curve: fetchedData[i].curve,
        optD: fetchedData[i].optd,
        optM: fetchedData[i].optm,
        reqComp: fetchedData[i].reqcomp,
        relComp: fetchedData[i].relcomp,
        passFail: fetchedData[i].passfail
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const selectPrefix = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (isValidated.pre !== td[0].textContent) {

      setIsValidated(prevState => ({...prevState, pre: td[0].textContent}))
      changedData()

    }

    closePrefix()

  }

  const selectCurve = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    let maxId = Number(td[0].textContent)
    let optD = Number(td[3].textContent)
    let optM = Number(td[4].textContent)

    updatePassFail.current.optD = optD

    if (isValidated.maxId !== maxId) {

      setIsValidated(prevState => ({...prevState,
        maxId: maxId,
        curve: td[2].textContent,
        optD: optD,
        optM: optM
      }))
      changedData()

    }

    closeCurve()

  }

  const selectLocate = (n,e) => {
    isChanged.current = true
    setIsValidated(prevState => ({...prevState,
      north: n,
      east: e
    }))
  }

  const addTest = () => {

    if (props.user.test < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (isValidated.testType === 'N' && (props.user.nukeId === '' || props.user.nukeId === null)) {
      alert('Please select a Nuke from the Home page.')
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.");
    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    } else if (isValidated.curve === null || isValidated.curve ==='') {
        alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
        alert("Relative compaction cannot be greater than 100%.");
    } else {

      // include optd and optm???

      if (props.filter.offline) {

        addPending({
          actionId: 1,
          action: 'addTest',
          table: 'Tests',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: Math.max(...fetchedData.map(o => o.id)) + 1,
          nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
          testdate: isValidated.testDate,
          pre: isValidated.pre,
          testno: isValidated.testNo,
          suf: isValidated.suf,
          testtype: isValidated.testType,
          north: isValidated.north,
          east: isValidated.east,
          elevd: isValidated.elevD,
          moistcont: isValidated.moistCont,
          drydens: isValidated.dryDens,
          maxId: isValidated.maxId,
          curve: isValidated.curve,
          optd: isValidated.optD,
          optm: isValidated.optM,
          reqcomp: isValidated.reqComp,
          relcomp: isValidated.relComp,
          passfail: isValidated.passFail,
          removed: isValidated.removed,
          showRemoved: isValidated.showRemoved
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not save. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        getLocation(function(latlng){

          fetch('/api/addTest', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              device: props.user.device,
              jobNumber: props.filter.jobNumber,
              gradeId: props.filter.gradeId,
              nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
              testDate: isValidated.testDate,
              pre: isValidated.pre,
              testNo: isValidated.testNo,
              suf: isValidated.suf,
              testType: isValidated.testType,
              north: isValidated.north,
              east: isValidated.east,
              elevD: isValidated.elevD,
              moistCont: isValidated.moistCont,
              dryDens: isValidated.dryDens,
              maxId: isValidated.maxId,
              curve: isValidated.curve,
              optD: isValidated.optD,
              optM: isValidated.optM,
              reqComp: isValidated.reqComp,
              relComp: isValidated.relComp,
              passFail: isValidated.passFail,
              removed: isValidated.removed,
              showRemoved: isValidated.showRemoved
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              fetchData() // i need the id if edited
              isChanged.current = false
              closeModal()

            },
            (error) => {

              alert('Error: could not add test. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'addTest', JSON.stringify(error), props.user.username, props.user.device)

            }
          )

        })

      }

    }

  }

  const editTest = () => {

    // if (props.user.test < 2) {
    //   alert('You do not have the required permission. Contact an admin.')
    if (isModal.warning > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.testDate === null || isValidated.testDate ==='') {
        alert("Please select a Test Date.");
    } else if ((isValidated.pre === null && props.filter.gradeId ==='PG') || (isValidated.pre ==='' && props.filter.gradeId ==='PG')) {
         alert("Please select a Prefix.");
    } else if (isValidated.testType === null || isValidated.testType ==='') {
        alert("Please select a Test Type.");
    } else if (isValidated.north === null || isValidated.north ==='') {
        alert("Please select a North.");
    } else  if (isValidated.east === null || isValidated.east ==='') {
        alert("Please select an East.");
    } else if (isValidated.elevD === null || isValidated.elevD ==='') {
        alert("Please select an Elev/Depth.");
    } else if (isValidated.pre !== 'AC' && (isValidated.moistCont === null || isValidated.moistCont ==='')) {
        alert("Please select a Moisture Content.");
    } else if (isValidated.dryDens === null || isValidated.dryDens ==='') {
        alert("Please select a Dry Density.");
    } else if (isValidated.curve === null || isValidated.curve ==='') {
        alert("Please select a curve.");
    // } else if (isValidated.relComp === null || isValidated.relComp ==='') {
    //     alert("Please select a Relative Compaction.");
    } else if (isValidated.reqComp === null || isValidated.reqComp ==='') {
        alert("Please select a Required Compaction.");
    } else if (Math.round((isValidated.dryDens/isValidated.optD)*100 >= 100)) {
        alert("Relative compaction cannot be greater than 100%.");
    } else {

      if (props.filter.offline) {

        addPending({
          actionId: 2,
          action: 'editTest',
          table: 'Tests',
          jobnumber: props.filter.jobNumber,
          gradeid: props.filter.gradeId,
          entryby: props.user.username,
          entrytime: formatDateTime(new Date()),
          entrylat: '',
          entrylng: '',
          entrydevice: props.user.device,
          modby: '',
          modtime: null,
          modlat: '',
          modlng: '',
          moddevice: '',
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: '',
          lng: '',
          device: props.user.device,
          id: isValidated.id,
          nukeId: isValidated.testType === 'N' ? props.user.nukeId : '',
          testdate: isValidated.testDate,
          pre: isValidated.pre,
          testno: isValidated.testNo,
          suf: isValidated.suf,
          testtype: isValidated.testType,
          north: isValidated.north,
          east: isValidated.east,
          elevd: isValidated.elevD,
          moistcont: isValidated.moistCont,
          drydens: isValidated.dryDens,
          maxId: isValidated.maxId,
          curve: isValidated.curve,
          optd: isValidated.optD,
          optm: isValidated.optM,
          reqcomp: isValidated.reqComp,
          relcomp: isValidated.relComp,
          passfail: isValidated.passFail,
          removed: isValidated.removed,
          showRemoved: isValidated.showRemoved
        })
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: '',
                modlng: '',
                moddevice: props.user.device,
                testdate: isValidated.testDate,
                testtype: isValidated.testType,
                pre: isValidated.pre,
                testno: isValidated.testNo,
                suf: isValidated.suf,
                testtype: isValidated.testType,
                north: isValidated.north,
                east: isValidated.east,
                elevd: isValidated.elevD,
                moistcont: isValidated.moistCont,
                drydens: isValidated.dryDens,
                maxId: isValidated.maxId,
                curve: isValidated.curve,
                optd: isValidated.optD,
                optm: isValidated.optM,
                reqcomp: isValidated.reqComp,
                relcomp: isValidated.relComp,
                passfail: isValidated.passFail,
                removed: isValidated.removed
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not save. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTestOffline', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      } else {

        getLocation(function(latlng){

          fetch('/api/editTest', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              by: props.user.username,
              time: formatDateTime(new Date()),
              lat: latlng.lat,
              lng: latlng.lng,
              id: isValidated.id,
              device: props.user.device,
              testDate: isValidated.testDate,
              pre: isValidated.pre,
              testNo: isValidated.testNo,
              suf: isValidated.suf,
              testType: isValidated.testType,
              north: isValidated.north,
              east: isValidated.east,
              elevD: isValidated.elevD,
              moistCont: isValidated.moistCont,
              dryDens: isValidated.dryDens,
              maxId: isValidated.maxId,
              curve: isValidated.curve,
              optD: isValidated.optD,
              optM: isValidated.optM,
              reqComp: isValidated.reqComp,
              relComp: isValidated.relComp,
              passFail: isValidated.passFail,
              removed: isValidated.removed
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              setFetchedData(fetchedData.map(data =>
                data.id === isValidated.id ?
                {...data,
                  modby: props.user.username,
                  modtime: formatDateTime(new Date()),
                  modlat: latlng.lat,
                  modlng: latlng.lng,
                  moddevice: props.user.device,
                  testdate: isValidated.testDate,
                  testtype: isValidated.testType,
                  pre: isValidated.pre,
                  testno: isValidated.testNo,
                  suf: isValidated.suf,
                  testtype: isValidated.testType,
                  north: isValidated.north,
                  east: isValidated.east,
                  elevd: isValidated.elevD,
                  moistcont: isValidated.moistCont,
                  drydens: isValidated.dryDens,
                  maxId: isValidated.maxId,
                  curve: isValidated.curve,
                  optd: isValidated.optD,
                  optm: isValidated.optM,
                  reqcomp: isValidated.reqComp,
                  relcomp: isValidated.relComp,
                  passfail: isValidated.passFail,
                  removed: isValidated.removed
                } :
                data
              ))

              isChanged.current = false
              closeModal()
              //alert('Updated')

            },
            (error) => {

              alert('Error: could not edit test. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'editTest', JSON.stringify(error), props.user.username, props.user.device)

            }
          )

        })

      }

    }

  }

  const deleteTest = () => {

    if (isModal.warning > 0) {
        alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        if (props.filter.offline) {

          addPending({
            actionId: 3,
            action: 'deleteTest',
            table: 'Tests',
            id: isValidated.id
          })
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              closeModal()
              //alert('Deleted.')

            },
            (error) => {

              alert('Error: could not delete Test. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTestOffline', JSON.stringify(error), props.user.username, props.user.device)
            }
          )

        } else {

          fetch('/api/deleteTest', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: isValidated.id
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + JSON.stringify(result))

              //fetchData()
              setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
              isChanged.current = false
              closeModal()
              //alert('Deleted.')

            },
            (error) => {

              alert('Error: could not delete test. Contact and admin.')
              catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'deleteTest', JSON.stringify(error), props.user.username, props.user.device)

            }
          )

        }

      }

    }

  }

  const removeTest = () => {
    if (window.confirm('You want to remove me ಠ_ಠ ?')) addTest()
  }

  const reTest = () => {


    //console.log(`fetchedData: ${JSON.stringify(fetchedData)}`)
    //console.log(`isValidated: ${JSON.stringify(isValidated)}`)
    let suf
    let sufArr = fetchedData.map(data => data.testno === isValidated.testNo && (data.suf !== '' && data.suf !== null) ? data.suf.charCodeAt() : 0)
    //console.log(`sufArr: ${sufArr}`)
    let newSuf = Math.max(...sufArr)
    //console.log(`newSuf: ${newSuf}`)

    if (newSuf === 0) {
      suf ='A'
    } else {
      suf = String.fromCharCode(newSuf.charCodeAt() + 1)
    }

    setIsValidated(prevState => (
      {...prevState,
        suf: suf,
        moistCont: null,
        dryDens: null,
        testDate: '',
        reTest: false,
        reTestActive: true
      }
    ))

    alert('Suffix ' + suf + ' created. Please provide a moisture, dry density and test date.');

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const viewFailingTest = () => setIsFailingTest(isFailingTest ? false : true)

  const viewRemoved = () => setIsRemoved(isRemoved ? false : true)

  const openAdd = () => {

    if (props.user.test < 2) {

      alert('You do not have the required permission. Contact an admin.')

    // if (isModal.warning) {
    //
    //   alert('You do not have the required permission. Contact an admin.')

    } else if (props.filter.jobNumber === '') {

      alert('Please select a JN.')

    } else {

      let testNo = fetchedData.length < 1 ? 1 : Math.max(...fetchedData.map(o => o.testno)) + 1

      setIsValidated(prevState => ({...prevState, testNo: testNo}))
      setIsModal(prevState => ({...prevState, add: true}))

      // fetch('/api/selectNextTestNo', {
      //   method: 'post',
      //   headers: {
      //     'Accept': 'application/json, text/plain, */*',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     jobNumber: props.filter.jobNumber,
      //     gradeId: props.filter.gradeId
      //   })
      // })
      // .then(res=>res.json())
      // .then(
      //   (result) => {
      //     //console.log('result: ' + result)
      //
      //     setIsValidated(prevState => ({...prevState, testNo: result[0].testNo === null ? 1 : result[0].testNo + 1}))
      //     setIsModal(prevState => ({...prevState, add: true}))
      //
      //   },
      //   (error) => {
      //
      //     catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectNextTestNo', JSON.stringify(error), props.user.username, props.user.device)
      //
      //   }
      // )

    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openPrefix = () => setIsModal(prevState => ({...prevState, prefix: true}))

  const openCurve = () => setIsModal(prevState => ({...prevState, curve: true}))

  const openLocate = () => setIsModal(prevState => ({...prevState, locate: true}))

  const togglePlan = () => setIsModal(prevState => ({...prevState, plan: isModal.plan ? false : true}))

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const closePrefix = () => setIsModal(prevState => ({...prevState, prefix: false}))

  const closeCurve = () => setIsModal(prevState => ({...prevState, curve: false}))

  const closeLocate = () => setIsModal(prevState => ({...prevState, locate: false}))

  const filterData = (data) => {

    let pre = data.pre === null ? '' : data.pre
    let number = data.testno === null ? '' : data.testno
    let suf = data.suf === null ? '' : data.suf
    let testNo = `${pre} ${number} ${suf}`
    let curve = data.curve === null ? '' : data.curve
    let elevD = data.elevd === null ? '' : data.elevd
    let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''

    // this is for failingTest
    let failingTest = null

    if (data.passfail !== 'P') {
      //console.log(`data.passfail: ${data.passfail}`)
      failingTest = fetchedData.find(retest => retest.testno === data.testno && retest.passfail === 'P')
      //if (failingTest === undefined) console.warn(`listOfTests-->failingTest-->undefined.`)
    }

    if (

      ((isRemoved && suf === 'R') || !isRemoved) &&
      ((isFailingTest && failingTest === undefined) || !isFailingTest) &&
      (searchValue === '' ||
      testNo.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      curve.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      elevD.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      passFail.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {

      return true

    } else {

      return false

    }

  }

  let listOfTests = fetchedData.map((data, i) => {

    let pre = data.pre === null ? '' : data.pre
    let number = data.testno === null ? '' : data.testno
    let suf = data.suf === null ? '' : data.suf
    let testNo = `${pre} ${number} ${suf}`
    let dryDens = data.drydens === null ? '' : data.drydens
    let moistCont = data.moistcont === null ? '' : data.moistcont
    let md = `${dryDens} @ ${moistCont}`
    let optD = data.optd === null ? '' : data.optd
    let optM = data.optm === null ? '' : data.optm
    let curve = data.curve === null ? '' : data.curve
    let max = `${curve} - ${optD} @ ${optM}`
    let reqComp = data.reqcomp === null ? '' : data.reqcomp
    let elevD = data.elevd === null ? '' : data.elevd
    let passFail = data.passfail === null ? '' : data.passfail === 'P' ? 'pass' : data.passfail === 'F' ? 'fail' : '?'
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : data.tech !== null ? data.tech : ''

    let filter = filterData(data)
    //console.log(`filter ${filter}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={selectRow} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.entrylat === '' || data.entrylat === null || data.entrylng === '' || data.entrylng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>{data.testdate}</td>
          <td>{entryBy}</td>
          <td>{testNo.trim()}</td>
          <td>{elevD}</td>
          <td>{md.trim()}</td>
          <td>{max.trim()}</td>
          <td>{reqComp}</td>
          <td>{passFail}</td>
        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  let modalContent = (
    <div key={isValidated.reTestActive} style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div style={{display: 'inline-block', textAlign: 'right', margin: 10}}>

        {isModal.edit && isValidated.showRemoved ?

          <>
            <label>Removed?
              <select style={{width: 75}} className='select' pattern=".{1,}" name='removed' onInput={validate} onChange={changedData} required>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label><br />
          </> : isModal.edit && !isValidated.showRemoved ? <p>I have been Removed :(</p> : null

        }

        {isValidated.testType === 'N' ?

          <div>
            <label>Nuke ID</label>
            <input style={{width: 75}} className='input' type="text" value={isModal.add ? props.user.nukeId : isValidated.nukeId} disabled />
          </div> : null

        }

        <label>Test Date
          <input className='input' type="date" pattern=".{1,}" name='testDate' onInput={validate} onChange={changedData} defaultValue={isValidated.testDate} required />
        </label><br />

        <label>Prefix
          <input style={{width: 75}} className='input' type="text" value={isValidated.pre} onClick={openPrefix} />
        </label><br />

        <label>Test No
          <input style={{width: 75}} className='input' type="text" value={isValidated.testNo} disabled />
        </label><br />

        <label>Suffix
          <input style={{width: 75}} className='input' type="text" value={isValidated.suf} disabled />
        </label><br />

        <label>Test Type
          <select style={{width: 75}} className='select' pattern=".{1,}" name='testType' onInput={validate} onChange={changedData} defaultValue={isValidated.testType} required>
            <option value=""></option>
            <option value="N">N</option>
            <option value="S">S</option>
            <option value="D">D</option>
          </select>
        </label><br />

        <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

          <CircleButton iconName='add_location' onClick={openLocate} /><br />

          {isValidated.north !== null && isValidated.north !== '' ? <p>N: {isValidated.north}</p> : null}
          {isValidated.east !== null && isValidated.east !== '' ? <p>E: {isValidated.east}</p> : null}

        </div>

        <label>Elev/ Depth (ft)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elevD' onInput={validate} onChange={changedData} defaultValue={isValidated.elevD} required />
        </label><br />

        {isValidated.pre !== 'AC' ?

          <div>
            <label>Moisture Content (%)</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='moistCont' onInput={validate} onChange={changedData} defaultValue={isValidated.moistCont} required />
          </div> :
          <p>Asphalt doesnt need a Moisture</p>

        }

        <div>
          <label>Dry Density (pcf)</label>
          <input style={{width: 75}} className='input' type="text" pattern="\d{2,3}(\.\d{1})?" name='dryDens' onInput={validate} onChange={changedData} defaultValue={isValidated.dryDens} required />
        </div>

        <label>Curve
          <input style={{width: 75}} className='input' type="text" value={isValidated.curve} onClick={openCurve} />
        </label><br />

        <label>Req Comp (%)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='reqComp' onInput={validate} onChange={changedData} defaultValue={isValidated.reqComp} required />
        </label><br />

        <label>Rel Comp (%)
          <input style={{width: 75}} className='input' type="text" value={isValidated.relComp} disabled />
        </label><br />

        <label>Pass/Fail
          <input style={{width: 75}} className='input' type="text" value={isValidated.passFail} disabled />
        </label>

      </div>

    </div>
  )

  // user={props.user} <-- this once in plans below...

  return (
    props.filter.jobNumber === '' && props.filter.user === '' && props.filter.startDate === '' && props.component === 'Manage' ?
      <p style={{margin: 10}}>Please select a filter</p> :
    (props.filter.jobNumber == '' || props.filter.dateName !== 'All') && props.component === 'Manage' ?
      <p style={{margin: 10}}>Please select a job or user if 'All'</p> :
    (props.filter.jobNumber !== null && props.filter.jobNumber !== '') || props.component === 'Manage' ?
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add || isValidated.reTestActive ? addTest : (isModal.edit && isValidated.removed && isValidated.showRemoved) ? removeTest : isModal.edit ? editTest : null} reTest={isValidated.reTest ? reTest : null} delete={isModal.edit ? deleteTest : null} content={modalContent} closeModal={closeModal} isValidated={isValidated} isModal={isModal} /> : null}
      {isModal.curve ? <Curve user={props.user} filter={props.filter} selectCurve={selectCurve} closeModal={closeCurve} /> : null}
      {isModal.prefix ? <Prefix user={props.user} filter={props.filter} selectPrefix={selectPrefix} closeModal={closePrefix} /> : null}
      {isModal.locate ? <Plans user={props.user} filter={props.filter} selectLocate={selectLocate} data={isValidated} closePlan={closeLocate} planMode={props.planMode} modal={true} /> : null}
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: isModal.google || isModal.plan ? '0 1 auto' : '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>

                {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
                <Icon name='warning' color={isFailingTest ? 'tomato' : 'gray'} onClick={viewFailingTest} />
                <Icon name='delete' color={isRemoved ? 'tomato' : 'gray'} onClick={viewRemoved} />
                <Icon name='refresh' onClick={fetchData} />

                {props.component === 'Manage' ?
                  <>
                    <Icon name='location_on' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
                    <Icon name='map' color={isModal.plan ? 'dodgerblue' : 'gray'} onClick={togglePlan} />
                  </> : null
                }
              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table>

                    <thead>
                      <tr>
                        {isModal.google ? <th></th> : null}
                        {props.filter.jobNumber === '' && props.component === 'Manage' ? <th>JN</th> : null}
                        <th>Date</th>
                        <th>Tech</th>
                        <th>Test</th>
                        <th>E/D</th>
                        <th>D/M</th>
                        <th>Max</th>
                        <th>Req.</th>
                        <th>P/F</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfTests}
                    </tbody>

                  </table>

                </div> :
                <p style={{margin: 10}}>No tests found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

          {isModal.plan ?

            <div style={{margin: 10, flex: '0 1 auto', overflow: 'auto'}}>
              <Plans user={props.user} filter={props.filter} planMode={props.planMode} />
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </> : null
  )

}

export default Tests
