import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import ViewLocation from 'components/utils/ViewLocation'

import { formatDateYMD, formatDateTime, getLocation, replaceStr } from 'scripts/common'

const PresetTypes = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: ''
  })

  const fetchData = () => {

    fetch('/api/selectPresetTypes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectPresetTypes --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        description: fetchedData[i].description
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addPresetType = () => {

    if (props.user.presetType < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description")
    } else {

      getLocation(function(latlng){

        fetch('/api/addPresetType', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Preset Type. Contact and admin.')
            console.log('Error: addPresetType --> ' + error)
          }
        )

      })

    }

  }

  const editPresetType = () => {

    if (props.user.presetType < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description")
    } else {

      getLocation(function(latlng){

        fetch('/api/editPresetType', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            id: isValidated.id,
            device: props.user.device,
            id: isValidated.id,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                description: isValidated.description
              } :
              data
            ))

            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Preset Type. Contact and admin.')
            console.log('Error: editPresetType --> ' + error)
          }
        )

      })

    }

  }

  // DO NOT DELETE PRESET..presetId saved in plansdraw table!!!!!!!!!!

  // const deletePresetType = () => {
  //
  //   if (props.user.presetType < 3) {
  //     alert('You do not have the required permission. Contact an admin.')
  //   } else {
  //
  //     if (window.confirm('If you proceed, this will be deleted. Proceed?')) {
  //
  //       fetch('/api/deletePreset', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           id: isValidated.id
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))
  //
  //           //fetchData()
  //           setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
  //           isChanged.current = false
  //           closeModal()
  //           //alert('Deleted.')
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not delete Preset Type. Contact and admin.')
  //           console.log('Error: deletePresetTYpe --> ' + error)
  //         }
  //       )
  //
  //     }
  //
  //   }
  //
  // }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.presetType < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let description = data.description === null ? '' : data.description
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : ''
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let modBy = data.modby !== null && data.modby !== '' ? data.modby : ''
    let modTime = data.modtime === null ? '' : formatDateTime(data.modtime, true)

    if (
      searchValue === '' ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      modBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      modTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{description}</td>
          <td>{entryBy}</td>
          <td>{entryTime}</td>
          <td>{modBy}</td>
          <td>{modTime}</td>
        </tr>
      )
    }

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block'}}>

        <div>
          <label>Description
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} required></textarea>
          </label>
        </div>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addPresetType : isModal.edit ? editPresetType : null} content={modalContent} closeModal={closeModal} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div>
              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th>Descrip.</th>
                    <th>Entry by</th>
                    <th>Entry time</th>
                    <th>Mod by</th>
                    <th>Mod time</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>

              </div> :
              <p style={{margin: 10}}>No preset types found.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default PresetTypes
