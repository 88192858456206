import React, { useEffect } from 'react'
import Icon from 'components/utils/Icon'

const Alert = (props) => {

  useEffect(() => {
    window.setTimeout(props.close,2000)
  }, [])

  let modal = {
    position: 'fixed',
    zIndex: 5,
    left: 0,
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    height: '100%',
    width: '100%'
  }

  let modalContent = {
    position: 'fixed',
    borderRadius: 10,
    border: '1px solid #d3d3d3',
    backgroundColor: 'white',
    margin: '5px auto',
    overflow: 'auto',
    width: 100,
    maxHeight: 'calc(100% - 30px)',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  }

  return (
    <div style={modal}>
      <div style={modalContent}>
        <div style={{display: 'flex', justifyContent: 'center', position: 'sticky', top: 0, backgroundColor: 'white'}}>
          <Icon name='check_circle' onClick={props.close} />
        </div>
        <div style={{textAlign: 'center'}}>
          <p>{props.content}</p>
        </div>
      </div>
    </div>
  )
}

export default Alert
