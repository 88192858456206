import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

const Jobs = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    edit: false
  })

  const [searchValue, setSearchValue] = useState('')

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const editJob = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let i = parseInt(el.getAttribute('data-i'))

    fetch('/api/editJob', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, gdstatus: fetchedData[i].gdstatus === -1 ? 0 : -1})
    })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()

          setFetchedData(fetchedData.map(data => data.jobid === id ? {...data, gdstatus: data.gdstatus === -1 ? 0 : -1} : data))

        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  const fetchData = () => {

    fetch('/api/selectJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setFetchedData(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  useEffect(() => {
    fetchData()
  }, [isModal.edit])

  const toggleEdit = () => {

    if (props.user.job < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else {

      setIsModal(prevState => ({...prevState, edit: isModal.edit ? false : true}))

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let company = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city

    if (
      (isModal.edit || (!isModal.edit && data.gdstatus === -1)) &&
      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      company.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      city.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
    ) {
      return (
        <tr key={data.id.toString()}>
          {isModal.edit ?
            <td>
              <Icon
                name={data.gdstatus === -1 ? 'check_circle' : 'panorama_fish_eye'}
                color={data.gdstatus === -1 ? 'dodgerblue' : 'tomato'}
                id={data.id}
                i={i}
                jobnumber={data.jobnumber}
                onClick={editJob}
              />
            </td> : null
          }
          <td>{jn}</td>
          <td>{company}</td>
          <td>{city}</td>
        </tr>
      )
    }

  })

  return (

  <div style={{display: 'flex', width: '100%', height: '100%'}}>

    <div style={{margin: 10, flex: '1 0 auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        <div>
          <Icon name='edit' color={isModal.edit ? 'dodgerblue' : 'gray'} onClick={toggleEdit} />
          <Icon name='refresh' onClick={fetchData} />
        </div>

        <SearchBar search={search} clearSearch={clearSearch} />

        {fetchedData.length > 0 ?

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

          <table>

            <thead>
              <tr>
                {isModal.edit? <th></th> : null}
                <th>JN</th>
                <th>Client</th>
                <th>City</th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>
          </div> :
          <p style={{margin: 10}}>No active jobs found.</p>
        }

      </div>

    </div>

  </div>
  )
}

export default Jobs
