export const coordsNE = (north, east, m0, m3, m4, m5, scale, rotation) => {

    let x,y;
    let m = rotate(rotation * -1); //this orientates the coords for the following calcs
    //console.log('m: ' + JSON.stringify(m))
    // pmatrix is determined from selectplans

    //console.log(`east: ${east} -- north: ${north}`)

    let e = (east-m4)/m0;
    let n = (north-m5)/m3;

    //console.log(`e: ${e} -- n: ${n}`)

    x = e * m.m0 + n * m.m2;
    y = e * m.m1 + n * m.m3;

    //console.log(`x: ${x} -- y: ${y}`)

    x = x * scale;
    y = y * scale;

    //console.log(`scale: ${scale}`)

    //console.log(`x: ${x} -- y: ${y}`)

    return {
        x: x,
        y: y
    };

}

export const coordsXY = (mx,my,plan,scale,mode) => {

  let x = mx / scale;
  let y = my / scale;

  //console.log(`x: ${x} -- y: ${y}`)

  let e,n;
  let m,pm;

  if (mode === 'plansmanage') {

    m = rotate(plan.angle); //this orientates the coords for the following calcs

    e = x * m.m0 + y * m.m2; //+ m.m4;
    n = x * m.m1 + y * m.m3; //+ m.m5;

    pm = plansMatrix(plan.x1,plan.y1,plan.x2,plan.y2,plan.n1,plan.e1,plan.n2,plan.e2,plan.angle);

    e = (pm.m4 + e * pm.m0).toFixed(1);
    n = (pm.m5 + n * pm.m3).toFixed(1);

  } else {

    m = rotate(plan.angle); //this orientates the coords for the following calcs

    //console.log(`m: ${JSON.stringify(m)}`)

    e = x * m.m0 + y * m.m2; //+ m.m4;
    n = x * m.m1 + y * m.m3; //+ m.m5;

    //console.log(`e1: ${e} n: ${n}`)

    e = (Number(plan.m4) + e * Number(plan.m0)).toFixed(1);
    n = (Number(plan.m5) + n * Number(plan.m3)).toFixed(1);

    //console.log(`e2: ${e} n: ${n}`)

  }

  //========================================================

  //document.getElementById("planscoordsmsg").innerHTML('N: ' + n + '<br>E: ' + e);

  // console.log('=============================================');
  // console.log('N: ' + n + ' E: ' + e);
  // console.log('=============================================');

  return {
      e: e,
      n: n
  }

}

export const rotate = (degrees) => {

    let radians = degrees * Math.PI / 180;
    let cos = Math.cos(radians);
    let sin = Math.sin(radians);

    let matrix = [];

    matrix[0] = 1; // Horizontal scaling
    matrix[1] = 0; // Horizontal skewing
    matrix[2] = 0; // Vertical skewing
    matrix[3] = 1; // Vertical scaling
    matrix[4] = 0; // Horizontal moving
    matrix[5] = 0; // Vertical moving

    let m0 = matrix[0] * cos + matrix[2] * sin;
    let m1 = -matrix[0] * sin + matrix[2] * cos;
    let m2 = matrix[1] * cos + matrix[3] * sin;
    let m3 = -matrix[1] * sin + matrix[3] * cos;
    let m4 = matrix[4];
    let m5 = matrix[5];

    //console.log('m0: ' + m0 + '\nm1: ' + m1 + '\nm2: ' + m2 + '\nm3: ' + m3 + '\nm4: ' + m4 + '\nm5: ' + m5);

    return {
        m0: m0,
        m1: m1,
        m2: m2,
        m3: m3,
        m4: m4,
        m5: m5,
    };

}

export const plansMatrix = (x1,y1,x2,y2,n1,e1,n2,e2,angle) => {

    let m = rotate(angle); //this orientates the coords for the following calcs

    // console.log('x1/2: ' + x1 + ' ' + x2);
    // console.log('y1/2: ' + y1 + ' ' + y2);

    let mx1 = x1 * m.m0 + y1 * m.m2 + m.m4;
    let my1 = x1 * m.m1 + y1 * m.m3 + m.m5;

    let mx2 = x2 * m.m0 + y2 * m.m2 + m.m4;
    let my2 = x2 * m.m1 + y2 * m.m3 + m.m5;

    // console.log('mx1/2: ' + mx1 + ' ' + mx2);
    // console.log('my1/2: ' + my1 + ' ' + my2);

    let dx = Math.abs(mx2 - mx1);
    let dy = Math.abs(my2 - my1);

    // console.log('dx: ' + dx);
    // console.log('dy: ' + dy);

    let de;
    let dn;

    //this should ensure the proper + / - is provided for Scale

    if (mx1 > mx2) {

        de = e1 - e2;

    } else if (mx2 > mx1) {

        de = e2 - e1;

    }

    if (my1 > my2) {

        dn = n1 - n2;

    } else if (my2 > my1) {

        dn = n2 - n1;

    }

    // console.log('de: ' + de);
    // console.log('dn: ' + dn);

    // determine scale

    let fx = de / dx;
    let fy = dn / dy;

    //console.log('fx: ' + fx);
    //console.log('fy: ' + fy);

    let r = angle * Math.PI / 180;

    //determine skew

    let sx = Math.abs(r) * Math.abs(fx); //make it all + and decide signs with if statement below
    let sy = Math.abs(r) * Math.abs(fy);

    // this determines the +/- for scale and skew
    // necessary for quadrants

    //determine +/- for skew

    if (fx > 0 && fy > 0) { // +,+

        if (r > 0) { //sx (-) sy (+)

            sx *= -1;

        } else { //sx (+) sy (-)

            sy *= -1;

        }

    } else if (fx < 0 && fy < 0) { // -,-

        if (r < 0) { //sx (-) sy (+)

            sx *= -1;

        } else { //sx (+) sy (-)

            sy *= -1;

        }

    } else if (fx > 0 && fy < 0) { // +,-

        //do nothing --> //sx (+) sy (+)

    } else if (fx < 0 && fy > 0) { // -,+

        sx *= -1; //sx (-) sy (-)
        sy *= -1;

    }

    // else {
    //
    //     alert('Error: planscoords() --> skew +/-?. Contact ' + contacthelp);
    //
    // }

    // now start translate

    let tx;
    let ty;

    //determine greatest Northing and Easting
    //determine in which direction N and E increase to establish translate point

    if (mx1 > mx2) {

        tx = e1 - (mx1 * fx);

    } else if (mx2 > mx1) {

        tx = e2 - (mx2 * fx);

    }

    if (my1 > my2) {

        ty = n1 - (my1 * fy);

    } else if (my2 > my1) {

        ty = n2 - (my2 * fy);

    }

    // console.log('matrix0: ' + fx); // Horizontal scaling
    // console.log('matrix1: ' + sx); // Horizontal skewing
    // console.log('matrix2: ' + sy); // Vertical skewing
    // console.log('matrix3: ' + fy); // Vertical scaling
    // console.log('matrix4: ' + tx); // Horizontal moving
    // console.log('matrix5: ' + ty); // Vertical moving

    return {
        m0: fx,
        m1: sx,
        m2: sy,
        m3: fy,
        m4: tx,
        m5: ty,
    };

}
