import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
//import CircleButton from 'components/CircleButton'
//import AddButton from 'components/AddButton'
//import Modal from 'components/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError } from 'scripts/common'

const Billing = (props) => {

  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    loading: true,
    sort: false // false is by date, true is tech, date
  })

  const [searchValue, setSearchValue] = useState('')

  const fileArray = useRef([])

  const fetchData = () => {

    fetch('/api/selectBilling', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        sort: isModal.sort
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('Error: selectBilling --> ' + error)
      }
    )

  }

  useEffect(() => {
    if (props.filter.jobNumber === '' && props.filter.gradeId === '' && props.filter.user === '' && props.filter.startDate === '') {
      setIsModal(prevState => ({...prevState, loading: false}))
    } else {
      fetchData()
    }
  }, [props.filter, isModal.sort])

  // const validate = (event) => {
  //   let name = event.target.getAttribute('name')
  //   let state = event.target.reportValidity()
  //   let type = event.target.type
  //   let value = type === 'checkbox' ? event.target.checked : event.target.value
  //
  //   setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  // }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data i not found. Contact an admin.')
    } else {

      window.open(`dailies/daily${fetchedData[i].id}.pdf`)

    }

  }

  const downloadBilling = () => {

    fetch('/api/downloadBilling', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter,
        sort: isModal.sort
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))

        //console.log(`fileArray: ${JSON.stringify(fileArray.current)}`)

        fetch('/api/mergeBilling', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            fileName: `not used`,
            fileArray: fileArray.current
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            window.open(`billing.pdf`)

          },
          (error) => {
            alert('Error: could not merge PDFs for download. Contact and admin.')
            catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'mergeBilling', JSON.stringify(error), props.user.username, props.user.device)
          }
        )

      },
      (error) => {

        alert('Error: could not select list for download billing. Contact and admin.')
        catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'downloadBilling', JSON.stringify(error), props.user.username, props.user.device)
      }
    )

  }

  const toggleSort = () => setIsModal(prevState => ({...prevState, sort: isModal.sort ? false : true}))

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  fileArray.current = [] // clear before pushing

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let gd = data.gradeid === null ? '' : data.gradeid === 'RG' ? 'Grading' : data.gradeid === 'PG' ? 'Post' : ''
    let status = data.dailiesstatus === null ? '' : data.dailiesstatus
    let dailiesDate = data.dailiesdate === null ? '' : formatDateTime(data.dailiesdate, true)
    let entryBy = data.entryby !== null && data.entryby !== '' ? data.entryby : '?'
    let entryTime = data.entrytime === null ? '' : formatDateTime(data.entrytime, true)
    let task1 = data.taskcode1 === null ? '' : data.taskcode1
    let hour1 = data.hours1 === null ? '' : data.hours1
    let task2 = data.taskcode2 === null ? '' : data.taskcode2
    let hour2 = data.hours2 === null ? '' : data.hours2
    let task3 = data.taskcode3 === null ? '' : data.taskcode3
    let hour3 = data.hours3 === null ? '' : data.hours3
    let task4 = data.taskcode4 === null ? '' : data.taskcode4
    let hour4 = data.hours4 === null ? '' : data.hours4

    //  || entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0

    if (
      ((status === 'Signed' || status === 'Complete') &&
      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      status.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      dailiesDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0))
    ) {

      fileArray.current.push(`daily${data.id}.pdf`)

      return (
        <tr key={data.id.toString()} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td>{formatDateYMD(dailiesDate)}</td>
            <td>{entryBy}</td>
            <td>{jn} - {gd}</td>
            <td>{task1}</td>
            <td><b>{hour1}</b></td>
            <td>{task2}</td>
            <td><b>{hour2}</b></td>
            <td>{task3}</td>
            <td><b>{hour3}</b></td>
            <td>{task4}</td>
            <td><b>{hour4}</b></td>
        </tr>
      )
    }

  })

  return (
    <>
      {!isModal.loading ?
        <>
          {props.filter.jobNumber === '' && props.filter.gradeId === '' && props.filter.user === '' && props.filter.startDate === '' ?
            <p style={{margin: 10}}>Please select a filter</p> :
            <div style={{display: 'flex', width: '100%', height: '100%'}}>

              <div style={{margin: 10, flex: '1 0 auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div>
                    <Icon name='get_app' onClick={downloadBilling} />
                    <Icon name='refresh' onClick={fetchData} />
                    <div style={{display: 'inline-block', margin: 10}}>
                      <small style={{margin: 10}}>Sort by Date</small>
                      <label className='switch'>
                        <input type='checkbox' onClick={toggleSort} defaultChecked={isModal.sort} />
                        <span className='slider round'></span>
                      </label>
                      <small style={{margin: 10}}>Sort by Tech then Date</small>
                    </div>
                  </div>

                  <SearchBar search={search} clearSearch={clearSearch} />

                  {fetchedData.length > 0 ?

                    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                      <table>

                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Tech</th>
                            <th>JN</th>
                            <th>Task</th>
                            <th>Hours</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {listOfData}
                        </tbody>

                      </table>

                    </div> :
                    <p>No dailies found.</p>
                  }
                </div>

              </div>

            </div>
          }
        </> :
        <p style={{margin: 10}}>Loading...</p>
      }
    </>
  )

}

export default Billing
