import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD } from 'scripts/common'

const Maxes = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    edit: false
  })

  const [searchValue, setSearchValue] = useState('')

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const editMax = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let i = parseInt(el.getAttribute('data-i'))

    fetch('/api/editMax', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id, isFieldUse: fetchedData[i].isFieldUse === 1 ? 0 : 1})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        fetchData()

        setFetchedData(fetchedData.map(data => data.id === id ? {...data, isFieldUse: data.isFieldUse === 1 ? 0 : 1} : data))

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  const fetchData = () => {

    fetch('/api/selectMaxes', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedData(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  useEffect(() => {
    fetchData()
  }, [isModal.edit])

  const toggleEdit = () => {

    if (props.user.max < 2) {

      alert('You do not have the required permission. Contact an admin.')

    } else {

      setIsModal(prevState => ({...prevState, edit: isModal.edit ? false : true}))

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let sn = data.sampleno === null ? '' : data.sampleno
    let sampleDate = data.sampledate === null || data.sampledate === '' ? '' : formatDateYMD(data.sampledate, true)
    let curve = data.curve === null ? '' : data.curve
    let optd = data.optd === null ? '' : data.optd
    let optm = data.optm === null ? '' : data.optm
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null ? '' : data.represents
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color
    let additionalColor = data.additionalcolor === null ? '' : data.additionalcolor

    if (
      (isModal.edit || (!isModal.edit && data.isFieldUse === 1)) &&
      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sn.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      curve.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      optd.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      optm.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      location.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      represents.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      color.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      additionalColor.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
    ) {
      return (
        <tr key={data.id.toString()}>
          <td style={{display: 'none'}}>{data.id}</td>
          {isModal.edit ?
            <td>
              <Icon
                name={data.isFieldUse === 1 ? 'check_circle' : 'panorama_fish_eye'}
                color={data.isFieldUse === 1 ? 'dodgerblue' : 'tomato'}
                id={data.id}
                i={i}
                jobnumber={data.jobnumber}
                onClick={editMax}
              />
            </td> : null
          }
          <td>{jn}</td>
          <td>{sn}</td>
          <td>{sampleDate}</td>
          <td>{curve}</td>
          <td>{optd}</td>
          <td>{optm}</td>
          <td>{location}</td>
          <td>{represents}</td>
          <td>{description}</td>
          <td>{color}</td>
          <td>{additionalColor}</td>
        </tr>
      )
    }

  })

  return (

  <div style={{display: 'flex', width: '100%', height: '100%'}}>

    <div style={{margin: 10, flex: '1 0 auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        <div>
          <Icon name='edit' color={isModal.edit ? 'dodgerblue' : 'gray'} onClick={toggleEdit} />
          <Icon name='refresh' onClick={fetchData} />
        </div>

        <SearchBar search={search} clearSearch={clearSearch} />

        {fetchedData.length > 0 ?

          <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

          <table>

            <thead>
              <tr>
                {isModal.edit? <th></th> : null}
                <th>JN</th>
                <th>SN</th>
                <th>Sample Date</th>
                <th>Curve</th>
                <th>Opt Dens (pcf)</th>
                <th>Opt M (%)</th>
                <th>Location</th>
                <th>Represents</th>
                <th>Desc.</th>
                <th>Color</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {listOfData}
            </tbody>

          </table>
          </div> :
          <p style={{margin: 10}}>No active maxes found.</p>
        }

      </div>

    </div>

  </div>
  )
}

export default Maxes
