import React, { useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import Status from 'components/main/Status'
import Nukes from 'components/main/Nukes'
import Mileage from 'components/main/Mileage'
import Stats from 'components/main/Stats'

import { formatDateTime, formatTime, getLocation } from 'scripts/common'

import { selectData } from 'scripts/offline'

const Home = (props) => {

  const [myStatus, setMyStatus] = useState([])
  const [myNuke, setMyNuke] = useState([])
  const [myMileage, setMyMileage] = useState([])
  // const [nukes, setNukes] = useState([])
  const [isModal, setIsModal] = useState({
    status: false,
    nuke: false,
    mileage: false
  })

  const fetchStatus = () => {

    if (props.user.offline === props.filter.jobNumber) {

      selectData('MyStatus').then(res => setMyStatus(res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime))))

    } else {

      fetch('/api/myStatus', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))
          setMyStatus(result)
        },
        (error) => {
          console.log('Error: myStatus --> ' + error)
        }
      )

    }
  }

  const fetchNuke = () => {

    if (props.user.offline === props.filter.jobNumber) {

      // my Nuke

      selectData('MyNuke').then(res => {
        if (res.length > 0) {

          if (res[0].checkIn !== '' && res[0].checkIn !== null) {
            props.selectNuke(res[0].nukeId)
          } else {
            props.selectNuke('')
          }

        } else {
          props.selectNuke('')
        }

        setMyNuke(res.sort((a, b) => new Date(b.entrytime) - new Date(a.entrytime)))
      })

    } else {

      fetch('/api/myNuke', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          by: props.user.username,
          time: formatDateTime(new Date())
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

           // updates userDetails so nukeId can be used in Tests
          if (result.length > 0) {

            if (result[0].checkIn !== '' && result[0].checkIn !== null) {
              props.selectNuke(result[0].nukeId)
            } else {
              props.selectNuke('')
            }

          } else {
            props.selectNuke('')
          }

          setMyNuke(result)
        },
        (error) => {
          console.log('Error: myNuke --> ' + error)
        }
      )

    }

  }

  const fetchMileage = () => {
    fetch('/api/myMileage', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        by: props.user.username,
        time: formatDateTime(new Date())
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setMyMileage(result)
      },
      (error) => {
        console.log('Error: myMileage --> ' + error)
      }
    )
  }

  const fetchData = () => {

    fetchStatus()
    fetchNuke()
    fetchMileage()

  }

  useEffect(() => {
    fetchData()
  }, [])

  // useEffect(() => {
  //   if (isModal.nuke) fetchAvailableNukes()
  // }, [isModal.nuke])

  // const checkOutNuke = (e) => {
  //
  //   let tr = e.target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let nukeId = td[0].textContent
  //   let location = td[1].textContent
  //
  //   if (nukeId === '' || nukeId === null) {
  //     alert('The Nuke ID was not found. Contact an admin.')
  //   } else {
  //
  //     getLocation(function(latlng){
  //
  //       fetch('/api/checkOutNuke', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: props.user.device,
  //           nukeId: nukeId,
  //           location: location
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //
  //           fetchNuke()
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not add myNuke. Contact and admin.')
  //           console.log('Error: myNuke --> ' + error)
  //         }
  //       )
  //
  //     })
  //
  //     closeNuke()
  //
  //   }
  //
  // }
  //
  // const checkInNuke = (e) => {
  //
  //   if (myNuke.length > 0) {
  //
  //     //console.log(`id: ${JSON.stringify(myNuke)}`)
  //
  //     getLocation(function(latlng){
  //
  //       fetch('/api/checkInNuke', {
  //         method: 'post',
  //         headers: {
  //           'Accept': 'application/json, text/plain, */*',
  //           'Content-Type': 'application/json'
  //         },
  //         body: JSON.stringify({
  //           by: props.user.username,
  //           time: formatDateTime(new Date()),
  //           lat: latlng.lat,
  //           lng: latlng.lng,
  //           device: props.user.device,
  //           id: myNuke[0].id
  //         })
  //       })
  //       .then(res=>res.json())
  //       .then(
  //         (result) => {
  //           //console.log('result: ' + JSON.stringify(result))
  //
  //           setMyNuke(myNuke.map(data =>
  //             data.id === myNuke[0].id ?
  //             {...data,
  //               modby: props.user.username,
  //               modtime: formatDateTime(new Date()),
  //               modlat: latlng.lat,
  //               modlng: latlng.lng,
  //               moddevice: props.user.device,
  //               checkIn: formatDateTime(new Date()),
  //             } :
  //             data
  //           ))
  //
  //           props.selectNuke('') // updates userDetails so nukeId can be used in Tests
  //
  //         },
  //         (error) => {
  //
  //           alert('Error: could not edit myNuke. Contact and admin.')
  //           console.log('Error: editmyNuke --> ' + error)
  //         }
  //       )
  //
  //     })
  //
  //   } else {
  //     alert('Error: Nuke Id not found. Contact an Admin.')
  //   }
  //
  //   closeNuke()
  //
  // }

  const directions = () => {
    props.filter.jobNumber !== '' && props.filter.jobNumber !== null ?
    window.open(`http://maps.google.com/?q=${props.filter.lat}, ${props.filter.lng}`) :
    alert('Please select a Job Number first')
  }

  const openStatus = () => setIsModal(prevState => ({...prevState, status: true}))

  const closeStatus = () => setIsModal(prevState => ({...prevState, status: false}))

  const openNuke = () => setIsModal(prevState => ({...prevState, nuke: true}))

  const closeNuke = () => setIsModal(prevState => ({...prevState, nuke: false}))

  const openMileage = () => setIsModal(prevState => ({...prevState, mileage: true}))

  const closeMileage = () => setIsModal(prevState => ({...prevState, mileage: false}))

  // let listOfNukes = nukes.map(data => {
  //
  //   let available = data.checkOut !== '' && data.checkOut !== null && (data.checkIn === '' || data.checkIn === null) ? false : true
  //
  //   return (
  //     <tr onClick={available ? checkOutNuke : null}>
  //       <td style={{textDecoration: available ? 'none' : 'line-through', color: available ? 'dodgerblue' : 'tomato'}}>{data.nukeId}</td>
  //       <td style={{display: 'none'}}>{data.location}</td>
  //       <td>{available ? '' : data.user}</td>
  //     </tr>
  //   )
  // })

  // let reduceDistance = (a, b) => Number(a.distance) + Number(b.distance)
  // let reduceDuration = (a, b) => Number(a.duration) + Number(b.duration)
  //
  // let distance = myMileage.length > 0 ? Math.round(10 * Number(myMileage.reduce(reduceDistance)) / 1609.34) / 10 : ''
  // let duration = myMileage.length > 0 ? Math.round(10 * Number(myMileage.reduce(reduceDuration)) / 3600) / 10 : ''

  let distance = myMileage.length > 0 ? Math.round(10 * myMileage[0].sumDistance / 1609.34) / 10 : ''
  let duration = myMileage.length > 0 ? Math.round(10 * myMileage[0].sumDuration / 3600) / 10 : ''
//flex: '1 1 120px',
//margin: 5,
  let styleCard = {
    width: 300,
    border: '1px solid gray',
    borderRadius: 5,
    height: 100,
    textAlign: 'center',
    cursor: 'pointer'
  }

  let backGroundColor = `repeating-linear-gradient(-45deg, orange, orange 5px, white 5px, white 20px)`

  let styleIssue = {...styleCard, background: backGroundColor}
  let styleStatus = {...styleCard, background: myStatus.length > 0 ?  'none' : backGroundColor}
  let styleNuke = {...styleCard, background: myNuke.length > 0 ? 'none' : backGroundColor}
  let styleMileage = {...styleCard, background: distance > 0 ?  'none' : backGroundColor}

  // let showListofNukes = (nukes.length > 0 ?
  //
  //   <div style={{display: 'flex', justifyContent: 'center'}}>
  //
  //     <table>
  //       <thead>
  //         <tr>
  //           <th>Check Out a Nuke</th>
  //           <th></th>
  //         </tr>
  //       </thead>
  //
  //       <tbody>
  //         {listOfNukes}
  //       </tbody>
  //     </table>
  //
  //   </div> :
  //   <p style={{margin: 10}}>No nukes added. Contact management.</p>
  //
  // )

  // {isModal.nuke ?
  //   <Modal
  //     content={
  //       <div style={{margin: 10}}>
  //
  //         {
  //           myNuke.length > 0 ?
  //           myNuke[0].checkIn !== '' && myNuke[0].checkIn !== null ?
  //           showListofNukes :
  //           <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
  //             <h1>Check In Nuke {myNuke[0].nukeId}?</h1>
  //             <div><CircleButton iconName='check_circle' onClick={checkInNuke} /></div>
  //           </div> :
  //           showListofNukes
  //         }
  //
  //       </div>
  //     }
  //     closeModal={closeNuke}
  //   /> : null
  // }

  //{isModal.status ? <Modal content={<Status user={props.user} filter={props.filter} updateParent={fetchStatus} checkInNuke={checkInNuke} myNuke={myNuke} />} closeModal={closeStatus} /> : null}

  return (
    <>
      {props.user.userlevel === 'guest' ?
        <>
          <div>
            <p>Welcome {props.user.fullname}!</p>
            <p>As a guest, you may view and access Dailies and Plans.</p>
            <p>You may also sign and download Dailies.</p>
            <p>Feel free to explore!</p>
          </div>
        </> :
        <>
          {isModal.status ? <Modal content={<Status user={props.user} filter={props.filter} updateParent={fetchStatus} />} closeModal={closeStatus} /> : null}
          {isModal.mileage ? <Modal content={<Mileage user={props.user} filter={props.filter} updateParent={fetchMileage} />} closeModal={closeMileage} /> : null}
          {isModal.nuke ? <Modal content={<Nukes user={props.user} filter={props.filter} updateParent={fetchNuke} myNuke={myNuke} />} closeModal={closeNuke} /> : null}

          <div style={{display: 'flex', flexWrap: 'wrap', border: '1px solid blue'}}>

            <div style={styleCard} onClick={openStatus}>
              <h3>Status</h3>
              <div>{myStatus.length > 0 ? `${myStatus[0].status} @ ${formatTime(myStatus[0].entrytime, true)}` : 'No status today'}</div>
            </div>

            <div style={styleCard} onClick={openNuke}>
              <h3>Nuke</h3>
              <div>
                {
                  myNuke.length > 0 ?
                  `${myNuke[0].checkIn !== '' && myNuke[0].checkIn !== null ? 'Checked In Nuke' : 'Checked Out Nuke'} ${myNuke[0].nukeId} @ ${formatTime(myNuke[0].entrytime, true)}` :
                  'No nuke today'
                }
              </div>
            </div>

            {isModal.mileage === 'derp' ?
              <div style={styleCard} onClick={openMileage}>
                <h3>Mileage</h3>
                <div>{distance > 0 ? `${distance} miles (about ${duration} hr${duration === 1 ? '' : 's'}) Google` : 'No miles today'}</div>
              </div> : null
            }

            <div style={styleCard} onClick={directions}>
              <h3>Directions</h3>
              <Icon name='directions' />
            </div>

          </div>
        </>
      }
    </>
  )

  // return (
  //   <div className='testing1'>
  //     <div style={{backgroundColor:'coral'}}>A</div>
  //     <div style={{backgroundColor:'blue'}}>B</div>
  //     <div style={{backgroundColor:'red'}}>C</div>
  //     <div style={{backgroundColor:'orange'}}>D</div>
  //     <div style={{backgroundColor:'green'}}>E</div>
  //     <div style={{backgroundColor:'purple'}}>F</div>
  //   </div>
  // )
}


// <div style={styleIssue} onClick={null}>
//   <h3>Issues</h3>
//   <Stats user={props.user} filter={props.filter} />
// </div>


// <div>
//   {isModal.status ?
//
//
//     <div style={{display: 'flex', overflowX: 'auto'}}>
//
//       <div style={{flex: '1 1 auto'}}>
//         <Status user={props.user} filter={props.filter} />
//       </div>
//
//     </div> : null
//
//   }
// </div>



// <div style={{flex: '1 1 auto'}}>
//   <WhatsNew user={props.user} />
// </div>

// <div style={{flex: '1 1 auto'}}>
//   <Following user={props.user} />
// </div>

export default Home
