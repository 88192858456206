import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import 'styles/navBar.css';

function NavBar(props) {

  const [isModal, setIsModal] = useState({
    profile: false,
    menu: false
  })
  const minimumScreenSize = 600
  const [screenSizeNormal, setScreenSizeNormal] = useState(null)

  const resizeScreen = () => {

    if (window.innerWidth > minimumScreenSize && screenSizeNormal === null) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth <= minimumScreenSize && screenSizeNormal === null) {
      setScreenSizeNormal(false)
    } else if (window.innerWidth > minimumScreenSize && !screenSizeNormal) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth < minimumScreenSize && screenSizeNormal) {
      setScreenSizeNormal(false)
    }

  }

  window.addEventListener("resize", resizeScreen)

  useEffect(() => {
    resizeScreen()
  }, [])

  //const openAppMenu = () => setIsAppMenu(isAppMenu ? false : true)

  const openProfile = () => setIsModal(prevState => ({...prevState, profile: true}))
  const openMenu = () => setIsModal(prevState => ({...prevState, menu: true}))
  const closeProfile = () => setIsModal(prevState => ({...prevState, profile: false}))
  const closeMenu = () => setIsModal(prevState => ({...prevState, menu: false}))

  const selectComponent = (e) => {
    props.onClick(e.target)
    closeMenu()
  }

  const selectRow = (e) => {
    props.onClick(e.target.parentNode)
    closeMenu()
  }

  const styleNormal = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto'
  }

  const styleSmall = {
    backgroundColor: '#383838',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const styleAppMenu = {
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto',
    textAlign: 'right'
  }

  const titleStyle = {
    display: 'inline-block',
    width: 50,
    height: 'auto',
    objectFit: 'contain',
    marginRight: 10
  }

  // {props.user.drawing > 0 ? <tr className={props.component === 'Drawings' ? 'activeMenu' : 'nonActive2'} data-name='Drawings' onClick={selectRow}><td>Drawings</td></tr> : null}

  let menuContent = (
    <div style={{display: 'inline-block', textAlign: 'center'}}>
      <table>

        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr className={props.component === 'Home' ? 'activeMenu' : 'nonActive'} data-name='Home' onClick={selectRow}><td>Home</td></tr>
          {props.user.info > 0 ? <tr className={props.component === 'Info' ? 'activeMenu' : 'nonActive2'} data-name='Info' onClick={selectRow}><td>Info</td></tr> : null}
          {props.user.test > 0 ? <tr className={props.component === 'Tests' ? 'activeMenu' : 'nonActive2'} data-name='Tests' onClick={selectRow}><td>Tests</td></tr> : null}
          {props.user.lab > 0 ? <tr className={props.component === 'Lab' ? 'activeMenu' : 'nonActive2'} data-name='Lab' onClick={selectRow}><td>Lab</td></tr> : null}
          {props.user.plan > 0 || props.user.userlevel === 'guest' ? <tr className={props.component === 'Plans' ? 'activeMenu' : 'nonActive2'} data-name='Plans' onClick={selectRow}><td>Plans</td></tr> : null}
          {props.user.daily > 0 || props.user.userlevel === 'guest' ? <tr className={props.component === 'Dailies' ? 'activeMenu' : 'nonActive2'} data-name='Dailies' onClick={selectRow}><td>Dailies</td></tr> : null}
          {props.user.document > 0 ? <tr className={props.component === 'Documents' ? 'activeMenu' : 'nonActive2'} data-name='Documents' onClick={selectRow}><td>Docs</td></tr> : null}
          {props.user.manage > 0 ? <tr className={props.component === 'Manage' ? 'activeMenu' : 'nonActive2'} data-name='Manage' onClick={selectRow}><td>Manage</td></tr> : null}
        </tbody>

      </table>
    </div>
  )

  // {props.user.drawing > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Drawings' ? 'active' : 'nonActive'} data-name='Drawings' onClick={selectComponent}>Drawings</div> : null}

  return (
    <div>
      {isModal.profile ?
        <Modal
          content={
            <div>
              <h3>Profile</h3>
              <div>{props.user.username}</div>
              <div><small>Version 25</small></div>
            </div>
          }
          closeModal={closeProfile}
        /> : null
      }
      {isModal.menu ? <Modal content={menuContent} closeModal={closeMenu} /> : null}
      {screenSizeNormal ?
        <div style={styleNormal}>
          <img style={titleStyle} src="logo192.png" />
          <div style={{display: 'inline-block'}} className={props.component === 'Home' ? 'active' : 'nonActive'} data-name='Home' onClick={selectComponent}>Home</div>
          {props.user.info > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Info' ? 'active' : 'nonActive'} data-name='Info' onClick={selectComponent}>Info</div> : null}
          {props.user.test > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Tests' ? 'active' : 'nonActive'} data-name='Tests' onClick={selectComponent}>Tests</div> : null}
          {props.user.lab > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Lab' ? 'active' : 'nonActive'} data-name='Lab' onClick={selectComponent}>Lab</div> : null}
          {props.user.plan > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Plans' ? 'active' : 'nonActive'} data-name='Plans' onClick={selectComponent}>Plans</div> : null}
          {props.user.daily > 0 || props.user.userlevel === 'guest' ? <div style={{display: 'inline-block'}} className={props.component === 'Dailies' ? 'active' : 'nonActive'} data-name='Dailies' onClick={selectComponent}>Dailies</div> : null}
          {props.user.document > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Documents' ? 'active' : 'nonActive'} data-name='Documents' onClick={selectComponent}>Docs</div> : null}
          {props.user.manage > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Manage' ? 'active' : 'nonActive'} data-name='Manage' onClick={selectComponent}>Manage</div> : null}
          <div>
            <Icon name='account_circle' onClick={openProfile} />
          </div>
        </div> :
      !screenSizeNormal ?
        <div>
          <div style={styleSmall}>
            <img style={titleStyle} src="logo192.png" />
            <Icon name='apps' onClick={openMenu} />
            <Icon name='account_circle' onClick={openProfile} />
          </div>
        </div> :null
      }
    </div>
  )

}

export default NavBar
