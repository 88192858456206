import React from 'react'
import Icon from 'components/utils/Icon'

const LeftPanel = (props) => {

  let activeDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'dodgerblue',
    color: 'white'
  }

  let styleDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  let styleSpan = {
    marginRight: 10
  }

  return (
    <div style={{backgroundColor: 'whitesmoke', height: '100%', overflowY: 'auto'}}>

      <div className='hoverLink' style={props.componentManage === 'Home' ? activeDiv : styleDiv} data-id='Home' onClick={props.selectComponent}><Icon name='home' color={props.componentManage === 'Home' ? 'white' : 'gray'} /><span style={styleSpan}>Home</span></div>

      {props.user.analytics > 0 ? <div className='hoverLink' style={props.componentManage === 'Analytics' ? activeDiv : styleDiv} data-id='Analytics' onClick={props.selectComponent}><Icon name='analytics' outline={true} color={props.componentManage === 'Analytics' ? 'white' : 'gray'} /><span style={styleSpan}>Analytics</span></div> : null}

      {props.user.permission > 0 ? <div className='hoverLink' style={props.componentManage === 'Permissions' ? activeDiv : styleDiv} data-id='Permissions' onClick={props.selectComponent}><Icon name='vpn_key' color={props.componentManage === 'Permissions' ? 'white' : 'gray'} /><span style={styleSpan}>Permissions</span></div> : null}

      {props.user.job > 0 ? <div className='hoverLink' style={props.componentManage === 'Jobs' ? activeDiv : styleDiv} data-id='Jobs' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Jobs' ? 'white' : 'gray'} /><span style={styleSpan}>Active Jobs</span></div> : null}

      {props.user.max > 0 ? <div className='hoverLink' style={props.componentManage === 'Maxes' ? activeDiv : styleDiv} data-id='Maxes' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Maxes' ? 'white' : 'gray'} /><span style={styleSpan}>Active Maxes</span></div> : null}

      {props.user.info > 0 ? <div className='hoverLink' style={props.componentManage === 'Info' ? activeDiv : styleDiv} data-id='Info' onClick={props.selectComponent}><Icon name='info' color={props.componentManage === 'Info' ? 'white' : 'gray'} /><span style={styleSpan}>Info</span></div> : null}

      {props.user.test > 0 ? <div className='hoverLink' style={props.componentManage === 'Tests' ? activeDiv : styleDiv} data-id='Tests' onClick={props.selectComponent}><Icon name='change_history' color={props.componentManage === 'Tests' ? 'white' : 'gray'} /><span style={styleSpan}>Tests</span></div> : null}

      {props.user.lab > 0 ? <div className='hoverLink' style={props.componentManage === 'Lab' ? activeDiv : styleDiv} data-id='Lab' onClick={props.selectComponent}><Icon name='science' color={props.componentManage === 'Lab' ? 'white' : 'gray'} /><span style={styleSpan}>Lab</span></div> : null}

      {props.user.drawing > 0 ? <div className='hoverLink' style={props.componentManage === 'Drawings' ? activeDiv : styleDiv} data-id='Drawings' onClick={props.selectComponent}><Icon name='edit' color={props.componentManage === 'Drawings' ? 'white' : 'gray'} /><span style={styleSpan}>Drawings</span></div> : null}

      {props.user.daily > 0 ? <div className='hoverLink' style={props.componentManage === 'Dailies' ? activeDiv : styleDiv} data-id='Dailies' onClick={props.selectComponent}><Icon name='assignment' color={props.componentManage === 'Dailies' ? 'white' : 'gray'} /><span style={styleSpan}>Dailies</span></div> : null}

      {props.user.document > 0 ? <div className='hoverLink' style={props.componentManage === 'Documents' ? activeDiv : styleDiv} data-id='Documents' onClick={props.selectComponent}><Icon name='folder' color={props.componentManage === 'Documents' ? 'white' : 'gray'} /><span style={styleSpan}>Documents</span></div> : null}

      {props.user.plan > 0 ? <div className='hoverLink' style={props.componentManage === 'Plans' ? activeDiv : styleDiv} data-id='Plans' onClick={props.selectComponent}><Icon name='map' color={props.componentManage === 'Plans' ? 'white' : 'gray'} /><span style={styleSpan}>Plans</span></div> : null}

      {props.user.nuke > 0 ? <div className='hoverLink' style={props.componentManage === 'Nukes' ? activeDiv : styleDiv} data-id='Nukes' onClick={props.selectComponent}><Icon name='home_repair_service' color={props.componentManage === 'Nukes' ? 'white' : 'gray'} /><span style={styleSpan}>Nukes</span></div> : null}

      {props.user.dailyDescription > 0 ? <div className='hoverLink' style={props.componentManage === 'DailyDescription' ? activeDiv : styleDiv} data-id='DailyDescription' onClick={props.selectComponent}><Icon name='maps_ugc' color={props.componentManage === 'DailyDescription' ? 'white' : 'gray'} /><span style={styleSpan}>Daily Descrip</span></div> : null}

      {props.user.equipDescription > 0 ? <div className='hoverLink' style={props.componentManage === 'EquipDescription' ? activeDiv : styleDiv} data-id='EquipDescription' onClick={props.selectComponent}><Icon name='maps_ugc' color={props.componentManage === 'EquipDescription' ? 'white' : 'gray'} /><span style={styleSpan}>Equip Descrip</span></div> : null}

      {props.user.billing > 0 ? <div className='hoverLink' style={props.componentManage === 'Billing' ? activeDiv : styleDiv} data-id='Billing' onClick={props.selectComponent}><Icon name='receipt_long' color={props.componentManage === 'Billing' ? 'white' : 'gray'} /><span style={styleSpan}>Billing</span></div> : null}

      {props.user.prefix > 0 ? <div className='hoverLink' style={props.componentManage === 'Prefixes' ? activeDiv : styleDiv} data-id='Prefixes' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Prefixes' ? 'white' : 'gray'} /><span style={styleSpan}>Prefixes</span></div> : null}

      {props.user.preset > 0 ? <div className='hoverLink' style={props.componentManage === 'Presets' ? activeDiv : styleDiv} data-id='Presets' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Presets' ? 'white' : 'gray'} /><span style={styleSpan}>Presets</span></div> : null}

      {props.user.presetType > 0 ? <div className='hoverLink' style={props.componentManage === 'PresetTypes' ? activeDiv : styleDiv} data-id='PresetTypes' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'PresetTypes' ? 'white' : 'gray'} /><span style={styleSpan}>Preset Types</span></div> : null}

      {props.user.task > 0 ? <div className='hoverLink' style={props.componentManage === 'Tasks' ? activeDiv : styleDiv} data-id='Tasks' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Tasks' ? 'white' : 'gray'} /><span style={styleSpan}>Tasks</span></div> : null}

      {props.user.user > 0 ? <div className='hoverLink' style={props.componentManage === 'Users' ? activeDiv : styleDiv} data-id='Users' onClick={props.selectComponent}><Icon name='people' color={props.componentManage === 'Users' ? 'white' : 'gray'} /><span style={styleSpan}>Users</span></div> : null}

    </div>
  )
}

export default LeftPanel
